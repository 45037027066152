import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useApi } from "../../../api/ContextApi";

const SingleConfigurationPlan = (props) => {
  const theme = useTheme();
  const [visible, setVisible] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const api = useApi();

  const validationSchema = Yup.object().shape({
    name:
      props.plan.name === "Free"
        ? null
        : Yup.string()
            .required("Name is required")
            .max(50, "Name is too long")
            .notOneOf(["Free"], "Free plan already exist"),
    description: Yup.string()
      .required("Description is required")
      .max(2000, "Description is too long"),
    freePodNumber: Yup.number("Value must be a number")
      .integer("Number must be an integer")
      .min(0, "Number must be positive")
      .max(2147483647, "Number is too big")
      .required("Number of free PODs is required"),
    maxConnectors: Yup.number("Value must be a number")
      .integer("Number must be an integer")
      .min(0, "Number must be positive")
      .max(2147483647, "Number is too big")
      .required("Limit of connectors is required"),
    freeUsers: Yup.number("Value must be a number")
      .integer("Number must be an integer")
      .min(0, "Number must be positive")
      .max(2147483647, "Number is too big")
      .required("Number of free users is required"),
    currency:
      props.plan.name === "Free"
        ? null
        : Yup.mixed()
            .oneOf(["USD", "PLN", "EUR"], "Not a valid currency")
            .required("Currency must be declared"),
    priceForPod:
      props.plan.name === "Free"
        ? null
        : Yup.number("Price must be a number", 0)
            .min(0, "Price must be positive")
            .max(2147483647, "Number is too big")
            .required("Price for single POD is required"),
    priceForConnector:
      props.plan.name === "Free"
        ? null
        : Yup.number("Price must be a number")
            .min(0, "Price must be positive")
            .max(2147483647, "Number is too big")
            .required("Price for single POD is required"),
    priceForAdditionalUser:
      props.plan.name === "Free"
        ? null
        : Yup.number("Price must be a number")
            .min(0, "Price must be positive")
            .max(2147483647, "Number is too big")
            .required("Price for additional is required"),
    support: Yup.mixed()
      .oneOf(["Forum", "Forum/Contact(8/5)"], "Not a valid value")
      .required("Support must be declared"),
    ownNames: Yup.boolean("The value must be boolean").required(
      "The value must be declared"
    ),
    userManagement: Yup.boolean("The value must be boolean").required(
      "The value must be declared"
    ),
    twoFactorAuthentication: Yup.boolean("The value must be boolean").required(
      "The value must be declared"
    ),
    tftp: Yup.boolean("The value must be boolean").required(
      "The value must be declared"
    ),
    consoleHistory: Yup.boolean("The value must be boolean").required(
      "The value must be declared"
    ),
    shareConsole: Yup.boolean("The value must be boolean").required(
      "The value must be declared"
    ),
    accessToApi: Yup.boolean("The value must be boolean").required(
      "The value must be declared"
    ),
  });

  const defaultValues = {
    name: props.plan.name,
    description: props.plan.description ? props.plan.description : "",
    freePodNumber: props.plan.freePodNumber,
    maxConnectors: props.plan.maxConnectors,
    freeUsers: props.plan.freeUsers,
    currency: props.plan.currency ? props.plan.currency : "",
    priceForPod: props.plan.priceForPod ? props.plan.priceForPod : "",
    priceForConnector: props.plan.priceForConnector
      ? props.plan.priceForConnector
      : "",
    priceForAdditionalUser: props.plan.priceForAdditionalUser
      ? props.plan.priceForAdditionalUser
      : "",
    support: props.plan.support,
    ownNames: props.plan.ownNames,
    userManagement: props.plan.userManagement,
    twoFactorAuthentication: props.plan.twoFactorAuthentication,
    tftp: props.plan.tftp,
    consoleHistory: props.plan.consoleHistory,
    shareConsole: props.plan.shareConsole,
    accessToApi: props.plan.accessToApi,
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const handleButton = (data) => {
    console.log(data);
    api
      .updatePricingPlan(
        props.plan.id,
        data.name,
        data.description,
        parseInt(data.freePodNumber),
        props.name !== "Free" ? parseInt(data.priceForPod) : null,
        parseInt(data.maxConnectors),
        props.name !== "Free" ? parseInt(data.priceForConnector) : null,
        parseInt(data.freeUsers),
        props.name !== "Free" ? parseInt(data.priceForAdditionalUser) : null,
        data.support,
        props.name !== "Free" ? data.currency : null,
        data.ownNames,
        data.userManagement,
        data.twoFactorAuthentication,
        data.tftp,
        data.accessToApi,
        data.consoleHistory,
        data.shareConsole
      )
      .then((response) => {
        if (response.status === 200) {
          setIsEdited(false);
          window.location.reload(false);
        } else if (response.status === 404) {
          reset();
          alert("Pricing plan not found!");
        } else if (response.status === 403) {
          reset();
          alert("Not allowed");
        } else {
          reset();
          alert("Something went wrong. Try later!");
        }
      });
  };

  return (
    <Box
      sx={{
        mb: "10px",
        mx: "10px",
        p: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        height: "auto",
        borderStyle: "solid",
        borderWidth: "2px",
        borderColor: theme.palette.primary.main,
        borderRadius: "15px",
        width: "100%",
      }}
    >
      <Accordion disableGutters sx={{ width: "100%", boxShadow: "none" }}>
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            height="40px"
          >
            <Typography
              variant="h5"
              height="auto"
              textAlign="center"
              lineHeight="40px"
            >{`Plan - ${props.plan.name}`}</Typography>
            <AccordionSummary
              sx={{
                height: "40px",
                minHeight: "40px",
                width: "40px",
                m: 0,
                p: 0,
              }}
            >
              {visible ? (
                <IconButton
                  sx={{
                    color: theme.palette.primary.main,
                    ":hover": {
                      color: theme.palette.secondary.main,
                      bgcolor: theme.palette.primary.main,
                    },
                    height: "40px",
                    width: "40px",
                    m: 0,
                    p: 0,
                  }}
                  onClick={() => {
                    setVisible(!visible);
                  }}
                >
                  <ExpandLessIcon
                    sx={{ height: "40px", width: "40px", m: 0, p: 0 }}
                  />
                </IconButton>
              ) : (
                <IconButton
                  sx={{
                    color: theme.palette.primary.main,
                    ":hover": {
                      color: theme.palette.secondary.main,
                      bgcolor: theme.palette.primary.main,
                    },
                    height: "40px",
                    width: "40px",
                    m: 0,
                    p: 0,
                  }}
                  onClick={() => {
                    setVisible(!visible);
                  }}
                >
                  <ExpandMoreIcon
                    sx={{ height: "40px", width: "40px", m: 0, p: 0 }}
                  />
                </IconButton>
              )}
            </AccordionSummary>
          </Box>
          <Typography>{`${props.plan.description}`}</Typography>
        </Box>
        <Box>
          <AccordionDetails sx={{ padding: 0 }}>
            <Box sx={{ minHeight: "200px" }}>
              <Grid container columnSpacing={2} paddingTop={2}>
                <Grid item xs={4}>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        fullWidth={true}
                        label="Name"
                        size="small"
                        error={errors.name ? true : false}
                        helperText={errors.name?.message}
                        disabled={props.plan.name === "Free"}
                        title={
                          props.plan.name === "Free"
                            ? "The name for this rate plan is fixed"
                            : undefined
                        }
                        onChangeCapture={() => {
                          if (!isEdited) {
                            setIsEdited(true);
                          }
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={8}>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        fullWidth={true}
                        label="Description"
                        title="Description"
                        size="small"
                        error={errors.description ? true : false}
                        helperText={errors.description?.message}
                        multiline
                        maxRows={4}
                        onChangeCapture={() => {
                          if (!isEdited) {
                            setIsEdited(true);
                          }
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container columnSpacing={2}>
                <Grid item xs={10}>
                  <Grid container columnSpacing={2} paddingTop={2}>
                    <Grid item xs={2.4}>
                      <Controller
                        name="freePodNumber"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            variant="outlined"
                            type="number"
                            fullWidth={true}
                            label="Number of free PODs"
                            title="Number of free PODs"
                            size="small"
                            error={errors.freePodNumber ? true : false}
                            helperText={errors.freePodNumber?.message}
                            onChangeCapture={() => {
                              if (!isEdited) {
                                setIsEdited(true);
                              }
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={2.4}>
                      <Controller
                        name="priceForPod"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            variant="outlined"
                            type="number"
                            fullWidth={true}
                            label="Price for additional POD"
                            size="small"
                            error={errors.priceForPod ? true : false}
                            helperText={errors.priceForPod?.message}
                            disabled={props.plan.name === "Free"}
                            title={
                              props.plan.name === "Free"
                                ? "The free plan does not involve additional payments"
                                : "Price for additional POD"
                            }
                            onChangeCapture={() => {
                              if (!isEdited) {
                                setIsEdited(true);
                              }
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={2.4}>
                      <Controller
                        name="currency"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            variant="outlined"
                            select
                            fullWidth={true}
                            label="Currency"
                            size="small"
                            error={errors.currency ? true : false}
                            helperText={errors.currency?.message}
                            disabled={props.plan.name === "Free"}
                            title={
                              props.plan.name === "Free"
                                ? "The free plan does not involve additional payments"
                                : "Currency"
                            }
                          >
                            <MenuItem
                              value="EUR"
                              onClick={(event) => {
                                if (
                                  !isEdited &&
                                  event.target.dataset.value !==
                                    props.plan.currency
                                ) {
                                  setIsEdited(true);
                                }
                              }}
                            >
                              EUR
                            </MenuItem>
                            <MenuItem
                              value="USD"
                              onClick={(event) => {
                                if (
                                  !isEdited &&
                                  event.target.dataset.value !==
                                    props.plan.currency
                                ) {
                                  setIsEdited(true);
                                }
                              }}
                            >
                              USD
                            </MenuItem>
                            <MenuItem
                              value="PLN"
                              onClick={(event) => {
                                if (
                                  !isEdited &&
                                  event.target.dataset.value !==
                                    props.plan.currency
                                ) {
                                  setIsEdited(true);
                                }
                              }}
                            >
                              PLN
                            </MenuItem>
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={2.4}>
                      <Box
                        component="div"
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        padding="8.5px"
                        maxHeight="40px"
                        sx={{
                          borderWidth: "1px",
                          borderColor: "#00000038",
                          borderRadius: "4px",
                          borderStyle: "solid",
                          ":hover": { borderColor: "#00000087" },
                        }}
                      >
                        <Typography>Custom names</Typography>
                        <Controller
                          name="ownNames"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              checked={field.value}
                              {...field}
                              onClick={() => {
                                if (!isEdited) {
                                  setIsEdited(true);
                                }
                              }}
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container columnSpacing={2} paddingTop={2}>
                    <Grid item xs={2.4}>
                      <Controller
                        name="maxConnectors"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            variant="outlined"
                            type="number"
                            fullWidth={true}
                            label="Maximum number of connectors"
                            title="Maximum number of connectors"
                            size="small"
                            error={errors.maxConnectors ? true : false}
                            helperText={errors.maxConnectors?.message}
                            onChangeCapture={() => {
                              if (!isEdited) {
                                setIsEdited(true);
                              }
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={2.4}>
                      <Controller
                        name="priceForConnector"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            variant="outlined"
                            type="number"
                            fullWidth={true}
                            label="Price per connector"
                            size="small"
                            error={errors.priceForConnector ? true : false}
                            helperText={errors.priceForConnector?.message}
                            disabled={props.plan.name === "Free"}
                            title={
                              props.plan.name === "Free"
                                ? "The free plan does not involve additional payments"
                                : "Price per connector"
                            }
                            onChangeCapture={() => {
                              if (!isEdited) {
                                setIsEdited(true);
                              }
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={2.4}>
                      <Box
                        component="div"
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        padding="8.5px"
                        maxHeight="40px"
                        title={
                          props.plan.name === "Free"
                            ? "The free plan does not involve user management"
                            : undefined
                        }
                        sx={{
                          borderWidth: "1px",
                          borderColor: "#00000038",
                          borderRadius: "4px",
                          borderStyle: "solid",
                        }}
                      >
                        <Typography
                          color={
                            props.plan.name === "Free"
                              ? "#00000038"
                              : "-moz-initial"
                          }
                        >
                          Users management
                        </Typography>
                        <Controller
                          name="userManagement"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              disabled={props.plan.name === "Free"}
                              checked={field.value}
                              {...field}
                              onClick={() => {
                                if (!isEdited) {
                                  setIsEdited(true);
                                }
                              }}
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={2.4}>
                      <Box
                        component="div"
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        padding="8.5px"
                        maxHeight="40px"
                        sx={{
                          borderWidth: "1px",
                          borderColor: "#00000038",
                          borderRadius: "4px",
                          borderStyle: "solid",
                          ":hover": { borderColor: "#00000087" },
                        }}
                      >
                        <Typography>2FA</Typography>
                        <Controller
                          name="twoFactorAuthentication"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              checked={field.value}
                              {...field}
                              onClick={() => {
                                if (!isEdited) {
                                  setIsEdited(true);
                                }
                              }}
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container columnSpacing={2} paddingTop={2}>
                    <Grid item xs={2.4}>
                      <Controller
                        name="freeUsers"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            variant="outlined"
                            type="number"
                            fullWidth={true}
                            label="Number of free users"
                            title="Number of free users"
                            size="small"
                            error={errors.freeUsers ? true : false}
                            helperText={errors.freeUsers?.message}
                            onChangeCapture={() => {
                              if (!isEdited) {
                                setIsEdited(true);
                              }
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={2.4}>
                      <Controller
                        name="priceForAdditionalUser"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            variant="outlined"
                            type="number"
                            fullWidth={true}
                            label="Price for additional user"
                            size="small"
                            error={errors.priceForAdditionalUser ? true : false}
                            helperText={errors.priceForAdditionalUser?.message}
                            disabled={props.plan.name === "Free"}
                            title={
                              props.plan.name === "Free"
                                ? "The free plan does not involve additional payments"
                                : "Price for additional user"
                            }
                            onChangeCapture={() => {
                              if (!isEdited) {
                                setIsEdited(true);
                              }
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={2.4}>
                      <Box
                        component="div"
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        padding="8.5px"
                        maxHeight="40px"
                        title={
                          props.plan.name === "Free"
                            ? "The free plan does not involve user management"
                            : undefined
                        }
                        sx={{
                          borderWidth: "1px",
                          borderColor: "#00000038",
                          borderRadius: "4px",
                          borderStyle: "solid",
                        }}
                      >
                        <Typography>TFTP</Typography>
                        <Controller
                          name="tftp"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              checked={field.value}
                              {...field}
                              onClick={() => {
                                if (!isEdited) {
                                  setIsEdited(true);
                                }
                              }}
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={2.4}>
                      <Box
                        component="div"
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        padding="8.5px"
                        maxHeight="40px"
                        sx={{
                          borderWidth: "1px",
                          borderColor: "#00000038",
                          borderRadius: "4px",
                          borderStyle: "solid",
                          ":hover": { borderColor: "#00000087" },
                        }}
                      >
                        <Typography>Access to API</Typography>
                        <Controller
                          name="accessToApi"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              checked={field.value}
                              {...field}
                              onClick={() => {
                                if (!isEdited) {
                                  setIsEdited(true);
                                }
                              }}
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container columnSpacing={2} paddingTop={2}>
                    <Grid item xs={2.4}>
                      <Controller
                        name="support"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            variant="outlined"
                            select
                            fullWidth={true}
                            label="Support"
                            size="small"
                            error={errors.support ? true : false}
                            helperText={errors.support?.message}
                          >
                            <MenuItem
                              value="Forum"
                              onClick={(event) => {
                                if (
                                  !isEdited &&
                                  event.target.dataset.value !==
                                    props.plan.support
                                ) {
                                  setIsEdited(true);
                                }
                              }}
                            >
                              Forum
                            </MenuItem>
                            <MenuItem
                              value="Forum/Contact(8/5)"
                              onClick={(event) => {
                                if (
                                  !isEdited &&
                                  event.target.dataset.value !==
                                    props.plan.support
                                ) {
                                  setIsEdited(true);
                                }
                              }}
                            >
                              Forum/Contact(8/5)
                            </MenuItem>
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={2.4} />
                    <Grid item xs={2.4}>
                      <Box
                        component="div"
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        padding="8.5px"
                        maxHeight="40px"
                        sx={{
                          borderWidth: "1px",
                          borderColor: "#00000038",
                          borderRadius: "4px",
                          borderStyle: "solid",
                          ":hover": { borderColor: "#00000087" },
                        }}
                      >
                        <Typography>Console history</Typography>
                        <Controller
                          name="consoleHistory"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              checked={field.value}
                              {...field}
                              onClick={() => {
                                if (!isEdited) {
                                  setIsEdited(true);
                                }
                              }}
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={2.4}>
                      <Box
                        component="div"
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        padding="8.5px"
                        maxHeight="40px"
                        sx={{
                          borderWidth: "1px",
                          borderColor: "#00000038",
                          borderRadius: "4px",
                          borderStyle: "solid",
                          ":hover": { borderColor: "#00000087" },
                        }}
                      >
                        <Typography>Share console</Typography>
                        <Controller
                          name="shareConsole"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              checked={field.value}
                              {...field}
                              onClick={() => {
                                if (!isEdited) {
                                  setIsEdited(true);
                                }
                              }}
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={2}
                  display="flex"
                  alignItems="end"
                  justifyContent="end"
                >
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Button
                      variant="outlined"
                      disabled={!isEdited}
                      sx={{
                        borderRadius: "50px",
                        borderWidth: "3px",
                        maxHeight: "40px",
                        lineHeight: "22px",
                        fontSize: "22px",
                      }}
                      onClick={() => {
                        setIsEdited(false);
                        reset();
                      }}
                    >
                      RESET
                    </Button>
                    <Button
                      variant="outlined"
                      disabled={!isEdited}
                      sx={{
                        borderRadius: "50px",
                        borderWidth: "3px",
                        maxHeight: "40px",
                        lineHeight: "22px",
                        fontSize: "22px",
                      }}
                      onClick={handleSubmit(handleButton)}
                    >
                      Save
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </AccordionDetails>
        </Box>
      </Accordion>
    </Box>
  );
};

export default SingleConfigurationPlan;
