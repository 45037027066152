import { Typography, Container, Button, useTheme, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROOT } from "../../navigation/CONSTANTS";
import UserCreationChart from "./userCreationChart";
import GeneralInformations from "./generalInformation";
import DeviceStatistics from "./deviceStatistics";
import OrganizationStats from "./organizations";

const Statistics = () => {
  let theme = useTheme();
  const navigate = useNavigate();

  const handleBackToHomePage = () => {
    navigate(ROOT);
  };

  return (
    <>
      <Container
        maxWidth="xl"
        sx={{
          mx: "auto",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography m="10px" variant="h4">
          Statistics:
        </Typography>
        <Button
          variant="contained"
          onClick={handleBackToHomePage}
          sx={{ borderRadius: "50px" }}
        >
          Home page
        </Button>
      </Container>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          mx: "auto",
        }}
        maxWidth="xl"
      >
        <Box display="flex" flexDirection="row">
          <Box component="div" sx={{ width: "60%" }}>
            <UserCreationChart />
          </Box>
          <Box component="div" sx={{ width: "30%" }}>
            <GeneralInformations />
          </Box>
        </Box>
        <Box component={"div"} sx={{ height: "auto", py: "30px", px: "10px" }}>
          <DeviceStatistics />
        </Box>
        <Box
          component={"div"}
          sx={{ display: "flex", width: "100%", p: "10px" }}
        >
          <OrganizationStats />
        </Box>
      </Container>
    </>
  );
};

export default Statistics;
