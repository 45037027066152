import {
  AppBar,
  Avatar,
  Box,
  Button,
  CssBaseline,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import images from "../assets";
import { useAuth } from "../navigation/authorization/ProvideAuth";
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate } from "react-router-dom";
import { PROFILE, ROOT } from "../navigation/CONSTANTS";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { useSelector } from "react-redux";
import { selectHeaderData } from "../redux/userSlice";

const Header = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const userData = useSelector(selectHeaderData);

  const handleHomePageNavigation = () => {
    navigate(ROOT);
  };

  const handleProfileNavigation = () => {
    setAnchorEl(null);
    navigate(PROFILE);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let auth = useAuth();
  const theme = useTheme();

  return (
    <>
      <CssBaseline />
      <AppBar
        position="sticky"
        sx={{
          width: "100%",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        color="default"
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            component="img"
            src={images.logoHorizontalColor}
            sx={{
              height: "50px",
              width: "200px",
              objectFit: "cover",
              cursor: "pointer",
            }}
            onClick={handleHomePageNavigation}
          />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ marginRight: "15px" }}>
              {userData.userType.replace("_", " ")}
              {userData.orgDcName !== "" ? ` - ${userData.orgDcName}` : ""}
              {userData.email !== "" ? ` - ${userData.email}` : ""}
            </Typography>
            <Button
              aria-label="Notifications"
              onClick={props.openNotifications}
              sx={{
                marginX: "10px",
                padding: 0,
                minWidth: 0,
                borderRadius: "15px",
              }}
            >
              {" "}
              {props.isNewNotification ? (
                <NotificationsActiveIcon
                  fontSize="large"
                  sx={{
                    color: theme.palette.others.newNotification,
                    ":hover": {
                      color: theme.palette.others.newNotificationHover,
                    },
                  }}
                />
              ) : (
                <NotificationsIcon
                  fontSize="large"
                  sx={{
                    color: theme.palette.primary.main,
                    ":hover": { color: theme.palette.secondary.main },
                  }}
                />
              )}
            </Button>
            <Button
              id="header-avatar-icon-sub-menu"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <Avatar
                sx={{
                  bgcolor: theme.palette.primary.main,
                  ":hover": { bgcolor: theme.palette.secondary.main },
                }}
              >
                <PersonIcon />
              </Avatar>
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                id="header-sub-menu-profile-button"
                onClick={handleProfileNavigation}
              >
                Profile
              </MenuItem>
              <MenuItem
                id="header-sub-menu-logout-button"
                onClick={auth.signout}
              >
                Sign out
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
