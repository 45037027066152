import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import { GridLoader, ClipLoader } from "react-spinners";
import QRCode from "react-qr-code";
import { useApi } from "../../api/ContextApi";

const TwoFactorAuthentication = (props) => {
  const [twoFA, setTwoFA] = useState(false);
  const [select, setSelect] = useState(props.secret.url !== null);
  const [statusQRCode, setStatusQRCode] = useState(
    props.secret.secret !== null ? "qrCode" : "none"
  );
  const theme = useTheme();
  const api = useApi();

  const handleChange = (event) => {
    try {
      if (event.target.checked !== null || event.target.checked !== undefined) {
        setSelect(event.target.checked);
        if (event.target.checked) {
          setStatusQRCode("loading1");
          api.update2FASecret("create").then((response) => {
            if (response.status === 200) {
              console.log(response.data);
              props.setSecret({
                url: response.data.url,
                secret: response.data.secret,
                algorithm: response.data.algorithm,
              });
              setStatusQRCode("qrCode");
            } else {
              setStatusQRCode("none");
            }
          });
        } else {
          setStatusQRCode("loading2");
          api.update2FASecret("delete").then((response) => {
            if (response.status === 200) {
              props.setSecret({
                url: null,
                secret: null,
                algorithm: null,
              });
              setStatusQRCode("none");
            }
          });
        }
      }
    } catch (err) {
      console.log("Error: on change 2FA settings:\n" + err);
    }
  };

  return (
    <Box>
      <Accordion disableGutters sx={{ width: "100%", boxShadow: "none" }}>
        <Box sx={{ display: "flex" }}>
          <AccordionSummary
            sx={{ height: "50px" }}
            aria-expanded={twoFA}
            onClick={() => {
              setTwoFA(!twoFA);
            }}
          >
            <Button
              variant="outlined"
              sx={{
                borderRadius: "50px",
                mb: "2px",
                borderWidth: "2px",
                ":hover": { borderWidth: "2px" },
                width: "250px",
              }}
            >
              2 Factor Authentication
            </Button>
          </AccordionSummary>
        </Box>
        <AccordionDetails sx={{}}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              minHeight: "54px",
            }}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography>Enable two factor authentication: </Typography>
              <Switch
                checked={select}
                onChange={handleChange}
                disabled={
                  statusQRCode === "loading1" || statusQRCode === "loading2"
                }
              />
              {statusQRCode === "loading2" ? (
                <ClipLoader color={theme.palette.primary.main} />
              ) : null}
            </Box>
            <Box display="flex" flexDirection="row">
              <Box flex={1}>
                {statusQRCode === "none" ? null : statusQRCode ===
                  "loading1" ? (
                  <Box
                    style={{
                      display: "flex",
                      width: "256px",
                      height: "256px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <GridLoader size={50} color={theme.palette.primary.main} />
                  </Box>
                ) : (statusQRCode === "qrCode" ||
                    statusQRCode === "loading2") &&
                  props.secret.url ? (
                  <QRCode value={props.secret.url} />
                ) : null}
              </Box>
              {statusQRCode === "qrCode" || statusQRCode === "loading2" ? (
                <Box flex={1} width="40%">
                  <Box display="flex" flexDirection="row">
                    <Typography marginRight="5px">{"Secret:"}</Typography>
                    <Typography
                      overflow="clip"
                      style={{
                        wordBreak: "break-word",
                      }}
                    >
                      {props.secret.secret}
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="row">
                    <Typography marginRight="5px">{"Algorithm:"}</Typography>
                    <Typography
                      overflow="clip"
                      style={{
                        wordBreak: "break-word",
                      }}
                    >
                      {props.secret.algorithm}
                    </Typography>
                  </Box>
                </Box>
              ) : null}
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default TwoFactorAuthentication;
