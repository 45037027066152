import { Box, Grid, List, Typography, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { insertToArray } from "../../../utils/functions";
import SingleUser from "./singleUser";
import useAsyncFeedback from "../../../hooks/useAsyncFeedback";
import { useSelector } from "react-redux";
import { userType } from "../../../redux/userSlice";
import { useLocation, useParams } from "react-router-dom";
import { useApi } from "../../../api/ContextApi";

const UserList = (props) => {
  const userData = useSelector(userType);
  const [handbrake, setHandBrake] = useState(true);
  const [userListData, setUserListData] = useState([]);
  const [ports, setPorts] = useState([]);
  const [handleAsyncUsers, { loading }] = useAsyncFeedback();
  const [handleAsyncPorts, { loading2 }] = useAsyncFeedback();
  const [handleAsyncNetworkInterfaces, { loading3 }] = useAsyncFeedback();
  const newUserId = useRef(0);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const [networkInterfaces, setNetworkInterfaces] = useState([]);
  const api = useApi();

  useEffect(() => {
    handleAsyncUsers(
      () => api.getUsers(),
      null,
      (data) => {
        if (params.get("organizationId")) {
          data = data.filter(
            (user) => user.organization_id === params.get("organizationId")
          );
          setUserListData(data);
        }
        setUserListData(data);
      },
      "loading"
    );
  }, [params.get("organizationId")]);

  useEffect(() => {
    handleAsyncPorts(
      () => api.getPortsByAccountType(),
      null,
      (data) => {
        setPorts(data.miniPC);
      },
      "loading2"
    );
  }, []);

  useEffect(() => {
    if (userData.userType === "dc_admin")
      handleAsyncNetworkInterfaces(
        () => api.getNetworkInterfaces(),
        null,
        (data) => {
          setNetworkInterfaces(data.networkInterfaces);
        },
        "loading3"
      );
  }, []);

  useEffect(() => {
    if (handbrake) {
      setHandBrake(false);
    } else {
      setUserListData(
        insertToArray(userListData, 0, {
          id: newUserId.current,
          name: "",
          account_type_id: "",
          email: "",
          password: "",
          edit: true,
          serial_ports: [],
          draft: true,
        })
      );
      newUserId.current++;
    }
  }, [props.addItem]);

  if (loading || loading2 || loading3) {
    return <></>;
  }

  return (
    <>
      <Box component="div">
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
        ></Box>
        <Grid container spacing={2} sx={{ px: "16px" }}>
          <Grid item xs={3}>
            <Typography>Name</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>Email</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>
              {userData.userType === "organization_admin"
                ? "Organization"
                : "Org/DC"}
            </Typography>
          </Grid>
        </Grid>
        <List>
          {userListData.map((value, index) => (
            <SingleUser
              index={index}
              data={value}
              key={value.id}
              setUserListData={setUserListData}
              userListData={userListData}
              userPorts={ports}
              networkInterfaces={networkInterfaces}
            />
          ))}
        </List>
      </Box>
    </>
  );
};

export default UserList;
