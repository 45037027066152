import {
  Box,
  Button,
  Container,
  CssBaseline,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  colors,
  useTheme,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Copyright from "../../components/Copyright";
import { RESETPASSWORD, ROOT } from "../../navigation/CONSTANTS";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import YupPassword from "yup-password";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useApi } from "../../api/ContextApi";
YupPassword(Yup);

const ForgotPassword = () => {
  let theme = useTheme();
  let navigate = useNavigate();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });
  const [message, setMessage] = useState("");
  const api = useApi();

  const validationSchema =
    params.get("step") === "2"
      ? Yup.object().shape({
          code: Yup.string()
            .required("Code is required")
            .max(20, "Code is too long"),
          password: Yup.string()
            .required("Password is required")
            .minLowercase(
              1,
              "Password must contain at least 1 lower case letter"
            )
            .minUppercase(
              1,
              "Password must contain at least 1 upper case letter"
            )
            .minNumbers(1, "Password must contain at least 1 number")
            .minSymbols(1, "Password must contain at least 1 special character")
            .min(6, "Password is too short")
            .max(50, "Password is too long"),
          confirmPassword: Yup.string().oneOf(
            [Yup.ref("password"), null],
            "Passwords must match"
          ),
        })
      : Yup.object().shape({
          email: Yup.string()
            .required("Email address is required")
            .email("Please enter a valid email address"),
        });

  const defaultValues =
    params.get("step") === "2"
      ? {
          code: "",
          password: "",
          confirmPassword: "",
        }
      : {
          email: "",
        };

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    reset,
    setError,
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  const handleGoBack = () => {
    navigate(ROOT);
  };

  const handleButton = (data) => {
    if (params.get("step") === "2" && params.get("accountId")) {
      api
        .forgotPasswordReset(data.code, data.password, params.get("accountId"))
        .then((result) => {
          if (result.status === 200) {
            navigate(ROOT);
          } else {
            if (result.data.error === "wrong code") {
              alert("Wrong code!");
            } else {
              alert("Something went wrong!");
            }
          }
        });
    } else {
      setMessage("");
      api.forgotPasswordRequest(data.email).then((response) => {
        if (response.data.message) {
          setMessage(response.data.message);
        }
      });
    }
  };

  const handleClickShowPassword = (element) => {
    if (element === "password")
      setShowPassword({ ...showPassword, password: !showPassword.password });
    else if (element === "confirmPassword")
      setShowPassword({
        ...showPassword,
        confirmPassword: !showPassword.confirmPassword,
      });
  };

  return (
    <>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginInline: "3%",
            borderStyle: "solid",
            borderColor: theme.palette.secondary.main,
            borderWidth: "2px",
            borderRadius: "15px",
            padding: "15px",
          }}
        >
          {params.get("step") &&
          params.get("step") === "2" &&
          params.get("accountId") ? (
            <>
              <Typography>Paste the password change code</Typography>
              <Divider />
              <Typography>
                You will receive a password change code to the e-mail address
                provided earlier. To change your password, paste the code below
                and enter your new password.
              </Typography>
              <Box component="form">
                <Controller
                  name="code"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="standard"
                      margin="normal"
                      required
                      fullWidth
                      id="code"
                      label="Code"
                      error={errors.code ? true : false}
                      helperText={errors.code?.message}
                      autoFocus
                    />
                  )}
                />
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="standard"
                      type={showPassword.password ? "text" : "password"}
                      margin="normal"
                      required
                      fullWidth
                      id="password"
                      label="Password"
                      error={errors.password ? true : false}
                      helperText={errors.password?.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="Toggle password visibility"
                              onClick={() =>
                                handleClickShowPassword("password")
                              }
                              edge="end"
                            >
                              {showPassword.password ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
                <Controller
                  name="confirmPassword"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="standard"
                      type={showPassword.confirmPassword ? "text" : "password"}
                      margin="normal"
                      required
                      fullWidth
                      id="confirmPassword"
                      label="Confirm password"
                      error={errors.confirmPassword ? true : false}
                      helperText={errors.confirmPassword?.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="Toggle password visibility"
                              onClick={() =>
                                handleClickShowPassword("confirmPassword")
                              }
                              edge="end"
                            >
                              {showPassword.confirmPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    type="submit"
                    onClick={handleSubmit(handleButton)}
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: 3,
                      mb: 2,
                      bgcolor: theme.palette.primary.main,
                      ":hover": { bgcolor: theme.palette.secondary.main },
                      color: theme.palette.text.main,
                      marginInline: "5%",
                    }}
                  >
                    Change
                  </Button>
                  <Button
                    type="button"
                    onClick={handleGoBack}
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: 3,
                      mb: 2,
                      bgcolor: theme.palette.background.main,
                      ":hover": { bgcolor: theme.palette.secondary.main },
                      color: theme.palette.text.main,
                      marginInline: "5%",
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Typography>Forgot your password?</Typography>
              <Divider />
              <Typography>
                Enter your email address below and we'll send your password
                reset instructions by email.
              </Typography>
              <Box component="form">
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="standard"
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email address"
                      autoComplete="email"
                      error={errors.email ? true : false}
                      helperText={errors.email?.message}
                      autoFocus
                      onChangeCapture={() => {
                        setMessage("");
                      }}
                    />
                  )}
                />
                {message ? (
                  <Typography
                    color={theme.palette.secondary.main}
                    fontSize="15px"
                  >
                    {message}
                  </Typography>
                ) : null}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    type="submit"
                    onClick={handleSubmit(handleButton)}
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: 3,
                      mb: 2,
                      bgcolor: theme.palette.primary.main,
                      ":hover": { bgcolor: theme.palette.secondary.main },
                      color: theme.palette.text.main,
                      marginInline: "5%",
                    }}
                  >
                    Send
                  </Button>
                  <Button
                    type="button"
                    onClick={handleGoBack}
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: 3,
                      mb: 2,
                      bgcolor: theme.palette.background.main,
                      ":hover": { bgcolor: theme.palette.secondary.main },
                      color: theme.palette.text.main,
                      marginInline: "5%",
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} color="text.secondary" />
      </Container>
    </>
  );
};

export default ForgotPassword;
