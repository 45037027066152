import { Typography } from "@mui/material";

const Copyright = (props) => {
  return (
    <Typography variant="body2" align="center" {...props}>
      {`Copyright © Portharbor.io ` + new Date().getFullYear() + "."}
    </Typography>
  );
};

export default Copyright;
