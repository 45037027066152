import { Box, Grid, List, Typography, useTheme } from "@mui/material";
import cryptoRandomString from "crypto-random-string";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAsyncFeedback from "../../../hooks/useAsyncFeedback";
import { selectorProfile } from "../../../redux/userSlice";
import { insertToArray } from "../../../utils/functions";
import SingleApiKey from "./singleApiKey";
import { useApi } from "../../../api/ContextApi";

const ApiKeyList = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const userData = useSelector(selectorProfile);
  const [handbrake, setHandBrake] = useState(true);
  const [apiKeyData, setApiKeyData] = useState([]);
  const [indexKey, setIndexKey] = useState(0);
  const [handleAsyncApiKey, { loading }] = useAsyncFeedback();
  const api = useApi();

  useEffect(() => {
    handleAsyncApiKey(
      () => api.getApiKeys(),
      null,
      (data) => {
        setApiKeyData(data);
      },
      "loading"
    );
  }, []);

  useEffect(() => {
    if (handbrake) {
      setHandBrake(false);
    } else {
      const apiKey = cryptoRandomString({ length: 25, type: "base64" });
      api.createApiKey(apiKey).then((res) => {
        if (res.status === 200) {
          window.location.reload(false);
        } else if (res.status === 420 || res.status === 500) {
          if (res.data.message) {
            alert(res.data.message);
          } else window.location.reload(false);
        }
      });
    }
  }, [props.addItem]);

  return (
    <>
      <Box component="div">
        <Grid container spacing={2} sx={{ px: "16px" }}>
          <Grid item xs={2}>
            <Typography>API key</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>Created by</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>Name of mini PC</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>Date of use</Typography>
          </Grid>
        </Grid>
        <List>
          {apiKeyData.map((value, index) => {
            return (
              <SingleApiKey
                key={value.id}
                data={value}
                index={index}
                setApiKeyData={setApiKeyData}
                apiKeyData={apiKeyData}
              />
            );
          })}
        </List>
      </Box>
    </>
  );
};

export default ApiKeyList;
