import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Container, Grid, List, Typography } from "@mui/material";
import MiniPCTerminalList from "./miniPC";
import { useLocation, useNavigate } from "react-router-dom";
import { CONSOLEHISTORY, ROOT } from "../../navigation/CONSTANTS";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/userSlice";
import useAsyncFeedback from "../../hooks/useAsyncFeedback";
import SearchComponent from "./search";
import { useApi } from "../../api/ContextApi";

const TerminalList = () => {
  const userData = useSelector(selectUser);
  const navigate = useNavigate();
  const [handleAsyncPorts, { loading }] = useAsyncFeedback();
  const [handleAsyncConsoleHistoryPermission, { loading2 }] =
    useAsyncFeedback();
  const [consoleHistoryPermission, setConsoleHistoryPermission] =
    useState(false);
  const listTerminal = useRef([]);
  const latestVersion = useRef("0.0.0");
  const [filteredListTerminal, setFilteredListTerminal] = useState([]);
  const [filtered, setFiltered] = useState(false);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const api = useApi();

  useEffect(() => {
    handleAsyncPorts(
      () => api.getPortsByAccountType(),
      null,
      (data) => {
        listTerminal.current = data.miniPC;
        latestVersion.current = data.latestVersion;
      },
      "loading"
    );
  }, []);

  useEffect(() => {
    handleAsyncConsoleHistoryPermission(
      () => api.getConsolehistoryPermission(),
      null,
      (data) => {
        console.log(data);
        if (data) setConsoleHistoryPermission(data.allow ? data.allow : false);
      },
      "loading2"
    );
  }, []);

  const handleFilteredListTerminal = (list) => {
    setFilteredListTerminal(list);
    if (!filtered) setFiltered(true);
  };

  const handleBackToHomePage = () => {
    navigate(ROOT);
  };

  const handleToConsoleHistory = () => {
    navigate(CONSOLEHISTORY);
  };

  return (
    <>
      <Container
        maxWidth="xl"
        sx={{
          mx: "auto",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography m="10px" variant="h4">
          Terminal list:
        </Typography>
        {console.log(userData.userType)}
        {userData.userType !== "user" && userData.userType !== "free_user" ? (
          <Button
            variant="contained"
            onClick={handleBackToHomePage}
            sx={{ borderRadius: "50px" }}
          >
            Home page
          </Button>
        ) : consoleHistoryPermission ? (
          <Button
            variant="contained"
            onClick={handleToConsoleHistory}
            sx={{ borderRadius: "50px" }}
          >
            Console History
          </Button>
        ) : null}
      </Container>
      <Container
        sx={{ display: "flex", flexDirection: "column", mx: "auto" }}
        maxWidth="xl"
      >
        <Box minWidth="200px">
          <SearchComponent
            list={listTerminal.current}
            onSubmit={handleFilteredListTerminal}
          />
        </Box>
        <Box
          component="main"
          sx={{
            m: "10px",
            flexGrow: 1,
            height: "100%",
            overflow: "auto",
          }}
        >
          <Grid container>
            <Grid item xs={4}>
              Name
            </Grid>
            <Grid item xs={2}>
              Status
            </Grid>
            <Grid item xs={2}>
              Version
            </Grid>
            <Grid item xs={2}>
              Ports
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
          {(listTerminal.current.length === 0 && !loading) ||
          (filtered && filteredListTerminal.length === 0) ? null : (
            <List>
              {(filtered ? filteredListTerminal : listTerminal.current).map(
                (value) => {
                  return (
                    <MiniPCTerminalList
                      key={value.id}
                      value={value}
                      defaultOpen={params.get("miniPcId") === value.id}
                      latestVersion={latestVersion.current}
                      consoleHistoryPermission={consoleHistoryPermission}
                      disabled={userData.isSuspended}
                    />
                  );
                }
              )}
            </List>
          )}
        </Box>
      </Container>
    </>
  );
};

export default TerminalList;
