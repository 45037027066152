import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  List,
  ListItem,
  Typography,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useAsyncFeedback from "../../hooks/useAsyncFeedback";
import { useEffect, useState } from "react";
import { useApi } from "../../api/ContextApi";

const OrganizationStats = () => {
  const theme = useTheme();
  const [handleAsyncOrg, { loading }] = useAsyncFeedback();
  const [data, setData] = useState([]);
  const api = useApi();

  useEffect(() => {
    handleAsyncOrg(
      () => api.getOrganizationStats(),
      null,
      (data) => {
        setData(data.organizations);
      },
      "loading"
    );
  }, []);

  return (
    <>
      <Box
        sx={{
          width: "50%",
          "@media (max-width: 650px)": { width: "100%" },
        }}
      >
        <Typography sx={{ color: theme.palette.secondary.main }}>
          Organization statistics
        </Typography>
        <List>
          {data.map((value, index) => {
            return (
              <Box key={value.name + index}>
                <Divider
                  sx={{
                    bgcolor: theme.palette.primary.main,
                    borderBottomWidth: "2px",
                  }}
                />
                <ListItem sx={{ px: 0 }}>
                  <Accordion
                    disableGutters
                    sx={{ width: "100%", boxShadow: "none" }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      sx={{
                        flexDirection: "row-reverse",
                        px: 0,
                      }}
                    >
                      <Typography>{value.name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Number of users: {value.numberOfUsers}
                      </Typography>
                      <Typography>
                        Number of online users: {value.numberOfOnlineUsers}
                      </Typography>
                      <Typography>
                        The number of their terminals: {value.numberOfTerminals}
                      </Typography>
                      <Typography>
                        Number of open terminals: {value.numberOfOpenTerminals}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </ListItem>
              </Box>
            );
          })}
        </List>
      </Box>
    </>
  );
};

export default OrganizationStats;
