import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import moment from "moment/moment";

const SingleNotification = (props) => {
  const theme = useTheme();

  console.log(props.notification);

  return (
    <Box
      bgcolor={theme.palette.notifications.background}
      marginBottom="8px"
      borderRadius="10px"
      padding="6px 3px"
      sx={{
        borderColor: props.notification.seen_by_user
          ? theme.palette.notifications.background
          : theme.palette.others.newNotification,
        borderWidth: "2px",
        borderStyle: "solid",
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Typography fontWeight="600">{props.notification.title}</Typography>
        <Tooltip
          title={new Date(props.notification.createdAt).toLocaleString()}
        >
          <Typography fontSize="12px">
            {moment(props.notification.createdAt).startOf("minutes").fromNow()}
          </Typography>
        </Tooltip>
      </Box>
      <Box>
        <Typography fontSize="14px">{props.notification.event.text}</Typography>
      </Box>
    </Box>
  );
};

export default SingleNotification;
