import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  Modal,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import StepperTerminal from "../components/stepper";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import SaveIcon from "@mui/icons-material/Save";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ORGANIZATIONLISTMANAGEMENT } from "../../../navigation/CONSTANTS";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useSelector } from "react-redux";
import { userType } from "../../../redux/userSlice";
import { getOrganizationAndDcName } from "../functions/organization";
import StepperOrgDC from "../components/stepperOrgDC";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import YupPassword from "yup-password";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { ClipLoader } from "react-spinners";
import { useApi } from "../../../api/ContextApi";
YupPassword(Yup);

const SingleUser = (props) => {
  const userData = useSelector(userType);
  const navigate = useNavigate();
  const theme = useTheme();
  const [edit, setEdit] = useState(props.data.edit ? true : false);
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });
  const [loading, setLoading] = useState(false);
  const [showDelete2FA, setShowDelete2FA] = useState(true);
  const [modal, setModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const api = useApi();

  const obj = props.data.vlan_network_interface_maps
    ? props.data.vlan_network_interface_maps.reduce((accumulator, current) => {
        accumulator[current.mini_pc_id] = {
          value: current.value,
          vlan: current.vlan_id,
        };
        return accumulator;
      }, {})
    : {};

  const [vlanNetworkIntefaces, setVlanNetworkInterfaces] = useState(obj);
  const [networkInterfaceError, setNetworkInterfaceError] = useState({});

  useEffect(() => {
    let temp = {};
    let counter = 0;
    Object.entries(vlanNetworkIntefaces).map(([key, value]) => {
      temp[key] = {
        ...value,
        error:
          value.value.length + String(value.vlan ? value.vlan : 0).length > 14,
      };
      if (
        value.value.length + String(value.vlan ? value.vlan : 0).length >
        14
      ) {
        counter++;
      }
    });
    if (counter > 0) {
      setValue("vlanValidator", false);
    } else {
      setValue("vlanValidator", true);
    }
    setNetworkInterfaceError(temp);
  }, [vlanNetworkIntefaces]);

  let temp = "";
  let temp2 = "";
  let mini_pc_id = "";
  let current_mini_pc_id = "";
  let prevCounter = 0;
  let counter = 0;

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Username is required")
      .min(5, "Username is too short")
      .max(50, "Username is too long"),
    email: Yup.string()
      .required("Email address is required")
      .email("Please enter a valid email address"),
    password: props.data.draft
      ? Yup.string()
          .required("Password is required")
          .minLowercase(1, "Password must contain at least 1 lower case letter")
          .minUppercase(1, "Password must contain at least 1 upper case letter")
          .minNumbers(1, "Password must contain at least 1 number")
          .minSymbols(1, "Password must contain at least 1 special character")
          .min(6, "Password is too short")
          .max(50, "Password is too long")
      : Yup.string(),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
    account_type_id: Yup.number().required("Account type is required"),
    organization_id:
      userData.userType === "organization_admin"
        ? Yup.string()
        : Yup.string().required("Organization/DC is required"),
    vlanValidator: Yup.boolean("Has to be boolean.").isTrue("Has to be true."),
  });

  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    reset,
    setError,
    getValues,
  } = useForm({
    defaultValues: {
      name: props.data.name,
      email: props.data.email ? props.data.email : "",
      password: "",
      confirmPassword: "",
      account_type_id: props.data.account_type_id
        ? props.data.account_type_id
        : "",
      organization_id: props.data.organization_id
        ? props.data.organization_id
        : props.data.data_center_id
        ? props.data.data_center_id
        : "",
      vlanValidator: true,
    },
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  useEffect(() => {
    register("vlanValidator");
  }, [register]);

  const handleClickShowPassword = (element) => {
    if (element === "password")
      setShowPassword({ ...showPassword, password: !showPassword.password });
    else if (element === "confirmPassword")
      setShowPassword({
        ...showPassword,
        confirmPassword: !showPassword.confirmPassword,
      });
  };

  const handleSaveButton = (data) => {
    if (props.data.draft) {
      api
        .createUser(
          data.name,
          data.email,
          data.password,
          data.account_type_id,
          data.organization_id
        )
        .then((res) => {
          if (res.status === 200) window.location.reload(false);
          else if (res.data.error === "userAlreadyExists") {
            setError("email", {
              type: "custom",
              message: "User already exists",
            });
          } else if (res.status === 420 || res.status === 500) {
            if (res.data.message) {
              alert(res.data.message);
            } else window.location.reload(false);
          }
        });
    } else {
      api
        .updateUser(
          props.data.id,
          data.name,
          data.email,
          props.data.serial_ports,
          vlanNetworkIntefaces
        )
        .then((res) => {
          if (res.status === 200) window.location.reload(false);
        });
    }
  };

  const handleDeleteUser = () => {
    if (props.data.draft) {
      props.setUserListData(
        props.userListData.filter((item) => item.id !== props.data.id)
      );
    } else {
      setIsDeleting(true);
      api.deleteUser(props.data.id).then((res) => {
        if (res.status === 200) window.location.reload(false);
      });
    }
  };

  return (
    <Box key={props.data.id + "-1"}>
      <Divider
        sx={{
          bgcolor: theme.palette.primary.main,
          borderBottomWidth: "2px",
          mx: "16px",
        }}
      />
      <ListItem sx={{ py: "0px" }}>
        <Accordion
          sx={{
            width: "100%",
            boxShadow: "none",
            bgcolor:
              props.index % 2 === 1
                ? theme.palette.fadedColors.primary
                : theme.palette.background.main,
          }}
          disableGutters={true}
          expanded={edit}
        >
          <Grid
            container
            spacing={2}
            sx={{ alignItems: edit ? "flex-start" : "center" }}
          >
            <Grid item xs={3}>
              {edit ? (
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="standard"
                      label="Name"
                      error={errors.name ? true : false}
                      helperText={errors.name?.message}
                      fullWidth
                    />
                  )}
                />
              ) : (
                <Typography sx={{ wordWrap: "break-word" }}>
                  {props.data.name}
                </Typography>
              )}
            </Grid>
            <Grid item xs={3}>
              {edit ? (
                <Box>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="standard"
                        error={errors.email ? true : false}
                        helperText={errors.email?.message}
                        label="Email"
                        fullWidth
                      />
                    )}
                  />
                  {props.data.draft ? (
                    <>
                      <Controller
                        name="password"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            variant="standard"
                            type={showPassword.password ? "text" : "password"}
                            error={errors.password ? true : false}
                            helperText={errors.password?.message}
                            label="Password"
                            fullWidth
                            sx={{ mt: "20px" }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="Toggle password visibility"
                                    onClick={() =>
                                      handleClickShowPassword("password")
                                    }
                                    edge="end"
                                  >
                                    {showPassword.password ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                      <Controller
                        name="confirmPassword"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            variant="standard"
                            type={
                              showPassword.confirmPassword ? "text" : "password"
                            }
                            error={errors.confirmPassword ? true : false}
                            helperText={errors.confirmPassword?.message}
                            label="Confirm password"
                            fullWidth
                            sx={{ mt: "20px" }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="Toggle password visibility"
                                    onClick={() =>
                                      handleClickShowPassword("confirmPassword")
                                    }
                                    edge="end"
                                  >
                                    {showPassword.confirmPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </>
                  ) : null}
                </Box>
              ) : (
                <Typography sx={{ wordWrap: "break-word" }}>
                  {props.data.email}
                </Typography>
              )}
            </Grid>
            <Grid item xs={3} alignItems="end">
              {props.data.draft && edit ? (
                <StepperOrgDC control={control} userData={userData} />
              ) : (
                <Typography
                  sx={{
                    wordWrap: "break-word",
                    pt: edit ? "27px" : 0,
                  }}
                >
                  {getOrganizationAndDcName(props.data)}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <AccordionSummary aria-expanded={edit} sx={{ m: 0, p: 0 }}>
                <Tooltip title="Edit">
                  <IconButton
                    disabled={edit}
                    sx={{
                      bgcolor: theme.palette.primary.main,
                      color: theme.palette.background.main,
                      ":hover": {
                        bgcolor: theme.palette.secondary.main,
                      },
                      height: "2.5rem",
                      width: "2.5rem",
                      borderColor: theme.palette.primary.main,
                      borderWidth: "1px",
                      borderStyle: "solid",
                      ml: "10px",
                    }}
                    onClick={() => {
                      setEdit(!edit);
                    }}
                  >
                    <EditIcon sx={{ height: "1.8rem", width: "1.8rem" }} />
                  </IconButton>
                </Tooltip>
              </AccordionSummary>
              <Tooltip title="Delete">
                <IconButton
                  sx={{
                    bgcolor: theme.palette.primary.main,
                    color: theme.palette.background.main,
                    ":hover": {
                      bgcolor: theme.palette.secondary.main,
                    },
                    height: "2.5rem",
                    width: "2.5rem",
                    borderColor: theme.palette.primary.main,
                    borderWidth: "1px",
                    borderStyle: "solid",
                    ml: "10px",
                    my: "auto",
                  }}
                  onClick={() => setModal(true)}
                >
                  <DeleteIcon sx={{ height: "1.8rem", width: "1.8rem" }} />
                </IconButton>
              </Tooltip>
              <Modal
                open={modal}
                onClose={() => setModal(false)}
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "white",
                    padding: "20px",
                    borderRadius: "20px",
                    marginBottom: "15px",
                    maxWidth: "600px",
                  }}
                >
                  <Typography
                    id="modal-modal-title"
                    sx={{ textAlign: "center" }}
                  >
                    Delete user
                  </Typography>
                  <Typography
                    className="modal-modal-description"
                    sx={{ marginBottom: "15px" }}
                  >
                    Are you sure you want to delete the{" "}
                    <span style={{ color: theme.palette.primary.main }}>
                      {props.data.email}
                    </span>{" "}
                    account?
                  </Typography>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      disabled={isDeleting}
                      variant="outlined"
                      onClick={() => handleDeleteUser()}
                      sx={{ borderRadius: "10px", borderWidth: "2px" }}
                    >
                      Delete
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => setModal(false)}
                      sx={{ borderRadius: "10px", borderWidth: "2px" }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </Modal>
            </Grid>
          </Grid>
          <AccordionDetails>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                width: "100%",
              }}
            >
              <Box component="div" flex={3} width="50%">
                {props.data.account_type_id === 2 ||
                props.data.draft ||
                !props.data.organization_id ? null : (
                  <Typography variant="h6">Terminals</Typography>
                )}
                <List>
                  {props.data.account_type_id === 2
                    ? null
                    : props.data.serial_ports
                        .sort((a, b) => {
                          if (a.mini_pc.id < b.mini_pc.id) return -1;
                          else if (a.mini_pc.id > b.mini_pc.id) return 1;
                          else {
                            if (a.id < b.id) return -1;
                            else if (a.id > b.id) return 1;
                            else return 0;
                          }
                        })
                        .map((port) => {
                          temp2 =
                            temp !== port.mini_pc.id ? port.mini_pc.name : "";
                          temp = port.mini_pc.id;
                          mini_pc_id =
                            current_mini_pc_id !== port.mini_pc_id
                              ? port.mini_pc_id
                              : "";
                          prevCounter = counter;
                          counter =
                            current_mini_pc_id !== port.mini_pc_id
                              ? 1
                              : ++counter;
                          current_mini_pc_id = port.mini_pc_id;
                          let netInterfaces = props.networkInterfaces.find(
                            (el) => {
                              return el.miniPCId === mini_pc_id;
                            }
                          );
                          return (
                            <Box component="div" key={port.id}>
                              {temp2 !== "" ? (
                                <Divider
                                  sx={{
                                    width: "100%",
                                    bgcolor: theme.palette.primary.main,
                                    marginTop:
                                      userData.userType === "dc_admin"
                                        ? counter === prevCounter &&
                                          counter !== 0
                                          ? networkInterfaceError[
                                              port.mini_pc_id
                                            ]?.error
                                            ? "78px"
                                            : "78px"
                                          : prevCounter === counter + 1
                                          ? networkInterfaceError[
                                              port.mini_pc_id
                                            ]?.error
                                            ? "64px"
                                            : "44px"
                                          : prevCounter === counter + 2
                                          ? "24px"
                                          : "0px"
                                        : "0px",
                                  }}
                                />
                              ) : null}
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <Typography fontSize="16px">
                                    {temp2 === "" ? "" : `MiniPC: ${temp2}`}
                                  </Typography>
                                  {userData.userType === "dc_admin" &&
                                  mini_pc_id !== "" ? (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "end",
                                        width: "calc(100%)",
                                        maxWidth: "300px",
                                        position: "absolute",
                                      }}
                                    >
                                      <Typography
                                        padding="0px 5px 5px 0px"
                                        mb={
                                          networkInterfaceError[port.mini_pc_id]
                                            ?.error
                                            ? "22.97px"
                                            : "0px"
                                        }
                                      >
                                        VLAN:
                                      </Typography>
                                      <Autocomplete
                                        id={`combo-box-vlan-${port.mini_pc_id}`}
                                        options={
                                          netInterfaces
                                            ? netInterfaces.networkInterfaces
                                            : []
                                        }
                                        value={
                                          vlanNetworkIntefaces[
                                            port.mini_pc_id
                                          ] === undefined
                                            ? ""
                                            : vlanNetworkIntefaces[
                                                port.mini_pc_id
                                              ]["value"]
                                        }
                                        onInputChange={(event, newValue) => {
                                          setVlanNetworkInterfaces(
                                            (prevState) => {
                                              let newState = { ...prevState };
                                              if (!newState[port.mini_pc_id]) {
                                                newState[port.mini_pc_id] = {};
                                              }
                                              newState[port.mini_pc_id][
                                                "value"
                                              ] = newValue;
                                              return newState;
                                            }
                                          );
                                        }}
                                        freeSolo
                                        style={{ minWidth: "150px" }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            name={`vlan_network_interface_${port.mini_pc_id}`}
                                            variant="standard"
                                            label="network interface"
                                            title={
                                              networkInterfaceError[
                                                port.mini_pc_id
                                              ]?.error
                                                ? '"Network_interface.vlanId" can be at most 15 characters long'
                                                : undefined
                                            }
                                            error={
                                              networkInterfaceError[
                                                port.mini_pc_id
                                              ] &&
                                              networkInterfaceError[
                                                port.mini_pc_id
                                              ].error
                                            }
                                            helperText={
                                              networkInterfaceError[
                                                port.mini_pc_id
                                              ] &&
                                              networkInterfaceError[
                                                port.mini_pc_id
                                              ].error
                                                ? "Too long"
                                                : ""
                                            }
                                          />
                                        )}
                                      />
                                      <TextField
                                        value={
                                          vlanNetworkIntefaces[
                                            port.mini_pc_id
                                          ] === undefined
                                            ? ""
                                            : vlanNetworkIntefaces[
                                                port.mini_pc_id
                                              ]["vlan"]
                                        }
                                        name={`vlan_id_${port.mini_pc_id}`}
                                        variant="standard"
                                        label="ID"
                                        type="number"
                                        inputProps={{
                                          inputProps: { min: 0, max: 1000 },
                                        }}
                                        style={{
                                          minWidth: "80px",
                                          marginLeft: "5px",
                                        }}
                                        title={
                                          networkInterfaceError[port.mini_pc_id]
                                            ?.error
                                            ? '"Network_interface.vlanId" can be at most 15 characters long'
                                            : undefined
                                        }
                                        error={
                                          networkInterfaceError[
                                            port.mini_pc_id
                                          ] &&
                                          networkInterfaceError[port.mini_pc_id]
                                            .error
                                        }
                                        helperText={
                                          networkInterfaceError[
                                            port.mini_pc_id
                                          ] &&
                                          networkInterfaceError[port.mini_pc_id]
                                            .error
                                            ? " "
                                            : ""
                                        }
                                        onChange={(event) => {
                                          setVlanNetworkInterfaces(
                                            (prevState) => {
                                              let newState = { ...prevState };
                                              if (event.target.value < 0) {
                                                event.target.value = 0;
                                              }
                                              if (!newState[port.mini_pc_id]) {
                                                newState[port.mini_pc_id] = {};
                                              }
                                              newState[port.mini_pc_id][
                                                "vlan"
                                              ] = event.target.value;
                                              return newState;
                                            }
                                          );
                                        }}
                                      />
                                      <Controller
                                        name="vlanValidator"
                                        control={control}
                                        render={({ field }) => (
                                          <TextField
                                            {...field}
                                            variant="standard"
                                            type={"checkbox"}
                                            fullWidth
                                            sx={{ mt: "20px", display: "none" }}
                                          />
                                        )}
                                      />
                                    </Box>
                                  ) : null}
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="space-between"
                                  sx={{
                                    marginTop: "16px",
                                    paddingTop: "0 !important",
                                  }}
                                >
                                  <Tooltip
                                    title={port.name ? port.name : port.pnpId}
                                  >
                                    <Typography
                                      fontSize="16px"
                                      sx={{
                                        textDecoration: port.removed
                                          ? "line-through"
                                          : port.added
                                          ? "underline"
                                          : "none",
                                        textDecorationColor: port.removed
                                          ? theme.palette.others.red
                                          : theme.palette.others.green,
                                        textDecorationStyle: port.removed
                                          ? "solid"
                                          : "wavy",
                                        maxWidth: "90%",
                                      }}
                                      textOverflow="ellipsis"
                                      noWrap={true}
                                    >
                                      {port.name ? port.name : port.pnpId}
                                    </Typography>
                                  </Tooltip>
                                  <Tooltip
                                    title={port.removed ? "Get back" : "Remove"}
                                  >
                                    <IconButton
                                      sx={{
                                        bgcolor: theme.palette.background.main,
                                        color: port.removed
                                          ? theme.palette.others.green
                                          : theme.palette.others.red,
                                        ":hover": {
                                          color: port.removed
                                            ? theme.palette.others.dark_green
                                            : theme.palette.others.dark_red,
                                        },
                                        fontSize: "17px",
                                        height: "1rem",
                                        width: "1rem",
                                        ml: "10px",
                                      }}
                                      onClick={() => {
                                        props.setUserListData(
                                          props.userListData.map((user) => {
                                            if (user.id === props.data.id) {
                                              user.serial_ports =
                                                user.serial_ports.map(
                                                  (port1) => {
                                                    if (port.id === port1.id) {
                                                      port1.removed =
                                                        port.removed
                                                          ? false
                                                          : true;
                                                    }
                                                    return port1;
                                                  }
                                                );
                                              return user;
                                            } else return user;
                                          })
                                        );
                                      }}
                                    >
                                      {port.removed ? (
                                        <AddCircleOutlineIcon fontSize="2.5rem" />
                                      ) : (
                                        <RemoveCircleOutlineIcon fontSize="2.5rem" />
                                      )}
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </Box>
                          );
                        })}
                </List>
              </Box>
              <Box component="div" flex={2} ml="20px" width="33.4%">
                {props.data.account_type_id === 2 ||
                props.data.draft ||
                !props.data.organization_id ? null : (
                  <StepperTerminal
                    userPorts={props.userPorts}
                    setUserListData={props.setUserListData}
                    userListData={props.userListData}
                    userData={props.data}
                  />
                )}
                {!props.data.draft &&
                props.data.secret2FA !== null &&
                showDelete2FA ? (
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    alignItems={"center"}
                  >
                    <Button
                      variant="outlined"
                      disabled={loading}
                      sx={{
                        borderRadius: "50px",
                        borderWidth: "2px",
                        ":hover": {
                          borderWidth: "2px",
                          bgcolor: theme.palette.background.main,
                        },
                        m: "0px",
                        bgcolor: theme.palette.background.main,
                        my: "10px",
                        mr: "10px",
                      }}
                      onClick={() => {
                        setLoading(true);
                        api
                          .update2FASecret("delete", props.data.id)
                          .then((response) => {
                            console.log(response);
                            setLoading(false);
                            setShowDelete2FA(false);
                          })
                          .catch((err) => {
                            console.log(
                              "Error on deleting 2FA secret from " +
                                props.data.email +
                                ":\n" +
                                err
                            );
                            setLoading(false);
                          });
                      }}
                    >
                      Disable 2FA
                    </Button>
                    {loading ? (
                      <ClipLoader color={theme.palette.primary.main} />
                    ) : null}
                  </Box>
                ) : null}
                {props.data.organization_id ||
                props.data.data_center_id ||
                props.data.draft ? null : (
                  <Button
                    variant="outlined"
                    sx={{
                      borderRadius: "50px",
                      borderWidth: "2px",
                      ":hover": {
                        borderWidth: "2px",
                        bgcolor: theme.palette.background.main,
                      },
                      m: "0px",
                      bgcolor: theme.palette.background.main,
                    }}
                    onClick={() => {
                      navigate(ORGANIZATIONLISTMANAGEMENT);
                    }}
                  >
                    Add organization
                  </Button>
                )}
              </Box>
              <Box component="div" flex={1} width="16.6%">
                <Box sx={{ display: "flex", justifyContent: "end" }}>
                  {props.data.draft ? null : (
                    <Tooltip title="Cancel">
                      <IconButton
                        sx={{
                          bgcolor: theme.palette.primary.main,
                          color: theme.palette.background.main,
                          ":hover": {
                            bgcolor: theme.palette.secondary.main,
                          },
                          height: "2.5rem",
                          width: "2.5rem",
                          borderColor: theme.palette.primary.main,
                          borderWidth: "1px",
                          borderStyle: "solid",
                          ml: "10px",
                          my: "auto",
                        }}
                        onClick={() => {
                          setEdit(false);
                        }}
                      >
                        <ClearIcon
                          sx={{
                            height: "1.8rem",
                            width: "1.8rem",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip title="Save">
                    <IconButton
                      sx={{
                        bgcolor: theme.palette.primary.main,
                        color: theme.palette.background.main,
                        ":hover": {
                          bgcolor: theme.palette.secondary.main,
                        },
                        height: "2.5rem",
                        width: "2.5rem",
                        borderColor: theme.palette.primary.main,
                        borderWidth: "1px",
                        borderStyle: "solid",
                        ml: "10px",
                        mr: "-16px",
                        my: "auto",
                      }}
                      onClick={handleSubmit(handleSaveButton)}
                    >
                      <SaveIcon sx={{ height: "1.8rem", width: "1.8rem" }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
            {userData.userType === "dc_admin" ? (
              <Box
                height={counter === 1 ? "48px" : counter === 2 ? "24px" : "0px"}
                width="100%"
              ></Box>
            ) : null}
          </AccordionDetails>
        </Accordion>
      </ListItem>
    </Box>
  );
};

export default SingleUser;
