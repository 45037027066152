import {
  Box,
  Button,
  Divider,
  Drawer,
  List,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SingleNotification from "./SingleNotification";

const drawerMinWidth = 300;

const NotificationDrawer = (props) => {
  const theme = useTheme();

  return (
    <Drawer
      anchor="right"
      open={props.anchorDrawer}
      onClose={props.openNotifications}
      sx={{
        width: "40%",
        minWidth: drawerMinWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: "40%",
          minWidth: drawerMinWidth,
          boxSizing: "border-box",
        },
      }}
      ModalProps={{ disableScrollLock: true }}
    >
      <Toolbar />
      <Box sx={{ overflow: "auto" }}>
        <Box
          paddingX="20px"
          paddingY="10px"
          marginY="5px"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography fontSize="1.5rem" color={theme.palette.secondary.main}>
            Notifications
          </Typography>
          <Button
            variant="text"
            onClick={props.openNotifications}
            sx={{
              color: theme.palette.secondary.main,
              ":hover": {
                backgroundColor: theme.palette.notifications.background,
              },
              borderRadius: "100px",
            }}
          >
            <Typography marginRight="5px">Hide</Typography>
            <ArrowForwardIosIcon
              fontSize="inherit"
              sx={{ color: theme.palette.secondary.main }}
            />
          </Button>
        </Box>
        <Divider
          sx={{
            bgcolor: theme.palette.secondary.main,
            borderBottomWidth: "2px",
          }}
        />
        <Box marginX="25px">
          <List>
            {props.notifications.map((notification) => {
              return (
                <SingleNotification
                  key={notification.id}
                  notification={notification}
                />
              );
            })}
          </List>
        </Box>
      </Box>
    </Drawer>
  );
};

export default NotificationDrawer;
