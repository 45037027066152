import {
  Box,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  List,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import useAsyncFeedback from "../../../hooks/useAsyncFeedback";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import Row3x3x3x3 from "./components/row3x3x3x3";
import { useApi } from "../../../api/ContextApi";

const CurrentLimits = (props) => {
  const theme = useTheme();
  const api = useApi();

  const getSumOfCosts = () => {
    const cost =
      props.data.pricingPlan.priceForPod *
        (props.data.pods - props.data.pricingPlan.freePodNumber > 0
          ? props.data.pods - props.data.pricingPlan.freePodNumber
          : 0) +
      props.data.pricingPlan.priceForConnector * props.data.connectors +
      props.data.pricingPlan.priceForAdditionalUser *
        (props.data.users - props.data.pricingPlan.freeUsers > 0
          ? props.data.users - props.data.pricingPlan.freeUsers
          : 0);
    switch (props.data.pricingPlan.currency) {
      case "EUR":
        return `= \u20AC${cost}`;
      case "PLN":
        return `= ${cost}zł`;
      case "USD":
        return `= $${cost}`;
      default:
        return "";
    }
  };

  if (!props.data) {
    return (
      <>
        <Box>
          <Typography variant="h6">Pricing plan - FREE</Typography>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              Features
            </Grid>
            <Grid item xs={3}>
              Limit
            </Grid>
            <Grid item xs={6} />
          </Grid>
          <Divider
            sx={{
              borderBottomWidth: "2px",
              width: "100%",
              bgcolor: theme.palette.primary.main,
              width: "50%",
            }}
          />
          <Row3x3x3x3
            name="MiniPCs"
            inUse={props.freePricingPlan.freePodNumber}
          />
          <Row3x3x3x3
            name="Connectors"
            inUse={props.freePricingPlan.maxConnectors}
          />
          <Row3x3x3x3
            name="Users"
            inUse={props.freePricingPlan.freeUsers}
            last={true}
          />
        </Box>
      </>
    );
  }

  return (
    <>
      <Box>
        <Typography variant="h6">
          Pricing plan {props.data ? "- " + props.data.pricingPlan.name : ""}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            Features
          </Grid>
          <Grid item xs={3}>
            In usage
          </Grid>
          <Grid item xs={3}>
            Limit
          </Grid>
          <Grid item xs={3}>
            {"Cost per feature(monthly)"}
          </Grid>
        </Grid>
        <Divider
          sx={{
            borderBottomWidth: "2px",
            width: "100%",
            bgcolor: theme.palette.primary.main,
          }}
        />
        <Row3x3x3x3
          name="MiniPCs"
          inUse={props.data.pods}
          limit={props.data.podsLimit}
          free={props.data.pricingPlan.freePodNumber}
          cost={props.data.pricingPlan.priceForPod}
          currency={props.data.pricingPlan.currency}
        />
        <Row3x3x3x3
          name="Connectors"
          inUse={props.data.connectors}
          limit={props.data.connectorsLimit}
          free={0}
          cost={props.data.pricingPlan.priceForConnector}
          currency={props.data.pricingPlan.currency}
        />
        <Row3x3x3x3
          name="Users"
          inUse={props.data.users}
          limit={props.data.limitUsers}
          free={props.data.pricingPlan.freeUsers}
          cost={props.data.pricingPlan.priceForAdditionalUser}
          currency={props.data.pricingPlan.currency}
        />
        <Grid container columnSpacing={2}>
          <Grid item xs={9} />
          <Grid item>{getSumOfCosts()}</Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CurrentLimits;
