import { useSelector, useDispatch } from "react-redux";
import { selectUser, setReset } from "../../redux/userSlice";

export function useProvideAuth() {
  const userData = useSelector(selectUser);
  const dispatch = useDispatch();

  const signout = () => {
    return new Promise((resolve, reject) => {
      try {
        if (userData.token) {
          dispatch(setReset());
        }
        resolve(true);
      } catch (error) {
        console.error("signout error!==", error);
        reject("signout error!");
      }
    });
  };

  return {
    userData,
    signout,
  };
}
