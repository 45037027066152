import { Box, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { Legend, Pie, PieChart, ResponsiveContainer } from "recharts";
import useAsyncFeedback from "../../hooks/useAsyncFeedback";
import { useApi } from "../../api/ContextApi";

const DeviceStatistics = () => {
  const theme = useTheme();
  const [handleAsyncDevices, { loading }] = useAsyncFeedback();
  const [data, setData] = useState({
    version: [
      { name: "latest", quantity: 0, fill: "#00393D" },
      { name: "old", quantity: 0, fill: "#00A1AD" },
      { name: "unsupported", quantity: 0, fill: "#5f9ea0" },
    ],
    status: [
      { name: "online", quantity: 0, fill: "#00393D" },
      { name: "offline", quantity: 0, fill: "#00A1AD" },
      { name: "suspended", quantity: 0, fill: "#5f9ea0" },
    ],
  });
  const api = useApi();

  useEffect(() => {
    handleAsyncDevices(
      () => api.getDeviceStats(),
      null,
      (data) => {
        let tempVersion = [0, 0, 0];
        let tempStatus = [0, 0, 0];
        data.miniPC.forEach((miniPC) => {
          let miniPCVersion = miniPC.software_version.slice(".")[0];
          let latestVersion = data.latestVersion.slice(".")[0];
          if (miniPCVersion !== latestVersion) {
            tempVersion[2]++;
          } else if (miniPC.software_version !== data.latestVersion) {
            tempVersion[1]++;
          } else {
            tempVersion[0]++;
          }
          switch (miniPC.status) {
            case "online":
              tempStatus[0]++;
              break;
            case "offline":
              tempStatus[1]++;
              break;
            case "suspended":
              tempStatus[2]++;
              break;
            default:
          }
          setData({
            version: [
              { name: "latest", quantity: tempVersion[0], fill: "#00393D" },
              { name: "old", quantity: tempVersion[1], fill: "#00A1AD" },
              {
                name: "unsupported",
                quantity: tempVersion[2],
                fill: "#5f9ea0",
              },
            ],
            status: [
              { name: "online", quantity: tempStatus[0], fill: "#00393D" },
              { name: "offline", quantity: tempStatus[1], fill: "#00A1AD" },
              { name: "suspended", quantity: tempStatus[2], fill: "#5f9ea0" },
            ],
          });
        });
      },
      "loading"
    );
  }, []);

  return (
    <>
      <Typography sx={{ color: theme.palette.secondary.main }}>
        Device statistics
      </Typography>
      <Box
        component={"div"}
        sx={{
          display: "flex",
          "@media (max-width: 650px)": { display: "block" },
        }}
      >
        <Box
          component={"div"}
          sx={{
            height: "300px",
            minWidth: "280px",
            mb: "20px",
            pr: "5px",
            width: "20%",
          }}
        >
          <Typography textAlign={"center"}>Versions</Typography>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart width={400} height={400}>
              <Pie
                data={data.version}
                dataKey="quantity"
                cx="50%"
                cy="50%"
                outerRadius={60}
                innerRadius={20}
                label
              />
              <Legend verticalAlign="top" height={36} />
            </PieChart>
          </ResponsiveContainer>
        </Box>
        <Box
          component={"div"}
          sx={{
            height: "300px",
            minWidth: "280px",
            mb: "20px",
            pl: "5px",
            width: "20%",
          }}
        >
          <Typography textAlign={"center"}>Status</Typography>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart width={400} height={400}>
              <Pie
                data={data.status}
                dataKey="quantity"
                cx="50%"
                cy="50%"
                outerRadius={60}
                innerRadius={20}
                label
              />
              <Legend verticalAlign="top" height={36} />
            </PieChart>
          </ResponsiveContainer>
        </Box>
      </Box>
    </>
  );
};

export default DeviceStatistics;
