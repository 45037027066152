import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  ListItem,
  MenuItem,
  Modal,
  Select,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { USERLISTMANAGEMENT } from "../../../navigation/CONSTANTS";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ClearIcon from "@mui/icons-material/Clear";
import SaveIcon from "@mui/icons-material/Save";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import useAsyncFeedback from "../../../hooks/useAsyncFeedback";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useApi } from "../../../api/ContextApi";

const SingleOrganization = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [edit, setEdit] = useState(props.data.edit ? true : false);
  const [loadingAdmins, setLoadingAdmins] = useState(false);
  const [handleAsyncAdmins, { loading }] = useAsyncFeedback();
  const [admins, setAdmins] = useState([]);
  const [modal, setModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const api = useApi();

  const adminName = () => {
    if (!props.data.accounts || props.data.accounts.length === 0) {
      return { name: "", id: "" };
    } else {
      const admin = props.data.accounts.find(
        (user) => user.id === props.data.admin_id
      );
      if (admin) {
        return admin;
      } else {
        return { name: "", id: "" };
      }
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .min(5, "Name is too short")
      .max(50, "Name is too long"),
    email: props.data.draft
      ? Yup.string()
      : Yup.string().required("Email address is required"),
    description: Yup.string().required("Description is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    reset,
    setError,
  } = useForm({
    defaultValues: {
      name: props.data.name,
      email: adminName().id,
      description: props.data.description ? props.data.description : "",
    },
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  useEffect(() => {
    if (loadingAdmins && !edit) {
      handleAsyncAdmins(
        () => api.getAdminsByOrganization(props.data.id),
        null,
        (data) => {
          setAdmins(data);
          setEdit(!edit);
          setLoadingAdmins(!loadingAdmins);
        },
        "loading"
      );
    }
  }, [loadingAdmins]);

  const handleSaveButton = (data) => {
    if (props.data.draft) {
      api.createOrganization(data.name, data.description).then((res) => {
        if (res.status === 200) window.location.reload(false);
      });
    } else {
      api
        .updateOrganization(
          props.data.id,
          data.name,
          data.email,
          data.description
        )
        .then((res) => {
          if (res.status === 200) window.location.reload(false);
        });
    }
  };

  const handleDeleteButton = () => {
    if (props.data.draft) {
      props.setOrganizationData(
        props.organizationData.filter((item) => item.id !== props.data.id)
      );
    } else {
      setIsDeleting(true);
      api.deleteOrganization(props.data.id).then((res) => {
        if (res.status === 200) window.location.reload(false);
      });
    }
  };

  return (
    <Box key={props.data.id}>
      <Divider
        sx={{
          bgcolor: theme.palette.primary.main,
          borderBottomWidth: "2px",
          mx: "16px",
        }}
      />
      <ListItem key={props.data.id} sx={{ py: "0px" }}>
        <Accordion
          sx={{
            width: "100%",
            boxShadow: "none",
            bgcolor:
              props.index % 2 === 1
                ? theme.palette.fadedColors.primary
                : theme.palette.background.main,
          }}
          disableGutters
          expanded={edit}
        >
          <Grid
            container
            spacing={2}
            sx={{ alignItems: edit ? "flex-start" : "center" }}
          >
            <Grid item xs={3}>
              {edit ? (
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="standard"
                      label="Name"
                      fullWidth
                      error={errors.name ? true : false}
                      helperText={errors.name?.message}
                    />
                  )}
                />
              ) : (
                <Typography sx={{ wordWrap: "break-word" }}>
                  {props.data.name}
                </Typography>
              )}
            </Grid>
            <Grid item xs={3}>
              {!props.data.draft && edit ? (
                <>
                  <InputLabel sx={{ fontSize: "13px" }}>Admin email</InputLabel>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Admin email"
                        variant="standard"
                        fullWidth
                        size="small"
                      >
                        {admins.map((admin) => {
                          return (
                            <MenuItem key={admin.id} value={admin.id}>
                              {admin.email}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                  />
                </>
              ) : (
                <Typography sx={{ wordWrap: "break-word" }}>
                  {adminName().name}
                </Typography>
              )}
            </Grid>
            <Grid item xs={3}>
              {edit ? (
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="standard"
                      label="Description"
                      multiline
                      maxRows={4}
                      fullWidth
                      error={errors.description ? true : false}
                      helperText={errors.description?.message}
                    />
                  )}
                />
              ) : (
                <Typography sx={{ wordWrap: "break-word" }}>
                  {props.data.description}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              xs={3}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              {props.data.draft ? null : (
                <Tooltip title="Users">
                  <IconButton
                    disabled={edit}
                    sx={{
                      bgcolor: theme.palette.primary.main,
                      color: theme.palette.background.main,
                      ":hover": {
                        bgcolor: theme.palette.secondary.main,
                      },
                      height: "2.5rem",
                      width: "2.5rem",
                      borderColor: theme.palette.primary.main,
                      borderWidth: "1px",
                      borderStyle: "solid",
                      ml: "10px",
                      my: "auto",
                    }}
                    onClick={() => {
                      navigate(
                        USERLISTMANAGEMENT +
                          "?organizationId=" +
                          props.data.id +
                          "&&organizationName=" +
                          props.data.name
                      );
                    }}
                  >
                    <PeopleAltIcon sx={{ height: "1.8rem", width: "1.8rem" }} />
                  </IconButton>
                </Tooltip>
              )}
              <AccordionSummary aria-expanded={edit} sx={{ p: 0 }}>
                {props.data.draft ? null : (
                  <Tooltip title="Edit">
                    <IconButton
                      disabled={edit}
                      sx={{
                        bgcolor: theme.palette.primary.main,
                        color: theme.palette.background.main,
                        ":hover": {
                          bgcolor: theme.palette.secondary.main,
                        },
                        height: "2.5rem",
                        width: "2.5rem",
                        borderColor: theme.palette.primary.main,
                        borderWidth: "1px",
                        borderStyle: "solid",
                        ml: "10px",
                      }}
                      onClick={() => {
                        setLoadingAdmins(!loadingAdmins);
                      }}
                    >
                      <EditIcon sx={{ height: "1.8rem", width: "1.8rem" }} />
                    </IconButton>
                  </Tooltip>
                )}
              </AccordionSummary>
              <Tooltip title="Delete">
                <IconButton
                  sx={{
                    bgcolor: theme.palette.primary.main,
                    color: theme.palette.background.main,
                    ":hover": {
                      bgcolor: theme.palette.secondary.main,
                    },
                    height: "2.5rem",
                    width: "2.5rem",
                    borderColor: theme.palette.primary.main,
                    borderWidth: "1px",
                    borderStyle: "solid",
                    ml: "10px",
                    my: "12px",
                  }}
                  onClick={() => setModal(true)}
                >
                  <DeleteIcon sx={{ height: "1.8rem", width: "1.8rem" }} />
                </IconButton>
              </Tooltip>
              <Modal
                open={modal}
                onClose={() => setModal(false)}
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "white",
                    padding: "20px",
                    borderRadius: "20px",
                    marginBottom: "15px",
                    maxWidth: "600px",
                  }}
                >
                  <Typography
                    id="modal-modal-title"
                    sx={{ textAlign: "center" }}
                  >
                    Delete organization
                  </Typography>
                  <Typography
                    className="modal-modal-description"
                    sx={{ marginBottom: "15px" }}
                  >
                    Are you sure you want to delete the{" "}
                    <span style={{ color: theme.palette.primary.main }}>
                      {props.data.name}
                    </span>{" "}
                    organization?
                  </Typography>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      disabled={isDeleting}
                      variant="outlined"
                      onClick={() => handleDeleteButton()}
                      sx={{ borderRadius: "10px", borderWidth: "2px" }}
                    >
                      Delete
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => setModal(false)}
                      sx={{ borderRadius: "10px", borderWidth: "2px" }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </Modal>
            </Grid>
          </Grid>
          <AccordionDetails
            sx={{
              padding: "0px !important",
            }}
          >
            <Grid container spacing={2} mb="12px">
              <Grid item xs={3}></Grid>
              <Grid
                item
                xs={9}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                {props.data.draft ? null : (
                  <Tooltip title="Cancel">
                    <IconButton
                      sx={{
                        bgcolor: theme.palette.primary.main,
                        color: theme.palette.background.main,
                        ":hover": {
                          bgcolor: theme.palette.secondary.main,
                        },
                        height: "2.5rem",
                        width: "2.5rem",
                        borderColor: theme.palette.primary.main,
                        borderWidth: "1px",
                        borderStyle: "solid",
                        ml: "10px",
                        my: "auto",
                      }}
                      onClick={() => {
                        setEdit(!edit);
                        reset();
                      }}
                    >
                      <ClearIcon sx={{ height: "1.8rem", width: "1.8rem" }} />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Save">
                  <IconButton
                    sx={{
                      bgcolor: theme.palette.primary.main,
                      color: theme.palette.background.main,
                      ":hover": {
                        bgcolor: theme.palette.secondary.main,
                      },
                      height: "2.5rem",
                      width: "2.5rem",
                      borderColor: theme.palette.primary.main,
                      borderWidth: "1px",
                      borderStyle: "solid",
                      ml: "10px",
                      my: "auto",
                    }}
                    onClick={handleSubmit(handleSaveButton)}
                  >
                    <SaveIcon sx={{ height: "1.8rem", width: "1.8rem" }} />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </ListItem>
    </Box>
  );
};

export default SingleOrganization;
