import {
  Box,
  Divider,
  IconButton,
  Input,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import SaveIcon from "@mui/icons-material/Save";
import { useDispatch, useSelector } from "react-redux";
import {
  selectorProfile,
  selectorProfileAdminDC,
  selectorProfileGlobalAdmin,
  updateProfile,
  updateProfileAdminDC,
  userType,
} from "../../redux/userSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useApi } from "../../api/ContextApi";

const GeneralInformations = () => {
  const userTypeData = useSelector(userType);
  const profileData = useSelector(
    userTypeData.userType === "global_admin"
      ? selectorProfileGlobalAdmin
      : userTypeData.userType === "dc_admin"
      ? selectorProfileAdminDC
      : selectorProfile
  );
  const api = useApi();

  const validationSchema =
    userTypeData.userType === "dc_admin"
      ? Yup.object().shape({
          name: Yup.string()
            .required("Username is required")
            .min(5, "Username is too short")
            .max(50, "Username is too long"),
          email: Yup.string()
            .required("Email address is required")
            .email("Please enter a valid email address"),
          vat_number: Yup.string()
            .max(20, "VAT number is too long")
            .required("VAT number is required"),
          phone: Yup.number().required("Phone number is required"),
          address: Yup.string().required("Address is required"),
          emailTech: Yup.string()
            .required("Email is required")
            .email("Please enter a valid email address"),
          emailPay: Yup.string()
            .required("Email is required")
            .email("Please enter a valid email address"),
          identificator: Yup.string().required("Identificator is required"),
        })
      : Yup.object().shape({
          name: Yup.string()
            .required("Username is required")
            .min(5, "Username is too short")
            .max(50, "Username is too long"),
          email: Yup.string()
            .required("Email address is required")
            .email("Please enter a valid email address"),
        });

  const defaultValues =
    userTypeData.userType === "dc_admin"
      ? {
          name: profileData.name,
          email: profileData.email,
          vat_number: profileData.VAT_number,
          phone: profileData.phone,
          address: profileData.address,
          emailTech: profileData.email_tech,
          emailPay: profileData.email_pay,
          identificator: profileData.identificator,
        }
      : {
          name: profileData.name,
          email: profileData.email,
        };

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  const theme = useTheme();
  const dispatch = useDispatch();
  const [edit, setEdit] = useState();

  const handleSaveButton = (data) => {
    if (userTypeData.userType === "dc_admin") {
      api.updateProfileData({
        name: data.name,
        email: data.email,
        VAT_number: data.vat_number,
        phone: data.phone,
        address: data.address,
        email_tech: data.emailTech,
        email_pay: data.emailPay,
        identificator: data.identificator,
      });
      dispatch(
        updateProfileAdminDC({
          name: data.name,
          email: data.email,
          VAT_number: data.vat_number,
          phone: data.phone,
          address: data.address,
          email_tech: data.emailTech,
          email_pay: data.emailPay,
          identificator: data.identificator,
        })
      );
    } else {
      api.updateProfileData({ name: data.name, email: data.email });
      dispatch(updateProfile({ name: data.name, email: data.email }));
    }
    setEdit(false);
    window.location.reload(false);
  };

  return (
    <>
      <Typography variant="h6">{profileData.name}</Typography>
      <Box
        display="flex"
        alignItems={"center"}
        sx={{
          height: "50px",
        }}
      >
        <Typography>General informations</Typography>
        <Tooltip title="Edit">
          <IconButton
            disabled={edit}
            sx={{
              bgcolor: theme.palette.primary.main,
              color: theme.palette.background.main,
              ":hover": {
                bgcolor: theme.palette.secondary.main,
              },
              height: "2.5rem",
              width: "2.5rem",
              borderColor: theme.palette.primary.main,
              borderWidth: "1px",
              borderStyle: "solid",
              ml: "10px",
            }}
            onClick={() => {
              setEdit(!edit);
            }}
          >
            <EditIcon sx={{ height: "1.8rem", width: "1.8rem" }} />
          </IconButton>
        </Tooltip>
        {edit ? (
          <>
            <Tooltip title="Cancel">
              <IconButton
                sx={{
                  bgcolor: theme.palette.primary.main,
                  color: theme.palette.background.main,
                  ":hover": {
                    bgcolor: theme.palette.secondary.main,
                  },
                  height: "2.5rem",
                  width: "2.5rem",
                  borderColor: theme.palette.primary.main,
                  borderWidth: "1px",
                  borderStyle: "solid",
                  ml: "10px",
                  my: "auto",
                }}
                onClick={() => {
                  setEdit(false);
                }}
              >
                <ClearIcon sx={{ height: "1.8rem", width: "1.8rem" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Save">
              <IconButton
                sx={{
                  bgcolor: theme.palette.primary.main,
                  color: theme.palette.background.main,
                  ":hover": {
                    bgcolor: theme.palette.secondary.main,
                  },
                  height: "2.5rem",
                  width: "2.5rem",
                  borderColor: theme.palette.primary.main,
                  borderWidth: "1px",
                  borderStyle: "solid",
                  ml: "10px",
                  mr: "-16px",
                  my: "auto",
                }}
                onClick={handleSubmit(handleSaveButton)}
              >
                <SaveIcon sx={{ height: "1.8rem", width: "1.8rem" }} />
              </IconButton>
            </Tooltip>
          </>
        ) : null}
      </Box>
      <Divider
        sx={{
          bgcolor: theme.palette.primary.main,
          borderBottomWidth: "2px",
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "30%" }}>
          <Typography
            sx={{
              wordWrap: "break-word",
              height: "50px",
              display: "flex",
              alignItems: "center",
            }}
          >
            Name:
          </Typography>
        </Box>
        <Box sx={{ width: "70%" }}>
          {edit ? (
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  variant="standard"
                  error={errors.name ? true : false}
                  title={errors.name?.message}
                  required
                  sx={{
                    height: "50px",
                    width: "100%",
                    padding: "0px 0px 0px 10px",
                    "& .MuiInputBase-input.MuiInput-input": {
                      WebkitTextFillColor: "gray",
                      borderBottomColor: errors.name ? "red" : "primary",
                      borderBottomWidth: "2px",
                      borderBottomStyle: "solid",
                      fontStyle: "italic",
                    },
                  }}
                  disableUnderline={true}
                />
              )}
            />
          ) : (
            <Typography
              sx={{
                wordWrap: "break-word",
                height: "50px",
                display: "flex",
                alignItems: "center",
                width: "100%",
                overflow: "clip",
                padding: "0px 0px 0px 10px",
              }}
            >
              {profileData.name}
            </Typography>
          )}
        </Box>
      </Box>
      <Divider
        sx={{
          bgcolor: theme.palette.primary.main,
          borderBottomWidth: "2px",
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "30%" }}>
          <Typography
            sx={{
              wordWrap: "break-word",
              height: "50px",
              display: "flex",
              alignItems: "center",
            }}
          >
            Email:
          </Typography>
        </Box>
        <Box sx={{ width: "70%" }}>
          {edit ? (
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  variant="standard"
                  autoComplete="email"
                  error={errors.email ? true : false}
                  title={errors.email?.message}
                  required
                  sx={{
                    height: "50px",
                    width: "100%",
                    padding: "0px 0px 0px 10px",
                    "& .MuiInputBase-input.MuiInput-input": {
                      WebkitTextFillColor: "gray",
                      borderBottomColor: errors.email ? "red" : "primary",
                      borderBottomWidth: "2px",
                      borderBottomStyle: "solid",
                      fontStyle: "italic",
                    },
                  }}
                  disableUnderline={true}
                />
              )}
            />
          ) : (
            <Typography
              sx={{
                wordWrap: "break-word",
                height: "50px",
                display: "flex",
                alignItems: "center",
                width: "100%",
                padding: "0px 0px 0px 10px",
              }}
            >
              {profileData.email}
            </Typography>
          )}
        </Box>
      </Box>
      <Divider
        sx={{
          bgcolor: theme.palette.primary.main,
          borderBottomWidth: "2px",
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "30%" }}>
          <Typography
            sx={{
              wordWrap: "break-word",
              wordBreak: "break-all",
              height: "50px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {userTypeData.userType === "dc_admin"
              ? "Data center:"
              : "Organization:"}
          </Typography>
        </Box>
        <Box sx={{ width: "70%" }}>
          <Typography
            sx={{
              wordWrap: "break-word",
              height: "50px",
              display: "flex",
              alignItems: "center",
              width: "100%",
              overflow: "clip",
              padding: "0px 0px 0px 10px",
            }}
          >
            {userTypeData.userType === "dc_admin"
              ? profileData.data_center
              : profileData.organization}
          </Typography>
        </Box>
      </Box>
      {userTypeData.userType === "global_admin" ? null : (
        <>
          <Divider
            sx={{
              bgcolor: theme.palette.primary.main,
              borderBottomWidth: "2px",
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "30%" }}>
              <Typography
                sx={{
                  wordWrap: "break-word",
                  wordBreak: "break-all",
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Admin:
              </Typography>
            </Box>
            <Box sx={{ width: "70%" }}>
              <Typography
                sx={{
                  wordWrap: "break-word",
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  overflow: "clip",
                  padding: "0px 0px 0px 10px",
                }}
              >
                {profileData.adminName}
              </Typography>
            </Box>
          </Box>
          <Divider
            sx={{
              bgcolor: theme.palette.primary.main,
              borderBottomWidth: "2px",
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "30%" }}>
              <Typography
                sx={{
                  wordWrap: "break-word",
                  wordBreak: "break-all",
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Admin email:
              </Typography>
            </Box>
            <Box sx={{ width: "70%" }}>
              <Typography
                sx={{
                  wordWrap: "break-word",
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  overflow: "clip",
                  padding: "0px 0px 0px 10px",
                }}
              >
                {profileData.adminEmail}
              </Typography>
            </Box>
          </Box>
          {userTypeData.userType === "free_user" ? (
            <>
              <Divider
                sx={{
                  bgcolor: theme.palette.primary.main,
                  borderBottomWidth: "2px",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "30%" }}>
                  <Typography
                    sx={{
                      wordWrap: "break-word",
                      wordBreak: "break-all",
                      height: "50px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    API key:
                  </Typography>
                </Box>
                <Box sx={{ width: "70%" }}>
                  <Typography
                    sx={{
                      wordWrap: "break-word",
                      height: "50px",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      overflow: "clip",
                      padding: "0px 0px 0px 10px",
                    }}
                  >
                    {console.log(profileData)}
                    {profileData.apiKey}
                  </Typography>
                </Box>
              </Box>
              <Divider
                sx={{
                  bgcolor: theme.palette.primary.main,
                  borderBottomWidth: "2px",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "30%" }}>
                  <Typography
                    sx={{
                      wordWrap: "break-word",
                      wordBreak: "break-all",
                      height: "50px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Data center identificator:
                  </Typography>
                </Box>
                <Box sx={{ width: "70%" }}>
                  <Typography
                    sx={{
                      wordWrap: "break-word",
                      height: "50px",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      overflow: "clip",
                      padding: "0px 0px 0px 10px",
                    }}
                  >
                    {profileData.dataCenterIdentificator}
                  </Typography>
                </Box>
              </Box>
            </>
          ) : null}
        </>
      )}
      {userTypeData.userType === "dc_admin" ? (
        <>
          <Divider
            sx={{
              bgcolor: theme.palette.primary.main,
              borderBottomWidth: "2px",
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "30%" }}>
              <Typography
                sx={{
                  wordWrap: "break-word",
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                VAT number:
              </Typography>
            </Box>
            <Box sx={{ width: "70%" }}>
              {edit ? (
                <Controller
                  name="vat_number"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      variant="standard"
                      error={errors.vat_number ? true : false}
                      title={errors.vat_number?.message}
                      required
                      placeholder="PL0123456789"
                      sx={{
                        height: "50px",
                        width: "100%",
                        padding: "0px 0px 0px 10px",
                        "& .MuiInputBase-input.MuiInput-input": {
                          WebkitTextFillColor: "gray",
                          borderBottomColor: errors.vat_number
                            ? "red"
                            : "primary",
                          borderBottomWidth: "2px",
                          borderBottomStyle: "solid",
                          fontStyle: "italic",
                        },
                      }}
                      disableUnderline={true}
                    />
                  )}
                />
              ) : (
                <Typography
                  sx={{
                    wordWrap: "break-word",
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    padding: "0px 0px 0px 10px",
                  }}
                >
                  {profileData.VAT_number}
                </Typography>
              )}
            </Box>
          </Box>
          <Divider
            sx={{
              bgcolor: theme.palette.primary.main,
              borderBottomWidth: "2px",
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "30%" }}>
              <Typography
                sx={{
                  wordWrap: "break-word",
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Phone:
              </Typography>
            </Box>
            <Box sx={{ width: "70%" }}>
              {edit ? (
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      variant="standard"
                      error={errors.phone ? true : false}
                      title={errors.phone?.message}
                      required
                      sx={{
                        height: "50px",
                        width: "100%",
                        padding: "0px 0px 0px 10px",
                        "& .MuiInputBase-input.MuiInput-input": {
                          WebkitTextFillColor: "gray",
                          borderBottomColor: errors.phone ? "red" : "primary",
                          borderBottomWidth: "2px",
                          borderBottomStyle: "solid",
                          fontStyle: "italic",
                        },
                      }}
                      disableUnderline={true}
                    />
                  )}
                />
              ) : (
                <Typography
                  sx={{
                    wordWrap: "break-word",
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    padding: "0px 0px 0px 10px",
                  }}
                >
                  {profileData.phone}
                </Typography>
              )}
            </Box>
          </Box>
          <Divider
            sx={{
              bgcolor: theme.palette.primary.main,
              borderBottomWidth: "2px",
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "30%" }}>
              <Typography
                sx={{
                  wordWrap: "break-word",
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Address:
              </Typography>
            </Box>
            <Box sx={{ width: "70%" }}>
              {edit ? (
                <Controller
                  name="address"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      variant="standard"
                      error={errors.address ? true : false}
                      title={errors.address?.message}
                      required
                      sx={{
                        height: "50px",
                        width: "100%",
                        padding: "0px 0px 0px 10px",
                        "& .MuiInputBase-input.MuiInput-input": {
                          WebkitTextFillColor: "gray",
                          borderBottomColor: errors.address ? "red" : "primary",
                          borderBottomWidth: "2px",
                          borderBottomStyle: "solid",
                          fontStyle: "italic",
                        },
                      }}
                      disableUnderline={true}
                    />
                  )}
                />
              ) : (
                <Typography
                  sx={{
                    wordWrap: "break-word",
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    padding: "0px 0px 0px 10px",
                  }}
                >
                  {profileData.address}
                </Typography>
              )}
            </Box>
          </Box>
          <Divider
            sx={{
              bgcolor: theme.palette.primary.main,
              borderBottomWidth: "2px",
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "30%" }}>
              <Typography
                sx={{
                  wordWrap: "break-word",
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Data center email:
              </Typography>
            </Box>
            <Box sx={{ width: "70%" }}>
              {edit ? (
                <Controller
                  name="emailTech"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      variant="standard"
                      autoComplete="email"
                      error={errors.emailTech ? true : false}
                      title={errors.emailTech?.message}
                      required
                      sx={{
                        height: "50px",
                        width: "100%",
                        padding: "0px 0px 0px 10px",
                        "& .MuiInputBase-input.MuiInput-input": {
                          WebkitTextFillColor: "gray",
                          borderBottomColor: errors.emailTech
                            ? "red"
                            : "primary",
                          borderBottomWidth: "2px",
                          borderBottomStyle: "solid",
                          fontStyle: "italic",
                        },
                      }}
                      disableUnderline={true}
                    />
                  )}
                />
              ) : (
                <Typography
                  sx={{
                    wordWrap: "break-word",
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    padding: "0px 0px 0px 10px",
                  }}
                >
                  {profileData.email_tech}
                </Typography>
              )}
            </Box>
          </Box>
          <Divider
            sx={{
              bgcolor: theme.palette.primary.main,
              borderBottomWidth: "2px",
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "30%" }}>
              <Typography
                sx={{
                  wordWrap: "break-word",
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Email for payment:
              </Typography>
            </Box>
            <Box sx={{ width: "70%" }}>
              {edit ? (
                <Controller
                  name="emailPay"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      variant="standard"
                      autoComplete="email"
                      error={errors.emailPay ? true : false}
                      title={errors.emailPay?.message}
                      required
                      sx={{
                        height: "50px",
                        width: "100%",
                        padding: "0px 0px 0px 10px",
                        "& .MuiInputBase-input.MuiInput-input": {
                          WebkitTextFillColor: "gray",
                          borderBottomColor: errors.emailPay
                            ? "red"
                            : "primary",
                          borderBottomWidth: "2px",
                          borderBottomStyle: "solid",
                          fontStyle: "italic",
                        },
                      }}
                      disableUnderline={true}
                    />
                  )}
                />
              ) : (
                <Typography
                  sx={{
                    wordWrap: "break-word",
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    padding: "0px 0px 0px 10px",
                  }}
                >
                  {profileData.email_pay}
                </Typography>
              )}
            </Box>
          </Box>
          <Divider
            sx={{
              bgcolor: theme.palette.primary.main,
              borderBottomWidth: "2px",
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "30%" }}>
              <Typography
                sx={{
                  wordWrap: "break-word",
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Identificator:
              </Typography>
            </Box>
            <Box sx={{ width: "70%" }}>
              {edit ? (
                <Controller
                  name="identificator"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      variant="standard"
                      error={errors.identificator ? true : false}
                      title={errors.identificator?.message}
                      required
                      sx={{
                        height: "50px",
                        width: "100%",
                        padding: "0px 0px 0px 10px",
                        "& .MuiInputBase-input.MuiInput-input": {
                          WebkitTextFillColor: "gray",
                          borderBottomColor: errors.identificator
                            ? "red"
                            : "primary",
                          borderBottomWidth: "2px",
                          borderBottomStyle: "solid",
                          fontStyle: "italic",
                        },
                      }}
                      disableUnderline={true}
                    />
                  )}
                />
              ) : (
                <Typography
                  sx={{
                    wordWrap: "break-word",
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    padding: "0px 0px 0px 10px",
                  }}
                >
                  {profileData.identificator}
                </Typography>
              )}
            </Box>
          </Box>
        </>
      ) : null}
    </>
  );
};

export default GeneralInformations;
