import {
  Button,
  Divider,
  Grid,
  IconButton,
  Input,
  List,
  ListItem,
  Box,
  useTheme,
  Tooltip,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { OPENTERMINAL } from "../../navigation/CONSTANTS";
import SingleTerminalComponent from "./singleTerminal";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/userSlice";
import HistoryToggleOffOutlinedIcon from "@mui/icons-material/HistoryToggleOffOutlined";
import { useApi } from "../../api/ContextApi";

const MiniPCTerminalList = (props) => {
  const userData = useSelector(selectUser);
  let theme = useTheme();
  let navigate = useNavigate();
  const [visible, setVisible] = useState(props.defaultOpen);
  const [disabled, setDisabled] = useState(true);
  const [name, setName] = useState(props.value.name);
  let versionArray = props.value.software_version.split(".");
  let latestVersionArray = props.latestVersion.split(".");
  let [numberOfPortsWithHistoryEnabled, setNumberOfPortsWithHistoryEnabled] =
    useState(0);
  const [count, setCount] = useState(0);
  const [serialPorts, setSerialPorts] = useState(props.value.serial_ports);
  const isEnable = useRef(null);
  const api = useApi();

  useEffect(() => {
    let temp = 0;
    serialPorts.forEach((item) => {
      if (item.enableHistory) {
        temp++;
      }
    });
    setNumberOfPortsWithHistoryEnabled(temp);
  }, [serialPorts]);

  const handleConnectButton = (
    roomId,
    serialPortId,
    name,
    baudRate,
    dataBits,
    parity,
    stopBits,
    rtscts,
    xon,
    xoff,
    xany,
    miniPCId
  ) => {
    navigate(OPENTERMINAL + "?roomId=" + roomId, {
      state: {
        serialPortId: serialPortId,
        roomId: roomId,
        name: name,
        baudRate: baudRate,
        dataBits: dataBits,
        parity: parity,
        stopBits: stopBits,
        rtscts: rtscts,
        xon: xon,
        xoff: xoff,
        xany: xany,
        miniPCId: miniPCId,
      },
    });
  };

  const handleEnableMiniPCHistory = async () => {
    if (disabled) {
      return null;
    }
    console.log(1);
    if (serialPorts.length !== 0) {
      await api
        .enableHistoryByMiniPC(
          props.value.id,
          numberOfPortsWithHistoryEnabled === serialPorts.length ? false : true
        )
        .then((res) => {
          if (res.status === 200) {
            const temp = serialPorts.map((serialPort) => {
              serialPort.enableHistory = res.data.isEnabled;
              return serialPort;
            });
            isEnable.current = res.data.isEnabled;
            setCount((prev) => prev + 1);
            setSerialPorts(temp);
          }
        });
    }
    console.log(isEnable);
  };

  const handleEditName = (event) => {
    setName(event.target.value);
  };

  const handleUpdateMiniPCName = async (id, name) => {
    await api.updateMiniPCName(id, name);
  };

  return (
    <>
      <Divider
        sx={{
          bgcolor: theme.palette.primary.main,
          borderBottomWidth: "2px",
        }}
      />
      <ListItem sx={{ px: "0px" }}>
        <Box width={"100%"}>
          <Box width={"100%"} mb={visible ? "8px" : "0px"}>
            <Grid container>
              <Grid
                item
                xs={4}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Input
                  type="text"
                  id={`terminal-name-input-${props.value.id}`}
                  disabled={disabled}
                  disableUnderline={true}
                  required={true}
                  value={name}
                  onChange={handleEditName}
                  sx={{
                    p: "0px",
                    "& .MuiInputBase-input.MuiInput-input.Mui-disabled": {
                      WebkitTextFillColor: `${theme.palette.text.main} !important`,
                      fontStyle: "normal",
                    },
                    "& .MuiInputBase-input.MuiInput-input": {
                      WebkitTextFillColor: "gray",
                      fontStyle: "italic",
                    },
                  }}
                  fullWidth={true}
                />
                {(userData.userType === "global_admin" ||
                  userData.userType === "dc_admin") &&
                props.consoleHistoryPermission ? (
                  <Box sx={{ display: "flex" }}>
                    <Tooltip
                      title={
                        disabled
                          ? "Press Edit button to change console history"
                          : numberOfPortsWithHistoryEnabled ===
                            serialPorts.length
                          ? "Disable console history"
                          : "Enable console history"
                      }
                    >
                      <IconButton
                        aria-disabled={disabled}
                        onClick={handleEnableMiniPCHistory}
                        sx={{
                          borderColor:
                            serialPorts.length === 0 || count < 0
                              ? "#808080"
                              : numberOfPortsWithHistoryEnabled ===
                                serialPorts.length
                              ? "green"
                              : numberOfPortsWithHistoryEnabled === 0
                              ? "red"
                              : "orange",
                          width: "30px",
                          height: "30px",
                          borderWidth: "2px",
                          borderStyle: "solid",
                          marginRight: "15px",
                          boxShadow: disabled
                            ? undefined
                            : serialPorts.length === 0
                            ? "0px 0px 7px #808080"
                            : numberOfPortsWithHistoryEnabled ===
                              serialPorts.length
                            ? "0px 0px 7px green"
                            : numberOfPortsWithHistoryEnabled === 0
                            ? "0px 0px 7px red"
                            : "0px 0px 7px orange",
                        }}
                      >
                        <HistoryToggleOffOutlinedIcon
                          sx={{
                            color:
                              serialPorts.length === 0
                                ? "#808080"
                                : numberOfPortsWithHistoryEnabled ===
                                  serialPorts.length
                                ? "green"
                                : numberOfPortsWithHistoryEnabled === 0
                                ? "red"
                                : "orange",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Box>
                ) : null}
              </Grid>
              <Grid
                item
                xs={2}
                sx={{ display: "flex", justifyContent: "flex-start" }}
              >
                {props.value.status === "online" ? (
                  <FiberManualRecordIcon sx={{ color: "green" }} />
                ) : props.value.status === "suspended" ? (
                  <FiberManualRecordIcon sx={{ color: "#808080" }} />
                ) : (
                  <FiberManualRecordIcon sx={{ color: "red" }} />
                )}
                {props.value.status}
              </Grid>
              <Grid
                item
                xs={2}
                sx={{ display: "flex", justifyContent: "flex-start" }}
              >
                {props.value.software_version === props.latestVersion ? (
                  <Tooltip title="Latest version">
                    <FiberManualRecordIcon sx={{ color: "green" }} />
                  </Tooltip>
                ) : versionArray[0] >= latestVersionArray[0] ? (
                  <Tooltip title="Old version">
                    <FiberManualRecordIcon sx={{ color: "orange" }} />
                  </Tooltip>
                ) : (
                  <Tooltip title="Unsupported version">
                    <FiberManualRecordIcon sx={{ color: "red" }} />
                  </Tooltip>
                )}
                {props.value.software_version}
              </Grid>
              <Grid item xs={2}>
                {userData.userType === "user" ||
                userData.userType === "free_user"
                  ? null
                  : serialPorts.filter((item) => item.verified).length + "/"}
                {serialPorts.length}
              </Grid>
              <Grid
                item
                xs={2}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  variant="outlined"
                  sx={{ borderRadius: "50px" }}
                  onClick={() => {
                    setDisabled(!disabled);
                    if (disabled) {
                      window.setTimeout(function () {
                        document
                          .getElementById(
                            `terminal-name-input-${props.value.id}`
                          )
                          .focus();
                      }, 0);
                    } else {
                      handleUpdateMiniPCName(props.value.id, name);
                    }
                  }}
                >
                  {disabled ? "Edit" : "Save"}
                </Button>
                <IconButton
                  id={"expandButton" + props.value.id}
                  onClick={() => {
                    setVisible(!visible);
                  }}
                >
                  {visible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Grid>
            </Grid>
          </Box>
          {visible ? (
            <List sx={{ pt: "0px", pl: "20px" }}>
              {serialPorts.map((value2, index) => {
                return (
                  <SingleTerminalComponent
                    disabled={userData.isSuspended}
                    key={value2.id}
                    data={value2}
                    miniPCId={props.value.id}
                    index={index}
                    status={props.value.status}
                    onConnect={handleConnectButton}
                    isEnable={isEnable.current}
                    count={count}
                    enable={() => {
                      setNumberOfPortsWithHistoryEnabled((prev) => prev + 1);
                    }}
                    disable={() => {
                      setNumberOfPortsWithHistoryEnabled((prev) => prev - 1);
                    }}
                    consoleHistoryPermission={props.consoleHistoryPermission}
                  />
                );
              })}
            </List>
          ) : null}
        </Box>
      </ListItem>
    </>
  );
};

export default MiniPCTerminalList;
