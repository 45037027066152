import {
  Box,
  Divider,
  Grid,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

const Row3x3x3x3 = (props) => {
  const theme = useTheme();

  const getCosts = () => {
    let cost = 0;
    switch (props.currency) {
      case "EUR":
        cost = props.cost * (props.inUse - props.free);
        return "\u20AC" + (cost > 0 ? cost : 0);
      case "PLN":
        cost = props.cost * (props.inUse - props.free);
        return (cost > 0 ? cost : 0) + "zł";
      case "USD":
        cost = props.cost * (props.inUse - props.free);
        return "$" + (cost > 0 ? cost : 0);
      default:
        return "";
    }
  };

  const getTooltip = () => {
    let cost = "";
    let cost1 = "";
    let cost2 = "";
    switch (props.currency) {
      case "EUR":
        cost =
          props.free > 0
            ? `${props.cost} * (in usage ${props.inUse} - ${props.free} free)`
            : `${props.cost} * ${props.inUse} in use`;
        return "\u20AC" + cost;
      case "PLN":
        cost1 = `${props.cost}`;
        cost2 =
          props.free > 0
            ? ` * (in usage ${props.inUse} - ${props.free} free)`
            : ` * ${props.inUse} in use`;
        return cost1 + "zł" + cost2;
      case "USD":
        cost =
          props.free > 0
            ? `${props.cost} * (in usage ${props.inUse} - ${props.free} free)`
            : `${props.cost} * ${props.inUse} in use`;
        return "$" + cost;
      default:
        return "";
    }
  };

  return (
    <>
      <Box>
        <Grid container columnSpacing={2}>
          <Grid item xs={3}>
            {props.name}
          </Grid>
          <Grid item xs={3}>
            {props.inUse}
          </Grid>
          <Grid item xs={3}>
            {props.limit}
          </Grid>
          <Grid item>
            {props.cost ? (
              <Tooltip title={getTooltip()}>
                <Typography>{props.cost ? getCosts() : ""}</Typography>
              </Tooltip>
            ) : null}
          </Grid>
        </Grid>
        {props.last ? null : (
          <Divider
            sx={{
              borderBottomWidth: "1px",
              width: "100%",
              bgcolor: theme.palette.primary.main,
              width: props.currency ? "100%" : "50%",
            }}
          />
        )}
      </Box>
    </>
  );
};

export default Row3x3x3x3;
