import { ThemeProvider } from "@mui/material";
import { useState } from "react";
import "./App.css";
import { ProvideAuth } from "./navigation/authorization/ProvideAuth";
import { BrowserRouter } from "react-router-dom";
import { RouterConfig } from "./navigation/RouterConfig";
import { light, dark } from "./styles/muiThemes";
import { ApiProvider } from "./api/ContextApi";

function App() {
  const [darkState, setDarkState] = useState(false);
  // const handleThemeChange = () => {
  //   setDarkState(!darkState);
  //   console.log("theme=", darkState ? "dark" : "light");
  // };

  return (
    <>
      <div>
        <ThemeProvider theme={darkState ? dark() : light()}>
          {/* <ThemeSwitch
            darkState={darkState}
            handleThemeChange={handleThemeChange}
          /> */}
          <ProvideAuth>
            <ApiProvider>
              <BrowserRouter>
                <RouterConfig />
              </BrowserRouter>
            </ApiProvider>
          </ProvideAuth>
        </ThemeProvider>
      </div>
    </>
  );
}

export default App;
