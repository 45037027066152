import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Modal,
  Button,
  IconButton,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import GroupsIcon from "@mui/icons-material/Groups";
import TerminalIcon from "@mui/icons-material/Terminal";
import ClearIcon from "@mui/icons-material/Clear";
import SaveIcon from "@mui/icons-material/Save";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import OrganizationList from "./organizationsList";
import CurrentLimits from "./currentLimits";
import { useApi } from "../../../api/ContextApi";

const SingleDataCenter = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(
    props.data.edit
      ? {
          expanded: true,
          edit: true,
          miniPc: false,
          organizations: false,
          limits: false,
        }
      : {
          expanded: false,
          edit: false,
          miniPc: false,
          organizations: false,
          limits: false,
        }
  );
  const [admins, setAdmins] = useState([]);
  let latestVersionArray = props.latestVersion.split(".");
  const [modal, setModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const api = useApi();

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .min(5, "Name is too short")
      .max(50, "Name is too long"),
    adminName: props.data.draft
      ? Yup.string()
      : Yup.string().required("Admin name is required"),
    vat_number: Yup.string()
      .max(20, "VAT number is too long")
      .required("VAT number is required"),
    phone: Yup.string()
      .min(7, "Phone number is not valid")
      .max(20, "Phone number is not valid")
      .required("Phone number is required"),
    address: Yup.string().required("Address is required"),
    emailTech: Yup.string()
      .required("Email is required")
      .email("Please enter a valid email address"),
    emailPay: Yup.string()
      .required("Email is required")
      .email("Please enter a valid email address"),
    identificator: Yup.string(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    reset,
    setError,
  } = useForm({
    defaultValues: {
      name: props.data.name,
      adminName: props.data.dc_admin_id ? props.data.dc_admin_id : "",
      vat_number: props.data.VAT_number ? props.data.VAT_number : "",
      phone: props.data.phone ? props.data.phone : "",
      address: props.data.address ? props.data.address : "",
      emailTech: props.data.email_tech ? props.data.email_tech : "",
      emailPay: props.data.email_pay ? props.data.email_pay : "",
      identificator: props.data.identificator ? props.data.identificator : "",
      is_suspended:
        props.data.is_suspended !== undefined ? props.data.is_suspended : false,
    },
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const handleSaveButton = (data) => {
    if (props.data.draft) {
      api
        .createDataCenter({
          name: data.name,
          VAT_number: data.vat_number,
          phone: data.phone,
          address: data.address,
          email_tech: data.emailTech,
          email_pay: data.emailPay,
          identificator: data.identificator,
        })
        .then((res) => {
          if (res.status === 200) window.location.reload(false);
        });
    } else {
      api
        .updateDataCenter({
          id: props.data.id,
          name: data.name,
          dc_admin_id: data.adminName,
          VAT_number: data.vat_number,
          phone: data.phone,
          address: data.address,
          email_tech: data.emailTech,
          email_pay: data.emailPay,
          identificator: data.identificator,
          is_suspended: data.is_suspended,
        })
        .then((res) => {
          if (res.status === 200) window.location.reload(false);
        });
    }
  };

  const handleDeleteButton = () => {
    if (props.data.draft) {
      props.setDataCenterData(
        props.dataCenterData.filter((item) => item.id !== props.data.id)
      );
    } else {
      setIsDeleting(true);
      api.deleteDataCenter(props.data.id).then((res) => {
        if (res.status === 200) window.location.reload(false);
      });
    }
  };

  return (
    <Box key={props.data.id}>
      <Divider
        sx={{
          bgcolor: theme.palette.primary.main,
          borderBottomWidth: "2px",
          mx: "16px",
        }}
      />
      <ListItem key={props.data.id} sx={{ py: "0px" }}>
        <Accordion
          sx={{
            width: "100%",
            boxShadow: "none",
            bgcolor:
              props.index % 2 === 1
                ? theme.palette.fadedColors.primary
                : theme.palette.background.main,
          }}
          disableGutters
          expanded={expanded.expanded}
        >
          <Grid
            container
            spacing={2}
            sx={{ alignItems: expanded.edit ? "flex-start" : "center" }}
          >
            <Grid item xs={4}>
              {expanded.edit ? (
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="standard"
                      label="Name"
                      fullWidth
                      error={errors.name ? true : false}
                      helperText={errors.name?.message}
                    />
                  )}
                />
              ) : (
                <Typography sx={{ wordWrap: "break-word" }}>
                  {props.data.name}
                </Typography>
              )}
            </Grid>
            <Grid item xs={4}>
              {!props.data.draft && expanded.edit ? (
                <>
                  <InputLabel sx={{ fontSize: "13px" }}>Admin name</InputLabel>
                  <Controller
                    name="adminName"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Admin name"
                        variant="standard"
                        fullWidth
                        error={errors.adminName ? true : false}
                        size="small"
                      >
                        {props.data.accounts.map((admin) => {
                          return (
                            <MenuItem key={admin.id} value={admin.id}>
                              {admin.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                  />
                </>
              ) : (
                <Typography sx={{ wordWrap: "break-word" }}>
                  {props.data.accounts.length > 0 && props.data.dc_admin_id
                    ? props.data.accounts.find(
                        (admin) => admin.id === props.data.dc_admin_id
                      )
                      ? props.data.accounts.find(
                          (admin) => admin.id === props.data.dc_admin_id
                        ).name
                      : ""
                    : ""}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              xs={4}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <AccordionSummary aria-expanded={expanded.expanded} sx={{ p: 0 }}>
                {props.data.draft ? null : (
                  <Tooltip title="Limits">
                    <IconButton
                      disabled={expanded.edit}
                      sx={{
                        bgcolor: theme.palette.primary.main,
                        color: theme.palette.background.main,
                        ":hover": {
                          bgcolor: theme.palette.secondary.main,
                        },
                        height: "2.5rem",
                        width: "2.5rem",
                        borderColor: theme.palette.primary.main,
                        borderWidth: "1px",
                        borderStyle: "solid",
                        ml: "10px",
                        my: "auto",
                      }}
                      onClick={() => {
                        if (expanded.limits) {
                          if (
                            expanded.edit ||
                            expanded.organizations ||
                            expanded.miniPc
                          )
                            setExpanded({ ...expanded, limits: false });
                          else
                            setExpanded({
                              ...expanded,
                              expanded: false,
                              limits: false,
                            });
                        } else {
                          if (expanded.expanded)
                            setExpanded({
                              ...expanded,
                              limits: true,
                            });
                          else
                            setExpanded({
                              ...expanded,
                              expanded: true,
                              limits: true,
                            });
                        }
                      }}
                    >
                      <AttachMoneyIcon
                        sx={{ height: "1.8rem", width: "1.8rem" }}
                      />
                    </IconButton>
                  </Tooltip>
                )}
                {props.data.draft ? null : (
                  <Tooltip title="Mini PCs">
                    <IconButton
                      disabled={expanded.edit}
                      sx={{
                        bgcolor: theme.palette.primary.main,
                        color: theme.palette.background.main,
                        ":hover": {
                          bgcolor: theme.palette.secondary.main,
                        },
                        height: "2.5rem",
                        width: "2.5rem",
                        borderColor: theme.palette.primary.main,
                        borderWidth: "1px",
                        borderStyle: "solid",
                        ml: "10px",
                        my: "auto",
                      }}
                      onClick={() => {
                        if (expanded.miniPc) {
                          if (
                            expanded.edit ||
                            expanded.organizations ||
                            expanded.limits
                          )
                            setExpanded({ ...expanded, miniPc: false });
                          else
                            setExpanded({
                              ...expanded,
                              expanded: false,
                              miniPc: false,
                            });
                        } else {
                          if (expanded.expanded)
                            setExpanded({
                              ...expanded,
                              miniPc: true,
                            });
                          else
                            setExpanded({
                              ...expanded,
                              expanded: true,
                              miniPc: true,
                            });
                        }
                      }}
                    >
                      <TerminalIcon
                        sx={{ height: "1.8rem", width: "1.8rem" }}
                      />
                    </IconButton>
                  </Tooltip>
                )}
                {props.data.draft ? null : (
                  <Tooltip title="Organizations">
                    <IconButton
                      disabled={expanded.edit}
                      sx={{
                        bgcolor: theme.palette.primary.main,
                        color: theme.palette.background.main,
                        ":hover": {
                          bgcolor: theme.palette.secondary.main,
                        },
                        height: "2.5rem",
                        width: "2.5rem",
                        borderColor: theme.palette.primary.main,
                        borderWidth: "1px",
                        borderStyle: "solid",
                        ml: "10px",
                        my: "auto",
                      }}
                      onClick={() => {
                        if (expanded.organizations) {
                          if (
                            expanded.edit ||
                            expanded.miniPc ||
                            expanded.limits
                          )
                            setExpanded({ ...expanded, organizations: false });
                          else
                            setExpanded({
                              ...expanded,
                              expanded: false,
                              organizations: false,
                            });
                        } else {
                          if (expanded.expanded)
                            setExpanded({
                              ...expanded,
                              organizations: true,
                            });
                          else
                            setExpanded({
                              ...expanded,
                              expanded: true,
                              organizations: true,
                            });
                        }
                      }}
                    >
                      <GroupsIcon sx={{ height: "1.8rem", width: "1.8rem" }} />
                    </IconButton>
                  </Tooltip>
                )}
                {props.data.draft ? null : (
                  <Tooltip title="Edit">
                    <IconButton
                      disabled={expanded.edit}
                      sx={{
                        bgcolor: theme.palette.primary.main,
                        color: theme.palette.background.main,
                        ":hover": {
                          bgcolor: theme.palette.secondary.main,
                        },
                        height: "2.5rem",
                        width: "2.5rem",
                        borderColor: theme.palette.primary.main,
                        borderWidth: "1px",
                        borderStyle: "solid",
                        ml: "10px",
                      }}
                      onClick={() => {
                        if (expanded.edit) {
                          if (
                            expanded.organizations ||
                            expanded.miniPc ||
                            expanded.limits
                          )
                            setExpanded({ ...expanded, edit: false });
                          else
                            setExpanded({
                              ...expanded,
                              expanded: false,
                              edit: false,
                            });
                        } else {
                          if (expanded.expanded)
                            setExpanded({
                              ...expanded,
                              edit: true,
                            });
                          else
                            setExpanded({
                              ...expanded,
                              expanded: true,
                              edit: true,
                            });
                        }
                      }}
                    >
                      <EditIcon sx={{ height: "1.8rem", width: "1.8rem" }} />
                    </IconButton>
                  </Tooltip>
                )}
              </AccordionSummary>
              <Tooltip title="Delete">
                <IconButton
                  sx={{
                    bgcolor: theme.palette.primary.main,
                    color: theme.palette.background.main,
                    ":hover": {
                      bgcolor: theme.palette.secondary.main,
                    },
                    height: "2.5rem",
                    width: "2.5rem",
                    borderColor: theme.palette.primary.main,
                    borderWidth: "1px",
                    borderStyle: "solid",
                    ml: "10px",
                    my: "12px",
                  }}
                  onClick={() => setModal(true)}
                >
                  <DeleteIcon sx={{ height: "1.8rem", width: "1.8rem" }} />
                </IconButton>
              </Tooltip>
              <Modal
                open={modal}
                onClose={() => setModal(false)}
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "white",
                    padding: "20px",
                    borderRadius: "20px",
                    marginBottom: "15px",
                    maxWidth: "600px",
                  }}
                >
                  <Typography
                    id="modal-modal-title"
                    sx={{ textAlign: "center" }}
                  >
                    Delete data center
                  </Typography>
                  <Typography
                    className="modal-modal-description"
                    sx={{ marginBottom: "15px" }}
                  >
                    Are you sure you want to delete the{" "}
                    <span style={{ color: theme.palette.primary.main }}>
                      {props.data.name}
                    </span>{" "}
                    data center?
                  </Typography>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      disabled={isDeleting}
                      variant="outlined"
                      onClick={() => handleDeleteButton()}
                      sx={{ borderRadius: "10px", borderWidth: "2px" }}
                    >
                      Delete
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => setModal(false)}
                      sx={{ borderRadius: "10px", borderWidth: "2px" }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </Modal>
            </Grid>
          </Grid>
          <AccordionDetails
            sx={{
              padding: "0px !important",
            }}
          >
            {expanded.edit ? (
              <Box mb="12px">
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Controller
                      name="vat_number"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          variant="standard"
                          label="VAT number"
                          fullWidth
                          error={errors.vat_number ? true : false}
                          helperText={errors.vat_number?.message}
                          placeholder="PL0123456789"
                          sx={{ mb: "16px" }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Controller
                      name="phone"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          variant="standard"
                          label="Phone number"
                          fullWidth
                          error={errors.phone ? true : false}
                          helperText={errors.phone?.message}
                          sx={{ mb: "16px" }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}></Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Controller
                      name="emailTech"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          variant="standard"
                          label="Email"
                          fullWidth
                          error={errors.emailTech ? true : false}
                          helperText={errors.emailTech?.message}
                          sx={{ mb: "16px" }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Controller
                      name="emailPay"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          variant="standard"
                          label="Email for payment"
                          fullWidth
                          error={errors.emailPay ? true : false}
                          helperText={errors.emailPay?.message}
                          sx={{ mb: "16px" }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}></Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Controller
                      name="address"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          variant="standard"
                          label="Adres"
                          fullWidth
                          error={errors.address ? true : false}
                          helperText={errors.address?.message}
                          sx={{ mb: "16px" }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    {props.data.draft ? null : (
                      <Controller
                        name="identificator"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            variant="standard"
                            label="Identificator for miniPC"
                            fullWidth
                            error={errors.identificator ? true : false}
                            helperText={errors.identificator?.message}
                            sx={{ mb: "16px" }}
                            InputProps={{
                              readOnly: true,
                              disableUnderline: true,
                            }}
                          />
                        )}
                      />
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    {props.data.draft ? null : (
                      <FormControlLabel
                        control={
                          <Controller
                            name="is_suspended"
                            control={control}
                            render={({ field }) => (
                              <Checkbox checked={field.value} {...field} />
                            )}
                          />
                        }
                        label="Data center suspension"
                      />
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    {props.data.draft ? null : (
                      <Tooltip title="Cancel">
                        <IconButton
                          sx={{
                            bgcolor: theme.palette.primary.main,
                            color: theme.palette.background.main,
                            ":hover": {
                              bgcolor: theme.palette.secondary.main,
                            },
                            height: "2.5rem",
                            width: "2.5rem",
                            borderColor: theme.palette.primary.main,
                            borderWidth: "1px",
                            borderStyle: "solid",
                            ml: "10px",
                            my: "auto",
                          }}
                          onClick={() => {
                            if (expanded.edit) {
                              if (
                                expanded.organizations ||
                                expanded.miniPc ||
                                expanded.limits
                              )
                                setExpanded({ ...expanded, edit: false });
                              else
                                setExpanded({
                                  ...expanded,
                                  expanded: false,
                                  edit: false,
                                });
                            } else {
                              if (expanded.expanded)
                                setExpanded({
                                  ...expanded,
                                  edit: true,
                                });
                              else
                                setExpanded({
                                  ...expanded,
                                  expanded: true,
                                  edit: true,
                                });
                            }
                            reset();
                          }}
                        >
                          <ClearIcon
                            sx={{ height: "1.8rem", width: "1.8rem" }}
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Save">
                      <IconButton
                        sx={{
                          bgcolor: theme.palette.primary.main,
                          color: theme.palette.background.main,
                          ":hover": {
                            bgcolor: theme.palette.secondary.main,
                          },
                          height: "2.5rem",
                          width: "2.5rem",
                          borderColor: theme.palette.primary.main,
                          borderWidth: "1px",
                          borderStyle: "solid",
                          ml: "10px",
                          my: "auto",
                        }}
                        onClick={handleSubmit(handleSaveButton)}
                      >
                        <SaveIcon sx={{ height: "1.8rem", width: "1.8rem" }} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Box>
            ) : null}
            {expanded.limits ? (
              <Box component="div" p="8px 16px 16px 16px">
                <CurrentLimits
                  data={props.data.usage}
                  freePricingPlan={props.freePricingPlan}
                />
              </Box>
            ) : null}
            {expanded.miniPc ? (
              <Box component="div" p="8px 16px 16px 16px">
                <Typography variant="h6">Mini-PCs</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    Mini-PC name
                  </Grid>
                  <Grid item xs={3}>
                    Status
                  </Grid>
                  <Grid item xs={3}>
                    Version
                  </Grid>
                  <Grid item xs={3}>
                    Ports
                  </Grid>
                </Grid>
                <List>
                  {props.data.mini_pcs.map((miniPC, index) => {
                    let versionArray = miniPC.software_version.split(".");
                    return (
                      <Box component="div" key={miniPC.id}>
                        <Divider
                          sx={{
                            borderBottomWidth: index === 0 ? "2px" : "1px",
                            width: "100%",
                            bgcolor: theme.palette.primary.main,
                          }}
                        />
                        <Grid container spacing={2}>
                          <Grid item xs={3}>
                            {miniPC.name}
                          </Grid>
                          <Grid item xs={3} display="flex" alignItems="center">
                            {miniPC.status === "online" ? (
                              <FiberManualRecordIcon sx={{ color: "green" }} />
                            ) : (
                              <FiberManualRecordIcon sx={{ color: "red" }} />
                            )}
                            {miniPC.status}
                          </Grid>
                          <Grid item xs={3} display="flex" alignItems="center">
                            {miniPC.software_version === props.latestVersion ? (
                              <Tooltip title="Latest version">
                                <FiberManualRecordIcon
                                  sx={{ color: "green" }}
                                />
                              </Tooltip>
                            ) : versionArray[0] >= latestVersionArray[0] ? (
                              <Tooltip title="Old version">
                                <FiberManualRecordIcon
                                  sx={{ color: "orange" }}
                                />
                              </Tooltip>
                            ) : (
                              <Tooltip title="Unsupported version">
                                <FiberManualRecordIcon sx={{ color: "red" }} />
                              </Tooltip>
                            )}
                            {miniPC.software_version}
                          </Grid>
                          <Grid item xs={3}>
                            {miniPC.serial_ports.filter((item) => item.verified)
                              .length + "/"}
                            {miniPC.serial_ports.length}
                          </Grid>
                        </Grid>
                      </Box>
                    );
                  })}
                </List>
              </Box>
            ) : null}
            {expanded.organizations ? (
              <Box component="div" p="8px 16px 16px 16px">
                <OrganizationList
                  data={props.data}
                  setDataCenterData={props.setDataCenterData}
                  dataCenterData={props.dataCenterData}
                />
              </Box>
            ) : null}
            {expanded.limits ? (
              <Box component="div" p="8px 16px 16px 16px"></Box>
            ) : null}
          </AccordionDetails>
        </Accordion>
      </ListItem>
    </Box>
  );
};

export default SingleDataCenter;
