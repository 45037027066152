import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { NotFound } from "./NotFound";
import {
  TERMINALLIST,
  OPENTERMINAL,
  PROFILE,
  RESETPASSWORD,
  ROOT,
  STATISTICS,
  USERLISTMANAGEMENT,
  DATACENTERLISTMANAGEMENT,
  ORGANIZATIONLISTMANAGEMENT,
  APIKEYLISTMANAGEMENT,
  PRICINGPLANS,
  CONSOLEHISTORY,
  SERVERSTATUS,
} from "./CONSTANTS";
import Login from "../pages/Auth";
import { useSelector } from "react-redux";
import { selectUser } from "../redux/userSlice";
import Dashboard from "../pages/Dashboard";
import ForgotPassword from "../pages/Auth/forgotPassword";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Box } from "@mui/material";
import TerminalList from "../pages/TerminalList";
import OpenTerminal from "../pages/OpenTerminal";
import Statistics from "../pages/Statistics";
import UserManagement from "../pages/UserManagement";
import Profile from "../pages/Profile";
import NotificationDrawer from "../components/NotificationDrawer";
import config from "../config";
import Payments from "../pages/Payments";
import ConsoleHistory from "../pages/ConsoleHistory";
import { useApi } from "../api/ContextApi";
import Status from "../pages/Status";

export const RouterConfig = () => {
  const userData = useSelector(selectUser);
  const [notifications, setNotifications] = useState([]);
  const [anchorDrawer, setAnchorDrawer] = useState(false);
  const [isNewNotification, setIsNewNotification] = useState(false);
  const [resetWebsocket, setResetWebsocket] = useState(1);
  const api = useApi();

  useEffect(() => {
    if (userData.token) {
      const ws = new WebSocket(config.NOTIFICATION_WEBSOCKET_URL);

      ws.addEventListener("open", (event) => {
        console.log("opened");
        ws.send(userData.token);
      });

      ws.addEventListener("close", (event) => {
        console.log("Notification's websocket is closed. Preparing to restart");
        console.log(event.reason);
        setResetWebsocket((prev) => prev + 1);
      });

      ws.addEventListener("message", (event) => {
        const msg = JSON.parse(event.data);
        if (msg.notifications) {
          setNotifications((prev) => [...msg.notifications, ...prev]);
          const notSeenNotification = msg.notifications.find(
            (notification) => notification.seen_by_user === false
          );
          if (notSeenNotification) setIsNewNotification(true);
        } else if (msg.id) {
          setNotifications((prev) => [msg, ...prev]);
          if (!isNewNotification) setIsNewNotification(true);
        }
      });

      ws.addEventListener("error", (error) => {
        console.log("Error: " + error);
      });

      return () => {
        ws.close();
      };
    } else {
      setNotifications([]);
    }
  }, [userData.token, resetWebsocket]);

  const openNotifications = () => {
    if (isNewNotification && !anchorDrawer) {
      console.log("Sending api request!");
      api.viewNotifications();
    } else if (isNewNotification && anchorDrawer) {
      console.log("New notification has been viewed!");
      setIsNewNotification(false);
      setNotifications(
        notifications.map((notification) => {
          notification.seen_by_user = true;
          return notification;
        })
      );
    }
    setAnchorDrawer(!anchorDrawer);
  };

  if (userData.token) {
    api.setAuthToken(userData.token);
  }

  const isAuth = api.getHeaders();

  if (!userData.token || !isAuth) {
    return (
      <Routes>
        <Route path={ROOT} element={<Login />} />
        <Route path={RESETPASSWORD} element={<ForgotPassword />} />
        <Route path={SERVERSTATUS} element={<Status />} />
        <Route path="*" element={<Navigate to={ROOT} replace />} />
      </Routes>
    );
  }

  if (
    userData.userType === "global_admin" ||
    userData.userType === "dc_admin" ||
    userData.userType === "organization_admin"
  ) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Header
          isNewNotification={isNewNotification}
          openNotifications={openNotifications}
        />
        <NotificationDrawer
          openNotifications={openNotifications}
          anchorDrawer={anchorDrawer}
          notifications={notifications}
        />
        <Routes>
          {/* List all public routes here */}
          <Route path={ROOT} element={<Dashboard />} />
          <Route path={TERMINALLIST} element={<TerminalList />} />
          <Route path={OPENTERMINAL} element={<OpenTerminal />} />
          <Route path={STATISTICS} element={<Statistics />} />
          {userData.userType === "global_admin" ? (
            <Route
              path={DATACENTERLISTMANAGEMENT}
              element={<UserManagement variant="dc" />}
            />
          ) : null}
          {userData.userType === "global_admin" ||
          userData.userType === "dc_admin" ? (
            <Route
              path={ORGANIZATIONLISTMANAGEMENT}
              element={<UserManagement variant="org" />}
            />
          ) : null}
          {userData.userType === "global_admin" ||
          userData.userType === "dc_admin" ? (
            <Route
              path={APIKEYLISTMANAGEMENT}
              element={<UserManagement variant="api-key" />}
            />
          ) : null}
          <Route
            path={USERLISTMANAGEMENT}
            element={<UserManagement variant="users" />}
          />
          <Route path={CONSOLEHISTORY} element={<ConsoleHistory />} />
          <Route path={PROFILE} element={<Profile />} />
          {userData.userType !== "organization_admin" ? (
            <Route path={PRICINGPLANS} element={<Payments />} />
          ) : null}
          <Route path={SERVERSTATUS} element={<Status />} />

          {/* List a generic 404-Not Found route here */}
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Header
          isNewNotification={isNewNotification}
          openNotifications={openNotifications}
        />
        <NotificationDrawer
          openNotifications={openNotifications}
          anchorDrawer={anchorDrawer}
          notifications={notifications}
        />
        <Routes>
          {/* List all public routes here */}
          <Route path={ROOT} element={<TerminalList />} />
          <Route path={OPENTERMINAL} element={<OpenTerminal />} />
          <Route path={PROFILE} element={<Profile />} />
          <Route path={CONSOLEHISTORY} element={<ConsoleHistory />} />
          <Route path={SERVERSTATUS} element={<Status />} />

          {/* List a generic 404-Not Found route here */}
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Box>
    );
  }
};
