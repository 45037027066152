import React, { useEffect, useRef, useState } from "react";
import { Terminal } from "xterm";
import { FitAddon } from "xterm-addon-fit";
import "xterm/css/xterm.css";
import { Box, Container, useTheme } from "@mui/system";
import { Button, Divider, Grid, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { TERMINALLIST, ROOT } from "../../navigation/CONSTANTS";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/userSlice";
import config from "../../config";
import { AttachAddon } from "xterm-addon-attach";
import TftpContainer from "./tftpContainer";
import UploadFile from "./uploadFile";
import DownloadFile from "./downloadFiles/downloadFile";
import { tftpOnMessage } from "../../utils/functions";
import useAsyncFeedback from "../../hooks/useAsyncFeedback";
import { useApi } from "../../api/ContextApi";

const fitAddon = new FitAddon();

const OpenTerminal = () => {
  const userData = useSelector(selectUser);
  const [files, setFiles] = useState([]);
  const [isTFTPEnabled, setIsTFTPEnabled] = useState(false);
  const [container, setContainer] = useState(null);
  const tftpWebSocket = useRef(null);
  const [handleAsyncNetworkData, { loading }] = useAsyncFeedback();
  const [handleAsyncMiniPC, { loading2 }] = useAsyncFeedback();
  const [networkData, setNetworkData] = useState({});
  const [tftpIncluded, setTftpIncluded] = useState(false);
  const [uploadSpinner, setUploadSpinner] = useState(false);
  const api = useApi();

  let options = {
    convertEol: true,
    fontFamily: `'Fira Mono', monospace`,
    cursorBlink: true,
    fontSize: 15,
    fontWeight: 800,
    theme: {
      background: "#00393D",
      cursorAccent: "#00393D",
    },
    rendererType: "dom",
    rightClickSelectsWord: true,
  };

  const term = new Terminal(options);
  const theme = useTheme();
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    console.log(state);
    if (!state) {
      console.log(state);
      navigate(
        userData.userType === "global_admin" ||
          userData.userType === "dc_admin" ||
          userData.userType === "organization_admin"
          ? TERMINALLIST
          : ROOT
      );
    }
    handleAsyncNetworkData(
      () => api.getNetworkINterface(state.miniPCId),
      null,
      (data) => {
        if (!data.message) {
          setTftpIncluded(true);
          setNetworkData(data);
        }
      },
      "loading"
    );
    handleAsyncMiniPC(
      () => api.checkPortUserStatus(state.serialPortId),
      null,
      (data) => {
        console.log(1);
        console.log(data);
        console.log(2);
        if (!data.allow) {
          navigate(
            userData.userType === "global_admin" ||
              userData.userType === "dc_admin" ||
              userData.userType === "organization_admin"
              ? TERMINALLIST
              : ROOT
          );
        }
      },
      "loading2"
    );
  }, []);

  useEffect(() => {
    term.open(document.getElementById("xtermjs"));
    term.loadAddon(fitAddon);
    fitAddon.fit();
    const ws = new WebSocket(
      config.WEBSOCKET_SERVER_URL +
        "/?isUser=true&token=" +
        userData.token +
        "&roomId=" +
        state.roomId +
        "&miniPC=" +
        state.miniPCId
    );
    ws.addEventListener("open", () => {
      term.write("****Loading!****\r\n");
    });
    const attachAddon = new AttachAddon(ws);
    term.loadAddon(attachAddon);
    ws.onclose = () => {
      term.write("\u001b[33;1mThe terminal has been closed.\u001b[0m");
    };
    ws.onerror = () => {
      ws.close();
      term.write("\u001b[33;1mThe terminal has been closed.\u001b[0m");
    };
    console.log(userData.userType);
    if (userData.userType === "organization_admin") {
      tftpWebSocket.current = new WebSocket(
        config.WEBSOCKET_SERVER_URL +
          "/?isUser=true&token=" +
          userData.token +
          "&roomId=" +
          state.roomId +
          "&miniPC=" +
          state.miniPCId +
          "&tftp=true"
      );
      tftpWebSocket.current.onmessage = async (event) => {
        await tftpOnMessage(
          JSON.parse(event.data),
          setIsTFTPEnabled,
          setContainer,
          tftpWebSocket.current,
          userData.id,
          setFiles,
          uploadSpinner,
          setUploadSpinner,
          api
        );
      };
      tftpWebSocket.current.onclose = () => {
        console.log("TFTP websocket was closed!");
        setIsTFTPEnabled(false);
      };
      tftpWebSocket.current.onerror = () => {
        tftpWebSocket.current.close();
        setIsTFTPEnabled(false);
      };
    }
    return () => {
      ws.close();
      try {
        tftpWebSocket.current.close();
      } catch (err) {}
    };
  }, []);

  return (
    <Container
      maxWidth="xl"
      sx={{ p: "40px", pt: "20px", display: loading ? "none" : "block" }}
    >
      <Box
        sx={{
          width: "100%",
          justifyContent: "space-between",
          display: "flex",
          mb: "20px",
        }}
      >
        <Typography variant="h4">Terminal</Typography>
        <Button
          variant="outlined"
          sx={{ borderWidth: "2px", borderRadius: "50px" }}
          onClick={() => {
            navigate(
              userData.userType === "global_admin" ||
                userData.userType === "dc_admin" ||
                userData.userType === "organization_admin"
                ? TERMINALLIST
                : ROOT
            );
          }}
        >
          Terminal list
        </Button>
      </Box>
      <Box sx={{ width: "100%", display: "flex" }}>
        <Box flex={2} sx={{}}>
          <Box component="div" id="xtermjs" p={"10px"} />
        </Box>
        <Box flex={1} sx={{ mx: "20px" }}>
          <Grid container>
            <Grid item xs={5}>
              Name:
            </Grid>
            <Grid item xs={7}>
              {state.name}
            </Grid>
          </Grid>
          <Divider sx={{ bgcolor: theme.palette.primary.main }} />
          <Grid container>
            <Grid item xs={5}>
              Baud rate:
            </Grid>
            <Grid item xs={7}>
              {state.baudRate}
            </Grid>
          </Grid>
          <Divider sx={{ bgcolor: theme.palette.primary.main }} />
          <Grid container>
            <Grid item xs={5}>
              Data bits:
            </Grid>
            <Grid item xs={7}>
              {state.dataBits}
            </Grid>
          </Grid>
          <Divider sx={{ bgcolor: theme.palette.primary.main }} />
          <Grid container>
            <Grid item xs={5}>
              Parity:
            </Grid>
            <Grid item xs={7}>
              {state.parity}
            </Grid>
          </Grid>
          <Divider sx={{ bgcolor: theme.palette.primary.main }} />
          <Grid container>
            <Grid item xs={5}>
              Stop bits:
            </Grid>
            <Grid item xs={7}>
              {state.stopBits}
            </Grid>
          </Grid>
          <Divider sx={{ bgcolor: theme.palette.primary.main }} />
          <Grid container>
            <Grid item xs={5}>
              RTSCTS:
            </Grid>
            <Grid item xs={7}>
              {state.rtscts ? "on" : "off"}
            </Grid>
          </Grid>
          <Divider sx={{ bgcolor: theme.palette.primary.main }} />
          <Grid container>
            <Grid item xs={5}>
              XON:
            </Grid>
            <Grid item xs={7}>
              {state.xon ? "on" : "off"}
            </Grid>
          </Grid>
          <Divider sx={{ bgcolor: theme.palette.primary.main }} />
          <Grid container>
            <Grid item xs={5}>
              XOFF:
            </Grid>
            <Grid item xs={7}>
              {state.xoff ? "on" : "off"}
            </Grid>
          </Grid>
          <Divider sx={{ bgcolor: theme.palette.primary.main }} />
          <Grid container>
            <Grid item xs={5}>
              XANY:
            </Grid>
            <Grid item xs={7}>
              {state.xany ? "on" : "off"}
            </Grid>
          </Grid>
          <Divider sx={{ bgcolor: theme.palette.primary.main }} />
          {loading || !tftpIncluded ? null : (
            <>
              <TftpContainer
                miniPCId={state.miniPCId}
                enabled={isTFTPEnabled}
                container={container}
                tftpWebSocket={tftpWebSocket.current}
                userData={userData}
                networkData={networkData}
              />
              <UploadFile
                miniPCId={state.miniPCId}
                enabled={isTFTPEnabled}
                setFiles={setFiles}
                tftpWebSocket={tftpWebSocket.current}
                userData={userData}
                uploadSpinner={uploadSpinner}
                setUploadSpinner={setUploadSpinner}
              />
              <DownloadFile
                miniPCId={state.miniPCId}
                files={files}
                enabled={isTFTPEnabled}
                tftpWebSocket={tftpWebSocket.current}
                userData={userData}
              />
            </>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default OpenTerminal;
