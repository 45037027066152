import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  InputLabel,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/userSlice";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import HistoryToggleOffOutlinedIcon from "@mui/icons-material/HistoryToggleOffOutlined";
import { useApi } from "../../api/ContextApi";

const SingleTerminalComponent = (props) => {
  const userData = useSelector(selectUser);
  const [serialPortData, setSerialPortData] = useState(props.data);
  const [disabledButtton, setDisabledButton] = useState(false);
  const api = useApi();

  useEffect(() => {
    console.log(123);
    if (
      props.isEnable !== null &&
      serialPortData.enableHistory !== props.isEnable
    ) {
      setSerialPortData((prev) => ({
        ...prev,
        enableHistory: props.isEnable,
      }));
    }
  }, [props.isEnable, props.count]);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .min(5, "Name is too short")
      .max(50, "Name is too long"),
    baud_rate: Yup.number()
      .typeError("Baud rate is required")
      .required("Baud rate is required"),
    data_bits: Yup.number()
      .typeError("Data bits is required")
      .required("Data bits is required"),
    parity: Yup.string().required("Parity is required"),
    stop_bits: Yup.number()
      .typeError("Stop bits is required")
      .required("Stop bits is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    reset,
    setError,
  } = useForm({
    defaultValues: {
      name: serialPortData.name ? serialPortData.name : "",
      baud_rate: serialPortData.baud_rate ? serialPortData.baud_rate : "",
      data_bits: serialPortData.data_bits ? serialPortData.data_bits : "",
      parity: serialPortData.parity ? serialPortData.parity : "",
      stop_bits: serialPortData.stop_bits ? serialPortData.stop_bits : "",
      rtscts: serialPortData.rtscts,
      xon: serialPortData.xon,
      xoff: serialPortData.xoff,
      xany: serialPortData.xany,
      isVerified: serialPortData.verified,
    },
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });
  let theme = useTheme();

  const handleSaveButton = (data) => {
    api
      .updateSerialPort(
        serialPortData.id,
        data.name,
        data.baud_rate,
        data.data_bits,
        data.parity,
        data.stop_bits,
        data.rtscts,
        data.xon,
        data.xoff,
        data.xany,
        data.isVerified
      )
      .then((res) => {
        if (res.status === 200) window.location.reload(false);
        else if (res.data.message) {
          alert(res.data.message);
        }
      });
  };

  const handleDeleteButton = () => {
    if (serialPortData.status === "plug-out") {
      api.deleteSerialPort(serialPortData.id).then((res) => {
        if (res.status === 200) window.location.reload(false);
      });
    }
  };

  const handleForgetButton = () => {
    if (serialPortData.status === "plug-out") {
      api.forgetSerialPort(serialPortData.id);
    }
  };

  const handleEnableHistory = async () => {
    setDisabledButton((prev) => !prev);
    await api
      .enableHistory(serialPortData.id, !serialPortData.enableHistory)
      .then((res) => {
        if (res.status === 200) {
          setSerialPortData((prev) => ({
            ...prev,
            enableHistory: !prev.enableHistory,
          }));
          if (serialPortData.enableHistory) {
            props.enable();
          } else {
            props.disable();
          }
        }
        setDisabledButton((prev) => !prev);
      });
  };

  return (
    <>
      <Divider sx={{ bgcolor: theme.palette.primary.main }} />
      <ListItem
        sx={{
          px: "0px",
          bgcolor:
            props.index % 2 === 0
              ? theme.palette.fadedColors.primary
              : theme.palette.background.main,
        }}
      >
        <Accordion
          sx={{
            width: "100%",
            boxShadow: "none",
            bgcolor:
              props.index % 2 === 0
                ? theme.palette.fadedColors.primary
                : theme.palette.background.main,
          }}
          disableGutters={true}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={"panel" + serialPortData.id}
            id={"panel" + serialPortData.id}
            sx={{
              flexDirection: "row-reverse",
              p: "0px",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  wordBreak: "break-word",
                }}
              >
                {serialPortData.name ? serialPortData.name + " - " : null}
                {serialPortData.serial_number}:{serialPortData.pnpId}
              </Typography>
              <Box>
                <div
                  onClick={(event) => event.stopPropagation()}
                  style={{ display: "flex", justifyContent: "right" }}
                >
                  {props.consoleHistoryPermission ? (
                    <Tooltip
                      title={
                        serialPortData.enableHistory
                          ? "Disable console history"
                          : "Enable console history"
                      }
                    >
                      <IconButton
                        disabled={disabledButtton}
                        sx={{
                          color: serialPortData.enableHistory ? "green" : "red",
                          borderColor: disabledButtton
                            ? "#808080"
                            : serialPortData.enableHistory
                            ? "green"
                            : "red",
                          borderWidth: "2px",
                          borderStyle: "solid",
                          ":hover": {
                            boxShadow: serialPortData.enableHistory
                              ? "0px 0px 7px green"
                              : "0px 0px 7px red",
                          },
                          height: "2.5rem",
                          width: "2.5rem",
                          mr: "10px",
                          my: "auto",
                        }}
                        onClick={handleEnableHistory}
                      >
                        <HistoryToggleOffOutlinedIcon
                          sx={{ height: "1.8rem", width: "1.8rem" }}
                        />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                  {serialPortData.status === "plug-out" &&
                  (userData.userType === "global_admin" ||
                    userData.userType === "dc_admin") &&
                  serialPortData.accounts.length > 0 ? (
                    <Tooltip title="The serial port is no longer connected to the miniPC. Remove the port from all organizations' terminal lists">
                      <IconButton
                        sx={{
                          bgcolor: theme.palette.others.red,
                          color: theme.palette.background.main,
                          ":hover": {
                            bgcolor: theme.palette.others.dark_red,
                          },
                          height: "2.5rem",
                          width: "2.5rem",
                          borderColor: theme.palette.others.dark_red,
                          borderWidth: "1px",
                          borderStyle: "solid",
                          mr: "10px",
                          my: "auto",
                        }}
                        onClick={handleForgetButton}
                      >
                        <PersonOffIcon
                          sx={{ height: "1.8rem", width: "1.8rem" }}
                        />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                  {serialPortData.status === "plug-out" &&
                  (userData.userType === "global_admin" ||
                    userData.userType === "dc_admin") ? (
                    <Tooltip title="The serial port is no longer connected to the miniPC. Delete completely">
                      <IconButton
                        disabled={userData.isSuspended}
                        sx={{
                          bgcolor: theme.palette.others.red,
                          color: theme.palette.background.main,
                          ":hover": {
                            bgcolor: theme.palette.others.dark_red,
                          },
                          height: "2.5rem",
                          width: "2.5rem",
                          borderColor: theme.palette.others.dark_red,
                          borderWidth: "1px",
                          borderStyle: "solid",
                          mr: "10px",
                          my: "auto",
                        }}
                        onClick={handleDeleteButton}
                      >
                        <DeleteIcon
                          sx={{ height: "1.8rem", width: "1.8rem" }}
                        />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                  <Tooltip
                    title={
                      props.status === "suspended"
                        ? "DC is suspended"
                        : serialPortData.status === "plug-out"
                        ? "The device has been disconnected"
                        : ""
                    }
                  >
                    <Button
                      variant="contained"
                      sx={{
                        borderRadius: "50px",
                        zIndex: 12,
                        bgcolor:
                          props.status === "suspended" ||
                          serialPortData.status === "plug-out"
                            ? "#808080"
                            : null,
                        ":hover": {
                          bgcolor:
                            props.status === "suspended" ||
                            serialPortData.status === "plug-out"
                              ? "#5A5A5A"
                              : null,
                        },
                      }}
                      disabled={
                        !serialPortData.verified &&
                        serialPortData.status !== "plug-out"
                      }
                      onClick={() => {
                        if (
                          props.status === "online" &&
                          serialPortData.status !== "plug-out"
                        )
                          props.onConnect(
                            serialPortData.pnpId,
                            serialPortData.id,
                            serialPortData.name,
                            serialPortData.baud_rate,
                            serialPortData.data_bits,
                            serialPortData.parity,
                            serialPortData.stop_bits,
                            serialPortData.rtscts,
                            serialPortData.xon,
                            serialPortData.xoff,
                            serialPortData.xany,
                            props.miniPCId
                          );
                      }}
                    >
                      Connect
                    </Button>
                  </Tooltip>
                </div>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "90%",
                }}
              >
                <Box
                  sx={{
                    justifyContent: "center",
                    alignItems: "flex-start",
                    width: "33.3333%",
                  }}
                >
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        required
                        size="small"
                        label="Serial port name"
                        fullWidth
                        sx={{ mb: "15px", width: "90%" }}
                        error={errors.name ? true : false}
                        helperText={errors.name?.message}
                      />
                    )}
                  />
                </Box>
                <Box
                  sx={{
                    justifyContent: "center",
                    alignItems: "flex-start",
                    width: "33.3333%",
                  }}
                >
                  <Typography
                    sx={{
                      mb: "15px",
                      width: "90%",
                      borderWidth: "0px 0px 1px 0px",
                      borderColor: "#00000023",
                      borderStyle: "solid",
                      padding: "8.5px 14px",
                      overflow: "clip",
                    }}
                    textOverflow="ellipsis"
                    noWrap={true}
                    title={serialPortData.serial_number}
                  >
                    Serial number: {serialPortData.serial_number}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "90%",
                }}
              >
                <Box
                  sx={{
                    justifyContent: "center",
                    alignItems: "flex-start",
                    width: "66.6666%",
                  }}
                >
                  <Typography
                    sx={{
                      mb: "15px",
                      width: "95%",
                      borderWidth: "0px 0px 1px 0px",
                      borderColor: "#00000023",
                      borderStyle: "solid",
                      padding: "8.5px 14px",
                      overflow: "clip",
                    }}
                    textOverflow="ellipsis"
                    noWrap={true}
                    title={serialPortData.pnpId}
                  >
                    Id: {serialPortData.pnpId}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "90%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <InputLabel>Baud rate</InputLabel>
                  <Controller
                    name="baud_rate"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        required
                        id="baud-rate-status-select"
                        size="small"
                        sx={{ mb: "15px", width: "90%" }}
                        error={errors.baud_rate ? true : false}
                        title={errors.baud_rate?.message}
                      >
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={75}>75</MenuItem>
                        <MenuItem value={110}>110</MenuItem>
                        <MenuItem value={300}>300</MenuItem>
                        <MenuItem value={600}>600</MenuItem>
                        <MenuItem value={1200}>1200</MenuItem>
                        <MenuItem value={2400}>2400</MenuItem>
                        <MenuItem value={4800}>4800</MenuItem>
                        <MenuItem value={9600}>9600</MenuItem>
                        <MenuItem value={14400}>14400</MenuItem>
                        <MenuItem value={19200}>19200</MenuItem>
                        <MenuItem value={38400}>38400</MenuItem>
                        <MenuItem value={57600}>57600</MenuItem>
                        <MenuItem value={115200}>115200</MenuItem>
                        <MenuItem value={128000}>128000</MenuItem>
                        <MenuItem value={256000}>256000</MenuItem>
                      </Select>
                    )}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <InputLabel>Data bits</InputLabel>
                  <Controller
                    name="data_bits"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        required
                        id="data-bits-status-select"
                        size="small"
                        sx={{ mb: "15px", width: "90%" }}
                        error={errors.data_bits ? true : false}
                        title={errors.data_bits?.message}
                      >
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                      </Select>
                    )}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <InputLabel>Parity</InputLabel>
                  <Controller
                    name="parity"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        required
                        id="parity-status-select"
                        size="small"
                        sx={{ mb: "15px", width: "90%" }}
                        error={errors.parity ? true : false}
                        title={errors.parity?.message}
                      >
                        <MenuItem value={"even"}>Even</MenuItem>
                        <MenuItem value={"mark"}>Mark</MenuItem>
                        <MenuItem value={"none"}>None</MenuItem>
                        <MenuItem value={"odd"}>Odd</MenuItem>
                        <MenuItem value={"space"}>Space</MenuItem>
                      </Select>
                    )}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "90%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <InputLabel>Stop bits</InputLabel>
                  <Controller
                    name="stop_bits"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        required
                        id="stop-bits-status-select"
                        size="small"
                        sx={{ mb: "15px", width: "90%" }}
                        error={errors.stop_bits ? true : false}
                        title={errors.stop_bits?.message}
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={1.5}>1.5</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                      </Select>
                    )}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Controller
                          name="rtscts"
                          control={control}
                          render={({ field }) => (
                            <Checkbox checked={field.value} {...field} />
                          )}
                        />
                      }
                      label="RTSCTS"
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Controller
                          name="xon"
                          control={control}
                          render={({ field }) => (
                            <Checkbox checked={field.value} {...field} />
                          )}
                        />
                      }
                      label="XON"
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Controller
                          name="xoff"
                          control={control}
                          render={({ field }) => (
                            <Checkbox checked={field.value} {...field} />
                          )}
                        />
                      }
                      label="XOFF"
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Controller
                          name="xany"
                          control={control}
                          render={({ field }) => (
                            <Checkbox checked={field.value} {...field} />
                          )}
                        />
                      }
                      label="XANY"
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "90%",
                }}
              >
                {userData.userType !== "user" ||
                userData.userType !== "free_user" ? (
                  <FormControlLabel
                    control={
                      <Controller
                        name="isVerified"
                        control={control}
                        render={({ field }) => (
                          <Checkbox checked={field.value} {...field} />
                        )}
                      />
                    }
                    label="Port verification"
                  />
                ) : null}
              </Box>
              {props.consoleHistoryPermission ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "90%",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={disabledButtton}
                        checked={serialPortData.enableHistory}
                        onChange={handleEnableHistory}
                      />
                    }
                    label="Enable console history"
                  />
                </Box>
              ) : null}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "row",
                  width: "'100%'",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={handleSubmit(handleSaveButton)}
                  sx={{
                    bgcolor: theme.palette.primary.main,
                    ":hover": {
                      bgcolor: theme.palette.secondary.main,
                    },
                    color: theme.palette.text.main,
                    borderRadius: "100px",
                  }}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </ListItem>
    </>
  );
};

export default SingleTerminalComponent;
