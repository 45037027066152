import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    id: "",
    token: null,
    userType: null,
    userName: "",
    email: "",
    organization: "",
    adminName: "",
    adminEmail: "",
    apiKey: "",
    dataCenterIdentificator: "",
    miniPC: null,
    userList: [],
    terminals: [],
    data_center: "",
    VAT_number: null,
    phone: null,
    address: "",
    email_tech: "",
    email_pay: "",
    identificator: "",
    isSuspended: false,
  },
  reducers: {
    setUserData: (state, action) => {
      state.id = action.payload.id;
      state.token = action.payload.token;
      state.userType = action.payload.userType;
      state.email = action.payload.email;
      state.organization =
        action.payload.userType === "dc_admin"
          ? state.organization
          : action.payload.orgDcName;
      state.data_center =
        action.payload.userType === "dc_admin"
          ? action.payload.orgDcName
          : state.data_center;
      state.isSuspended = action.payload.isSuspended
        ? action.payload.isSuspended
        : state.isSuspended;
    },
    setProfileData: (state, action) => {
      state.userName = action.payload.name;
      state.email = action.payload.email;
      state.organization = action.payload.organization;
      state.adminName = action.payload.adminName;
      state.adminEmail = action.payload.adminEmail;
    },
    setProfileDataAdminDC: (state, action) => {
      state.userName = action.payload.name;
      state.email = action.payload.email;
      state.data_center = action.payload.data_center;
      state.adminName = action.payload.adminName;
      state.VAT_number = action.payload.VAT_number;
      state.phone = action.payload.phone;
      state.address = action.payload.address;
      state.email_tech = action.payload.email_tech;
      state.email_pay = action.payload.email_pay;
      state.identificator = action.payload.identificator;
    },
    setProfileDataGlobalAdmin: (state, action) => {
      state.userName = action.payload.name;
      state.email = action.payload.email;
      state.organization = action.payload.organization;
    },
    setProfileDataFreeUser: (state, action) => {
      state.userName = action.payload.name;
      state.email = action.payload.email;
      state.organization = action.payload.organization;
      state.adminName = action.payload.adminName;
      state.adminEmail = action.payload.adminEmail;
      state.apiKey = action.payload.apiKey;
      state.dataCenterIdentificator = action.payload.dataCenterIdentificator;
    },
    updateProfile: (state, action) => {
      state.userName = action.payload.name;
      state.email = action.payload.email;
    },
    updateProfileAdminDC: (state, action) => {
      state.userName = action.payload.name;
      state.email = action.payload.email;
      state.VAT_number = action.payload.VAT_number;
      state.phone = action.payload.phone;
      state.address = action.payload.address;
      state.email_tech = action.payload.email_tech;
      state.email_pay = action.payload.email_pay;
      state.identificator = action.payload.identificator;
    },
    setMiniPC: (state, action) => {
      state.miniPC = action.payload.miniPC;
    },
    setUserListData: (state, action) => {
      state.userList = action.payload.userList;
      state.terminals = action.payload.terminals;
    },
    setReset: (state, action) => {
      state.id = "";
      state.token = null;
      state.userType = null;
      state.userName = "";
      state.email = "";
      state.organization = "";
      state.adminName = "";
      state.adminEmail = "";
      state.miniPC = null;
      state.userList = [];
      state.terminals = [];
      state.data_center = "";
      state.VAT_number = null;
      state.phone = null;
      state.address = "";
      state.email_tech = "";
      state.email_pay = "";
      state.identificator = "";
    },
  },
});

export const {
  setUserData,
  setProfileData,
  setProfileDataAdminDC,
  setProfileDataGlobalAdmin,
  setProfileDataFreeUser,
  updateProfile,
  updateProfileAdminDC,
  setMiniPC,
  setUserListData,
  setReset,
} = userSlice.actions;

export const selectUser = (state) => {
  return {
    token: state.token,
    userType: state.userType,
    miniPC: state.miniPC,
    id: state.id,
    isSuspended: state.isSuspended,
  };
};

export const selectorProfile = (state) => {
  return {
    id: state.id,
    name: state.userName,
    email: state.email,
    organization: state.organization,
    adminName: state.adminName,
    adminEmail: state.adminEmail,
    apiKey: state.apiKey,
    dataCenterIdentificator: state.dataCenterIdentificator,
  };
};

export const selectorProfileGlobalAdmin = (state) => {
  return {
    id: state.id,
    name: state.userName,
    email: state.email,
    organization: state.organization,
  };
};

export const selectorProfileAdminDC = (state) => {
  return {
    id: state.id,
    name: state.userName,
    email: state.email,
    data_center: state.data_center,
    adminName: state.adminName,
    adminEmail: state.adminEmail,
    VAT_number: state.VAT_number,
    phone: state.phone,
    address: state.address,
    email_tech: state.email_tech,
    email_pay: state.email_pay,
    identificator: state.identificator,
  };
};

export const userType = (state) => {
  return {
    userType: state.userType,
  };
};

export const selectUsers = (state) => {
  return { users: state.users };
};

export const selectMiniPCs = (state) => {
  return { terminals: state.terminals };
};

export const selectHeaderData = (state) => {
  return {
    userType: state.userType,
    orgDcName:
      state.organization !== "" ? state.organization : state.data_center,
    email: state.email,
  };
};

export default userSlice.reducer;
