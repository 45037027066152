/* eslint-disable react-hooks/exhaustive-deps */
import { Typography, Container, Button, Box, List } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROOT } from "../../navigation/CONSTANTS";
import useAsyncFeedback from "../../hooks/useAsyncFeedback";
import { useEffect, useState } from "react";
import ConsoleHistorySearch from "./search";
import SingleMiniPC from "./singleMiniPC";
import { useApi } from "../../api/ContextApi";

const ConsoleHistory = () => {
  const navigate = useNavigate();
  const [handleAsyncInfo, { loading }] = useAsyncFeedback();
  const [handleAsyncConsoleHistoryPermission, { loading2 }] =
    useAsyncFeedback();
  const [miniPCs, setMiniPCs] = useState([]);
  const [filteredMiniPCs, setFilteredMiniPCs] = useState([]);
  const [filterBy, setFilterBy] = useState({
    miniPcName: "",
    pnpId: "",
    email: "",
    from: "",
    to: "",
  });
  const api = useApi();

  useEffect(() => {
    handleAsyncInfo(
      () => api.getPortsByAccountType(),
      null,
      (data) => {
        setMiniPCs(data.miniPC);
        setFilteredMiniPCs(data.miniPC);
      },
      "loading"
    );
  }, []);

  useEffect(() => {
    handleAsyncConsoleHistoryPermission(
      () => api.getConsolehistoryPermission(),
      null,
      (data) => {
        console.log(data);
        if (data) {
          if (data.allow === false) {
            handleBackToHomePage();
          }
        } else {
          handleBackToHomePage();
        }
      },
      "loading2"
    );
  }, []);

  useEffect(() => {
    if (filterBy.miniPcName === "" && filterBy.pnpId === "") {
      setFilteredMiniPCs(miniPCs);
    }
    let filteredMiniPC;
    filteredMiniPC = miniPCs.map((item) => {
      let clonedItem = { ...item };
      if (
        filterBy.miniPcName !== "" &&
        !item.name.toLowerCase().includes(filterBy.miniPcName.toLowerCase())
      ) {
        return null;
      }
      clonedItem.serial_ports = item.serial_ports.filter((serialPort) => {
        return filterBy.pnpId === ""
          ? true
          : serialPort.pnpId
              .toLowerCase()
              .includes(filterBy.pnpId.toLowerCase());
      });
      return clonedItem;
    });
    filteredMiniPC = filteredMiniPC.filter((item) => {
      return item !== null;
    });
    setFilteredMiniPCs(filteredMiniPC);
  }, [filterBy]);

  const handleBackToHomePage = () => {
    navigate(ROOT);
  };

  if (loading2) {
    return <></>;
  }

  return (
    <>
      <Container
        maxWidth="xl"
        sx={{
          mx: "auto",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography m="10px" variant="h4">
          Console history:
        </Typography>
        <Button
          variant="contained"
          onClick={handleBackToHomePage}
          sx={{ borderRadius: "50px" }}
        >
          Home page
        </Button>
      </Container>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          mx: "auto",
        }}
        maxWidth="xl"
      >
        <ConsoleHistorySearch setFilterBy={setFilterBy} filterBy={filterBy} />
        <Box mx={"10px"}>
          {(miniPCs.length === 0 && !loading) ||
          filteredMiniPCs.length === 0 ? null : (
            <List>
              {filteredMiniPCs.map((value, index) => {
                return value !== null ? (
                  <SingleMiniPC
                    key={value.id}
                    miniPC={value}
                    index={index}
                    serialPorts={value.serial_ports}
                    filterBy={filterBy}
                  />
                ) : null;
              })}
            </List>
          )}
        </Box>
      </Container>
    </>
  );
};

export default ConsoleHistory;
