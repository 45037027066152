import React, { createContext, useContext } from "react";
import createApi from "./index";
import { useAuth } from "../navigation/authorization/ProvideAuth";

export const ApiContext = createContext();

export const useApi = () => {
  return useContext(ApiContext);
};

export const ApiProvider = ({ children }) => {
  const auth = useAuth();
  const api = createApi(auth.signout);

  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
};
