export const ROOT = "/";
export const RESETPASSWORD = "/forgot-password";
export const TERMINALLIST = "/terminalList";
export const OPENTERMINAL = "/terminal";
export const STATISTICS = "/statistics";
export const DATACENTERLISTMANAGEMENT = "/user-management-data-center";
export const ORGANIZATIONLISTMANAGEMENT = "/user-management-organizations";
export const USERLISTMANAGEMENT = "/user-management-users";
export const APIKEYLISTMANAGEMENT = "/api-key-management";
export const PROFILE = "/profile";
export const PRICINGPLANS = "/pricing-plans";
export const CONSOLEHISTORY = "/console-history";
export const SERVERSTATUS = "/server-status";
