export const getAvailableToShareTerminals = (userPorts, selectedUser) => {
  try {
    let filteredUserPorts = [];
    userPorts.forEach((miniPC, index) => {
      let tempMiniPC = filteredUserPorts.push({
        id: miniPC.id,
        name: miniPC.name,
        serial_ports: [],
      });
      let selectedUserPortsByMiniPC = [];
      miniPC.serial_ports.forEach((port) => {
        let isFound = false;
        selectedUser.serial_ports.forEach((port2) => {
          if (port.id === port2.id) {
            isFound = true;
          }
        });
        if (!isFound) {
          selectedUserPortsByMiniPC.push(port);
        }
      });
      filteredUserPorts[index].serial_ports = selectedUserPortsByMiniPC;
    });
    filteredUserPorts = filteredUserPorts.filter(
      (miniPC) => miniPC.serial_ports.length > 0
    );
    return filteredUserPorts;
  } catch (err) {
    console.log("Error getting terminals: " + err);
    return {};
  }
};
