import {
  Box,
  Divider,
  Grid,
  IconButton,
  ListItem,
  Tooltip,
  Typography,
  useTheme,
  Modal,
  Button,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import ComputerIcon from "@mui/icons-material/Computer";
import { TERMINALLIST } from "../../../navigation/CONSTANTS";
import { useApi } from "../../../api/ContextApi";

const SingleApiKey = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const usedAt =
    props.data.usedAt === null
      ? ""
      : new Date(props.data.usedAt).toLocaleDateString();
  const [modal, setModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const api = useApi();

  const handleForgetButton = () => {
    api.forgetApiKey(props.data.id).then((res) => {
      if (res.status === 200) {
        props.setApiKeyData(
          props.apiKeyData.map((item) => {
            if (item.id === props.data.id) {
              return { ...item, mini_pc_id: null, usedAt: null, mini_pc: null };
            } else return item;
          })
        );
      }
    });
  };

  const handleDeleteButton = () => {
    if (props.data.draft) {
      props.setApiKeyData(
        props.apiKeyData.filter((item) => item.id !== props.data.id)
      );
    } else {
      setIsDeleting(true);
      api.deleteApiKey(props.data.id).then((res) => {
        if (res.status === 200) {
          props.setApiKeyData(
            props.apiKeyData.filter((item) => item.id !== props.data.id)
          );
        } else if (res.status >= 400) {
          setIsDeleting(false);
        }
      });
    }
  };

  return (
    <Box key={props.data.id}>
      <Divider
        sx={{
          bgcolor: theme.palette.primary.main,
          borderBottomWidth: "2px",
          mx: "16px",
        }}
      />
      <ListItem key={props.data.id} sx={{ py: "0px" }}>
        <Grid container spacing={2} sx={{ alignItems: "center" }}>
          <Grid item xs={2}>
            <Typography sx={{ wordWrap: "break-word" }}>
              {props.data.key}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{ wordWrap: "break-word" }}>
              {props.data.account ? props.data.account.email : ""}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{ wordWrap: "break-word" }}>
              {props.data.mini_pc ? props.data.mini_pc.name : ""}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography sx={{ wordWrap: "break-word" }}>
              {props.data.usedAt === null ? "" : usedAt}
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              minHeight: "4.5rem",
            }}
          >
            {props.data.mini_pc_id === null ? null : (
              <Tooltip title="Go to mini PC">
                <IconButton
                  sx={{
                    bgcolor: theme.palette.primary.main,
                    color: theme.palette.background.main,
                    ":hover": {
                      bgcolor: theme.palette.secondary.main,
                    },
                    height: "2.5rem",
                    width: "2.5rem",
                    borderColor: theme.palette.primary.main,
                    borderWidth: "1px",
                    borderStyle: "solid",
                    ml: "10px",
                    my: "auto",
                  }}
                  onClick={() => {
                    navigate(
                      TERMINALLIST + "?miniPcId=" + props.data.mini_pc_id
                    );
                  }}
                >
                  <ComputerIcon sx={{ height: "1.8rem", width: "1.8rem" }} />
                </IconButton>
              </Tooltip>
            )}
            {props.data.mini_pc_id === null ? null : (
              <Tooltip title="Forget mini PC">
                <IconButton
                  sx={{
                    bgcolor: theme.palette.primary.main,
                    color: theme.palette.background.main,
                    ":hover": {
                      bgcolor: theme.palette.secondary.main,
                    },
                    height: "2.5rem",
                    width: "2.5rem",
                    borderColor: theme.palette.primary.main,
                    borderWidth: "1px",
                    borderStyle: "solid",
                    ml: "10px",
                    my: "auto",
                  }}
                  onClick={handleForgetButton}
                >
                  <SettingsBackupRestoreIcon
                    sx={{ height: "1.8rem", width: "1.8rem" }}
                  />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Delete">
              <IconButton
                sx={{
                  bgcolor: theme.palette.primary.main,
                  color: theme.palette.background.main,
                  ":hover": {
                    bgcolor: theme.palette.secondary.main,
                  },
                  height: "2.5rem",
                  width: "2.5rem",
                  borderColor: theme.palette.primary.main,
                  borderWidth: "1px",
                  borderStyle: "solid",
                  ml: "10px",
                  my: "auto",
                }}
                onClick={() => setModal(true)}
              >
                <DeleteIcon sx={{ height: "1.8rem", width: "1.8rem" }} />
              </IconButton>
            </Tooltip>
            <Modal
              open={modal}
              onClose={() => setModal(false)}
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "white",
                  padding: "20px",
                  borderRadius: "20px",
                  marginBottom: "15px",
                  maxWidth: "600px",
                }}
              >
                <Typography id="modal-modal-title" sx={{ textAlign: "center" }}>
                  Delete API key
                </Typography>
                <Typography
                  className="modal-modal-description"
                  sx={{ marginBottom: "15px" }}
                >
                  Are you sure you want to delete the{" "}
                  <span style={{ color: theme.palette.primary.main }}>
                    {props.data.key}
                  </span>{" "}
                  API key?
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Button
                    disabled={isDeleting}
                    variant="outlined"
                    onClick={() => handleDeleteButton()}
                    sx={{ borderRadius: "10px", borderWidth: "2px" }}
                  >
                    Delete
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => setModal(false)}
                    sx={{ borderRadius: "10px", borderWidth: "2px" }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Modal>
          </Grid>
        </Grid>
      </ListItem>
    </Box>
  );
};

export default SingleApiKey;
