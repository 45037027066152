import { Box, Button, TextField, useTheme } from "@mui/material";
import { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useApi } from "../../api/ContextApi";

const TwoFactorAuthenticationLogin = (props) => {
  const [code, setCode] = useState("");
  const theme = useTheme();
  const loading = useRef(false);
  const api = useApi();

  const validationSchema = Yup.object().shape({
    code: Yup.string().min(5, "Code is too short").max(10, "Code is too long"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    reset,
    setError,
  } = useForm({
    defaultValues: { code: code },
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const onSubmit = () => {
    api.verify2FASecret(props.userId, code).then((response) => {
      console.log(response.data);
      if (response.status === 200) {
        if (response.data.verified) {
          props.setUserAuthorized();
        } else {
          setError("code", {
            type: "custom",
            message: response.data.message,
          });
        }
      } else {
        setError("code", {
          type: "custom",
          message: response.data.message,
        });
      }
    });
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
      <Controller
        name="code"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            margin="normal"
            fullWidth
            id="code"
            label="One-time password"
            name="code"
            autoFocus
            error={errors.code ? true : false}
            helperText={errors.code?.message}
            onChangeCapture={(event) => setCode(event.target.value)}
          />
        )}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        disabled={loading.current}
        sx={{
          mt: 3,
          mb: 2,
          bgcolor: theme.palette.primary.main,
          ":hover": { bgcolor: theme.palette.secondary.main },
          color: theme.palette.text.main,
        }}
      >
        Verify
      </Button>
    </Box>
  );
};

export default TwoFactorAuthenticationLogin;
