import {
  Box,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  List,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import useAsyncFeedback from "../../../hooks/useAsyncFeedback";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import { useApi } from "../../../api/ContextApi";

const OrganizationList = (props) => {
  const theme = useTheme();
  const [handleAsyncOrg, { loading }] = useAsyncFeedback();
  const [newOrganizations, setNewOrganizations] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [add, setAdd] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState([]);
  const [ready, setReady] = useState(false);
  const api = useApi();

  useEffect(() => {
    setOrganizations(props.data.organizations);
    setReady(true);
  }, []);

  useEffect(() => {
    if (ready) {
      handleAsyncOrg(
        () => api.getOrganizations(),
        null,
        (data) => {
          const temp = data.organizations.filter((org) => {
            let finded = false;
            organizations.forEach((item) => {
              if (item.id === org.id) {
                finded = true;
              }
            });
            return !finded;
          });
          setNewOrganizations(temp);
        },
        "loading"
      );
    }
  }, [organizations]);

  const handleAddOrganization = () => {
    setAdd(true);
  };

  const handleSelectOrganization = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedOrg(typeof value === "string" ? value.split(",") : value);
  };

  const handleSaveOrganization = () => {
    api.addOrganizations({
      data_center_id: props.data.id,
      organization_ids: selectedOrg,
    });
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h6">Organizations</Typography>
        <Box display="flex" justifyContent="right">
          {add ? (
            <Box
              component="form"
              display="flex"
              onSubmit={handleSaveOrganization}
            >
              <Select
                name="organization"
                value={selectedOrg}
                required
                size="small"
                variant="standard"
                id="user-management-dc-add-org"
                sx={{ minWidth: "200px" }}
                label="Organizations"
                multiple
                onChange={handleSelectOrganization}
              >
                {newOrganizations.map((org) => {
                  return (
                    <MenuItem key={org.id} value={org.id}>
                      {org.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <Tooltip title={"Save"}>
                <IconButton
                  type="submit"
                  sx={{
                    color: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main,
                    borderWidth: "2px",
                    borderStyle: "solid",
                    ml: "10px",
                  }}
                >
                  <SaveIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          ) : (
            <Tooltip title={"Add organization"}>
              <IconButton
                onClick={handleAddOrganization}
                sx={{
                  color: theme.palette.primary.main,
                  borderColor: theme.palette.primary.main,
                  borderWidth: "2px",
                  borderStyle: "solid",
                  ml: "10px",
                }}
              >
                <AddIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          Organization name
        </Grid>
        <Grid item xs={4}>
          Admin name
        </Grid>
      </Grid>
      <List>
        {organizations.map((organization, index) => {
          return (
            <Box component="div" key={organization.id}>
              <Divider
                sx={{
                  borderBottomWidth: index === 0 ? "2px" : "1px",
                  width: "100%",
                  bgcolor: theme.palette.primary.main,
                }}
              />
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  {organization.name}
                </Grid>
                <Grid item xs={4} display="flex" alignItems="center">
                  {organization.accounts[0]
                    ? organization.accounts[0].name
                    : ""}
                </Grid>
              </Grid>
            </Box>
          );
        })}
      </List>
    </>
  );
};

export default OrganizationList;
