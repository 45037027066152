import { Box, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import useAsyncFeedback from "../../hooks/useAsyncFeedback";
import { useApi } from "../../api/ContextApi";

const GeneralInformations = () => {
  const theme = useTheme();
  const [handleAsyncInfo, { loading }] = useAsyncFeedback();
  const [data, setData] = useState({
    numberOfOrganizations: "",
    numberOfUsers: "",
    numberOfOnlineUsers: "",
    numberOfMiniPC: "",
    numberOfTerminals: "",
    numberOfOpenedTerminals: "",
  });
  const api = useApi();

  useEffect(() => {
    handleAsyncInfo(
      () => api.getGeneralInformations(),
      null,
      (data) => {
        setData(data);
      },
      "loading"
    );
  }, []);

  return (
    <>
      <Box height="2.5rem" display="flex" alignItems="center">
        <Typography
          sx={{
            mb: "5px",
            ml: "20px",
            mr: "10px",
            color: theme.palette.secondary.main,
          }}
        >
          General Information
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          mt: "5px",
          mb: "5px",
          ml: "20px",
          mr: "10px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            borderWidth: "1px",
            borderColor: theme.palette.primary.main,
            borderStyle: "solid",
            borderRadius: "10px",
            width: "100%",
            p: "15px",
          }}
        >
          <Typography id={"general-info-organization-number-text"}>
            Number of organizations: {data.numberOfOrganizations}
          </Typography>
          <Typography>Number of users: {data.numberOfUsers}</Typography>
          <Typography>
            Number of online users: {data.numberOfOnlineUsers}
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          mt: "5px",
          mb: "5px",
          ml: "20px",
          mr: "10px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            borderWidth: "1px",
            borderColor: theme.palette.primary.main,
            borderStyle: "solid",
            borderRadius: "10px",
            width: "100%",
            p: "15px",
          }}
        >
          <Typography>
            Number of MiniPc devices: {data.numberOfMiniPC}
          </Typography>
          <Typography>
            Number of individual teminals: {data.numberOfTerminals}
          </Typography>
          <Typography>
            The number of currently open terminals:{" "}
            {data.numberOfOpenedTerminals}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default GeneralInformations;
