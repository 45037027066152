import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Box,
  Container,
  useTheme,
  InputAdornment,
  IconButton,
} from "@mui/material";
import images from "../../assets";
import { RESETPASSWORD } from "../../navigation/CONSTANTS";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { setUserData } from "../../redux/userSlice";
import Copyright from "../../components/Copyright";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import TwoFactorAuthenticationLogin from "./twoFactorAuthentication";
import { useApi } from "../../api/ContextApi";

const defaultInputValues = {
  email: "",
  password: "",
};

const Login = () => {
  const [values, setValues] = useState(defaultInputValues);
  let theme = useTheme();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState();
  const loading = useRef(false);
  const token = useRef(null);
  const userId = useRef(null);
  const userType = useRef(null);
  const orgDcName = useRef(null);
  const email = useRef(null);
  const isSuspended = useRef(false);
  const [twoFactorAuthentication, setTwoFactorAuthentication] = useState(false);
  const api = useApi();

console.log(process.env.REACT_APP_API_URL);
console.log(process.env.NODE_ENV);
console.log(process.env.REACT_APP_WEBSOCKET_API_URL);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email address is required")
      .email("Please enter a valid email address"),
    password: Yup.string()
      .required("Password is required")
      .min(5, "Password is too short")
      .max(50, "Password is too long"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    reset,
    setError,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
  });

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  const handleChange = (value) => {
    setValues(value);
  };

  const setUserAuthorized = () => {
    api.setAuthToken(token.current);
    dispatch(
      setUserData({
        id: userId.current,
        token: token.current,
        userType: userType.current,
        email: email.current,
        orgDcName: orgDcName.current,
        isSuspended: isSuspended.current,
      })
    );
  };

  const onSubmit = (values) => {
    loading.current = true;
    api
      .login(values.email, values.password)
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          userId.current = res.data.id;
          token.current = res.data.token;
          userType.current = res.data.userType;
          email.current = res.data.email;
          orgDcName.current = res.data.orgDcName;
          isSuspended.current = res.data.is_suspended;
          if (!res.data.has2FA) {
            setUserAuthorized();
          } else {
            setTwoFactorAuthentication(true);
          }
        } else if (res.status === 403) {
          setError("email", {
            type: "custom",
            message: "This account has been suspended",
          });
          setValues({ ...values, password: "" });
        } else {
          if (res.status >= 500) {
            alert(
              "The server is currently in the process of optimization and the service is unavailable. Please try again in a few minutes."
            );
          }
          setError("email", {
            type: "custom",
            message: "The email or password is incorrect.",
          });
          setValues({ ...values, password: "" });
        }
      })
      .then(() => {
        loading.current = false;
      });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          sx={{ mb: "-60px", maxWidth: "300px" }}
          src={images.logoVerticalColor}
        ></Box>
        {twoFactorAuthentication ? (
          <TwoFactorAuthenticationLogin
            userId={userId.current}
            setUserAuthorized={setUserAuthorized}
          />
        ) : (
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              fullWidth
              id="email"
              label="Email address"
              name="email"
              autoComplete="email"
              autoFocus
              {...register("email")}
              error={errors.email ? true : false}
              helperText={errors.email?.message}
              value={values.email}
              onChange={(event) =>
                handleChange({ ...values, email: event.target.value })
              }
            />
            <TextField
              margin="normal"
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              {...register("password")}
              error={errors.password ? true : false}
              helperText={errors.password?.message}
              value={values.password}
              onChange={(event) =>
                handleChange({ ...values, password: event.target.value })
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={() => handleClickShowPassword()}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              type="submit"
              id="submit-button-login"
              fullWidth
              variant="contained"
              disabled={loading.current}
              sx={{
                mt: 3,
                mb: 2,
                bgcolor: theme.palette.primary.main,
                ":hover": { bgcolor: theme.palette.secondary.main },
                color: theme.palette.text.main,
              }}
            >
              Sign in
            </Button>
            <Grid container>
              <Grid item xs>
                <Link
                  href={RESETPASSWORD}
                  variant="body2"
                  sx={{ color: "#1b212c" }}
                >
                  Forgot your password?
                </Link>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} color="text.secondary" />
    </Container>
  );
};

export default Login;
