/**
 * theme for MUI
 */
import { createTheme } from "@mui/material/styles";

export const dark = () =>
  createTheme({
    typography: {
      fontFamily: "Lexend",
    },
    palette: {
      type: "dark",
      primary: {
        main: "#00A1AD",
      },
      fadedColors: {
        primary: "#E5F5F6",
        secondary: "#E5EBEB",
      },
      secondary: {
        main: "#00393D",
      },
      grey_color: {
        button: "#7a7a7a",
      },
      text: {
        main: "#FFFFFF",
        opposite: "#000000",
      },
      background: {
        main: "#333333",
      },
      others: {
        red: "#ff0000",
        dark_red: "#960000",
        green: "#008000",
        dark_green: "#005900",
        newNotification: "#FFBF20",
        newNotificationHover: "#CC9819",
      },
      notifications: {
        background: "#00A1AD30",
      },
    },
  });

export const light = () =>
  createTheme({
    typography: {
      fontFamily: "Lexend",
    },
    palette: {
      type: "light",
      primary: {
        main: "#15D1B3",
      },
      fadedColors: {
        primary: "#E7FAF7",
        secondary: "#E5F5F6",
      },
      secondary: {
        main: "#00A1AD",
      },
      grey_color: {
        button: "#7a7a7a",
      },
      text: {
        main: "#000000",
        opposite: "#FFFFFF",
      },
      background: {
        main: "#FFFFFF",
      },
      others: {
        red: "#ff0000",
        dark_red: "#B20000",
        green: "#008000",
        dark_green: "#005900",
        newNotification: "#FFBF20",
        newNotificationHover: "#CC9819",
      },
      notifications: {
        background: "#00A1AD30",
      },
    },
  });
