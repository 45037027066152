import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  Typography,
  useTheme,
} from "@mui/material";
import SingleConsoleHistory from "./singleConsoleHistory";
import { useEffect, useRef, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useAsyncFeedback from "../../hooks/useAsyncFeedback";
import { BeatLoader } from "react-spinners";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useApi } from "../../api/ContextApi";

const SingleSerialPort = (props) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);
  const [handleAsyncInfo, { loading }] = useAsyncFeedback();
  const fullHistory = useRef([]);
  const history = useRef([]);
  const [filteredHistory, setFilteredHistory] = useState(history);
  const [continuationToken, setContinuationToken] = useState(undefined);
  const [page, setPage] = useState(1);
  const resultsPerPage = 10;
  const lastPage = Math.ceil(history.current.length / resultsPerPage);
  const api = useApi();

  useEffect(() => {
    console.log(78);
    history.current = fullHistory.current.filter((item) => {
      const email = getEmail(item.Key);
      let result = 0;
      console.log("Result: " + result);
      if (
        props.filterBy.email !== "" &&
        !email.toLowerCase().includes(props.filterBy.email.toLowerCase())
      ) {
        result++;
      }
      console.log(new Date(item.LastModified));
      console.log(
        new Date(new Date(props.filterBy.to).getDate() + 1000 * 60 * 60 * 24)
      );
      console.log(
        new Date(item.LastModified) >
          new Date(props.filterBy.to) + new Date(1000 * 60 * 60 * 24)
      );
      console.log("Result: " + result);
      if (
        props.filterBy.from !== "" &&
        new Date(item.LastModified) < new Date(props.filterBy.from)
      ) {
        result++;
      }
      console.log("Result: " + result);
      const date = new Date(props.filterBy.to);
      date.setDate(date.getDate() + 1);
      const updatedDateStr = date.toISOString().split("T")[0];
      console.log(updatedDateStr);
      if (
        props.filterBy.to !== "" &&
        new Date(item.LastModified) > new Date(updatedDateStr)
      ) {
        result++;
      }
      console.log("Result: " + result);
      return result > 0 ? false : true;
    });
    setPage(1);
    let temp = history.current.slice(0, resultsPerPage);
    setFilteredHistory(temp);
  }, [props.filterBy.email, props.filterBy.from, props.filterBy.to]);

  const getEmail = (str) => {
    const parts = str.split(" - ");

    if (parts.length > 1) {
      console.log(1);
      const email = parts[parts.length - 1].replace(".json", "");
      return ` - ${email}`;
    } else {
      console.log(2);
      return "";
    }
  };

  useEffect(() => {
    handleAsyncInfo(
      () =>
        api.getSerialPortConsoleHistory(
          props.miniPCId,
          props.data.id,
          continuationToken
        ),
      null,
      (data) => {
        let reversedData = data.contents.sort(
          (a, b) => new Date(b.LastModified) - new Date(a.LastModified)
        );
        console.log(props.filterBy);
        console.log(reversedData);
        fullHistory.current = reversedData;
        history.current = reversedData.filter((item) => {
          const email = getEmail(item.Key);
          let result = 0;
          if (
            props.filterBy.email !== "" &&
            !email.toLowerCase().includes(props.filterBy.email.toLowerCase())
          ) {
            result++;
          }
          console.log(new Date(item.LastModified));
          console.log(new Date(props.filterBy.from));
          console.log(
            new Date(item.LastModified).getUTCDate() <
              new Date(props.filterBy.from)
          );
          if (
            props.filterBy.from !== "" &&
            new Date(item.LastModified).getDate() <
              new Date(props.filterBy.from)
          ) {
            result++;
          }
          if (
            props.filterBy.to !== "" &&
            new Date(item.LastModified).getDate() > new Date(props.filterBy.to)
          ) {
            result++;
          }
          return result > 0 ? false : true;
        });
        let temp = reversedData.slice(
          (page - 1) * resultsPerPage,
          page * resultsPerPage
        );
        setFilteredHistory(temp);
        setContinuationToken(data.continuationToken);
      },
      "loading"
    );
  }, []);

  useEffect(() => {
    if (page === lastPage) {
      setFilteredHistory(history.current.slice((page - 1) * resultsPerPage));
    } else {
      setFilteredHistory(
        history.current.slice(
          (page - 1) * resultsPerPage,
          page * resultsPerPage
        )
      );
    }
  }, [page]);

  return (
    <Box>
      <Divider sx={{ bgcolor: theme.palette.primary.main }} />
      <ListItem
        sx={{
          p: "0px",
          bgcolor:
            props.index % 2 === 0
              ? theme.palette.fadedColors.primary
              : theme.palette.background.main,
        }}
      >
        <Accordion
          disableGutters
          sx={{
            width: "100%",
            boxShadow: "none",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              flexDirection: "row-reverse",
              p: "0px",
              alignItems: "center",
              bgcolor:
                props.index % 2 === 0
                  ? theme.palette.fadedColors.primary
                  : theme.palette.background.main,
            }}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <Typography
              sx={{
                wordBreak: "break-word",
              }}
            >
              {props.data.name ? props.data.name + " - " : null}
              {props.data.serial_number}:{props.data.pnpId}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              bgcolor:
                props.index % 2 === 0
                  ? theme.palette.fadedColors.primary
                  : theme.palette.background.main,
              py: 0,
            }}
          >
            {loading || history.current.length === 0 ? (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                py={2}
              >
                {loading ? (
                  <BeatLoader color={theme.palette.primary.main} />
                ) : (
                  <Typography>Empty</Typography>
                )}
              </Box>
            ) : (
              <>
                <List sx={{ py: 0 }}>
                  {filteredHistory.map((value, index) => {
                    return (
                      <ListItem
                        sx={{ width: "100%" }}
                        key={JSON.stringify(value)}
                      >
                        <SingleConsoleHistory
                          data={value}
                          index={index}
                          miniPcId={props.miniPCId}
                          serialPortId={props.data.id}
                          serialPortName={props.data.name}
                          pnpId={props.data.pnpId}
                        />
                        {index !== filteredHistory.length - 1 ? (
                          <Divider
                            sx={{ bgcolor: theme.palette.primary.main }}
                          />
                        ) : null}
                      </ListItem>
                    );
                  })}
                </List>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {page > 1 ? (
                    <IconButton
                      onClick={() => setPage((prev) => prev - 1)}
                      sx={{ width: 30 }}
                    >
                      <NavigateBeforeIcon />
                    </IconButton>
                  ) : (
                    <Box sx={{ width: 30 }} />
                  )}
                  {page}
                  {page !== lastPage ? (
                    <IconButton
                      onClick={() => setPage((prev) => prev + 1)}
                      sx={{ width: 30 }}
                    >
                      <NavigateNextIcon />
                    </IconButton>
                  ) : (
                    <Box sx={{ width: 30 }} />
                  )}
                </Box>
              </>
            )}
          </AccordionDetails>
        </Accordion>
      </ListItem>
    </Box>
  );
};

export default SingleSerialPort;
