import { Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import Cards from "./cards";

const Dashboard = () => {
  return (
    <>
      <Container maxWidth="lg">
        {/* <Typography variant="h4" sx={{ mt: "10px" }}>
          Notifications
        </Typography>
        <Typography>
          Sample message from website owner to all users. Lorem Ipsum is simply
          dummy text of the printing and typesetting industry. Lorem Ipsum has
          been the industry's standard dummy text ever since the 1500s, when an
          unknown printer took a galley of type and scrambled it to make a type
          specimen book.
        </Typography> */}
        <Grid
          container
          sx={{
            mt: "110px",
            mb: 8,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Cards />
        </Grid>
      </Container>
    </>
  );
};

export default Dashboard;
