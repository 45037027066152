import { List, ListItem } from "@mui/material";
import { useEffect, useState } from "react";
import useAsyncFeedback from "../../../hooks/useAsyncFeedback";
import SingleConfigurationPlan from "./singlePlan";
import { useApi } from "../../../api/ContextApi";

const ConfigurationPricingPlans = () => {
  const [pricingPlans, setPricingPlans] = useState([]);
  const [handleAsyncPlans, { loading }] = useAsyncFeedback();
  const api = useApi();

  useEffect(() => {
    handleAsyncPlans(
      () => api.getAllPricingPlans(),
      null,
      (data) => {
        setPricingPlans(data.pricingPlans);
      },
      "loading"
    );
  }, []);

  if (loading) {
    console.log("Loading ...");
    return <></>;
  } else {
    console.log("Loaded!");
  }

  return (
    <>
      <List>
        {pricingPlans.map((plan) => {
          return (
            <ListItem key={plan.id} sx={{ p: "0px" }}>
              <SingleConfigurationPlan plan={plan} />
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

export default ConfigurationPricingPlans;
