import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { PulseLoader } from "react-spinners";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import YupPassword from "yup-password";
YupPassword(Yup);

const TftpContainer = (props) => {
  const validationSchema = Yup.object().shape({
    ipv4: Yup.string().required("IP address is required"),
    mask: Yup.string().required("Mask is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    reset,
    getValues,
    setError,
  } = useForm({
    defaultValues: {
      ipv4: "",
      mask: "",
    },
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
  });
  let theme = useTheme();

  function isValidIP(ip) {
    const octets = ip.split(".");
    return (
      octets.length === 4 &&
      octets.every(
        (octet) =>
          !isNaN(octet) && parseInt(octet) >= 0 && parseInt(octet) <= 255
      )
    );
  }

  function isValidMask(mask) {
    const octets = mask.split(".").map(Number);
    if (octets.length !== 4) return false;
    let zeroFound = false;
    for (const octet of octets) {
      if (isNaN(octet) || octet < 0 || octet > 255) return false;
      const binaryOctet = octet.toString(2).padStart(8, "0");
      for (const bit of binaryOctet) {
        if (bit === "0") zeroFound = true;
        if (bit === "1" && zeroFound) return false;
      }
    }
    return true;
  }

  function calculateSubnet(ip, mask) {
    const ipArray = ip.split(".").map(Number);
    const maskArray = mask.split(".").map(Number);

    const subnetArray = ipArray.map((octet, index) => octet & maskArray[index]);

    const cidr =
      maskArray
        .map((octet) => octet.toString(2))
        .join("")
        .split("1").length - 1;

    return `${subnetArray.join(".")}/${cidr}`;
  }

  const handleContainerCreation = (data) => {
    try {
      if (props.networkData.value && props.networkData.vlan_id) {
        if (!isValidIP(data.ipv4)) {
          setError("ipv4", {
            type: "custom",
            message: "IP address is incorrect",
          });
        }
        if (!isValidMask(data.mask)) {
          setError("mask", {
            type: "custom",
            message: "Mask is incorrect",
          });
        }
        if (!isValidIP(data.ipv4) || !isValidMask(data.mask)) {
          return "Invalid IP or mask";
        }
        const subnet = calculateSubnet(data.ipv4, data.mask);
        props.tftpWebSocket.send(
          JSON.stringify({
            type: "createContainer",
            data: {
              userId: props.userData.id,
              ipv4: data.ipv4,
              subnet: subnet,
              networkInterface: props.networkData.value,
              vlanId: props.networkData.vlan_id,
            },
            userId: props.userData.id,
          })
        );
      }
    } catch (err) {
      console.error("Error: in submit container creation: " + err);
    }
  };

  return (
    <Box>
      <Typography sx={{ marginTop: "26px", fontSize: "20px" }}>
        TFTP connection
      </Typography>
      <Typography sx={{ fontSize: "18px" }}>
        Network interface:{" "}
        {props.networkData.value ? props.networkData.value : ""}
      </Typography>
      <Typography sx={{ fontSize: "18px" }}>
        VLAN ID: {props.networkData.vlan_id ? props.networkData.vlan_id : ""}
      </Typography>
      {props.container ? (
        props.container.Id && props.container?.State === "running" ? (
          <>
            {props.container.NetworkSettings?.Networks ? (
              <Typography sx={{ fontSize: "18px" }}>
                IPv4:{" "}
                {
                  Object.values(props.container.NetworkSettings.Networks).map(
                    (network) => {
                      try {
                        return network.IPAMConfig.IPv4Address;
                      } catch (err) {
                        return "";
                      }
                    }
                  )[0]
                }
              </Typography>
            ) : null}
          </>
        ) : (
          <>
            <form onSubmit={handleSubmit(handleContainerCreation)}>
              <Box display="flex" flexDirection="column">
                <Controller
                  name="ipv4"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="standard"
                      label="IPv4"
                      size="small"
                      disabled={!props.enabled}
                      placeholder="192.168.1.1"
                      error={errors.ipv4 ? true : false}
                      helperText={errors.ipv4?.message}
                      sx={{ mb: "16px", maxWidth: "250px" }}
                    />
                  )}
                />
                <Controller
                  name="mask"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="standard"
                      label="Mask"
                      size="small"
                      disabled={!props.enabled}
                      placeholder="255.255.255.0"
                      error={errors.mask ? true : false}
                      helperText={errors.mask?.message}
                      sx={{ mb: "16px", maxWidth: "250px" }}
                    />
                  )}
                />
              </Box>
              <Button
                variant="outlined"
                sx={{ borderWidth: "2px", borderRadius: "50px" }}
                disabled={!props.enabled}
                title={
                  !props.networkData.value || !props.networkData.vlan_id
                    ? "The network interface and VLAN ID are not assigned to the user"
                    : ""
                }
                color={
                  !props.networkData.value || !props.networkData.vlan_id
                    ? "error"
                    : "primary"
                }
                type="submit"
              >
                Create connection
              </Button>
            </form>
          </>
        )
      ) : (
        <PulseLoader color={theme.palette.primary.main} />
      )}
    </Box>
  );
};

export default TftpContainer;
