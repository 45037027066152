import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  useTheme,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import useAsyncFeedback from "../../../hooks/useAsyncFeedback";
import { userType } from "../../../redux/userSlice";
import { useApi } from "../../../api/ContextApi";

const steps = [
  {
    id: 1,
    label: "Select account type",
  },
  {
    id: 2,
    label: "Select organization/dc",
  },
  {
    id: 3,
    label: "Done",
  },
];

const StepperOrgDC = (props) => {
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();
  const [handleAsyncOrganizations, { loading }] = useAsyncFeedback();
  const [handleAsyncDC, { loading2 }] = useAsyncFeedback();
  const [secondStep, setSecondStep] = useState(false);
  const [organization, setOrganization] = useState([]);
  const [dataCenter, setDataCenter] = useState([]);
  const accountType = useRef("");
  const userData = useSelector(userType);
  const api = useApi();

  useEffect(() => {
    if (secondStep) {
      if (
        accountType.current === "organization" ||
        (accountType.current === "user" &&
          userData.userType !== "organization_admin")
      )
        handleAsyncOrganizations(
          () => api.getOrganizations(),
          null,
          (data) => {
            setOrganization(data.organizations);
          },
          "loading"
        );
      else if (accountType.current === "dc")
        handleAsyncDC(
          () => api.getDataCenter(),
          null,
          (data) => {
            setDataCenter(data);
          },
          "loading2"
        );
    }
  }, [secondStep]);

  const handleNext = () => {
    if (
      activeStep === 0 &&
      accountType.current === "user" &&
      userData.userType === "organization_admin"
    ) {
      setActiveStep((prevActiveStep) => prevActiveStep + 2);
    } else if (activeStep !== 2)
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return (
    <Stepper
      activeStep={activeStep}
      orientation="vertical"
      sx={{ width: "100%" }}
    >
      {steps.map((step, index) => (
        <Step key={step.label} sx={{ width: "100%" }}>
          <StepLabel>{step.label}</StepLabel>
          <StepContent sx={{ width: "100%" }}>
            {step.id !== 3 ? (
              <>
                <FormControl sx={{ width: "70%", alignItems: "center" }}>
                  <InputLabel id={`select-terminal-${step.id}`}>
                    {step.id === 1 ? "Account type" : "Org/DC"}
                  </InputLabel>
                  {step.id === 1 ? (
                    <Controller
                      name="account_type_id"
                      control={props.control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          label={step.label}
                          variant="standard"
                          fullWidth
                          size="small"
                        >
                          <MenuItem
                            value={4}
                            onClick={() => {
                              accountType.current = "user";
                              setSecondStep(true);
                            }}
                          >
                            Ordinary user
                          </MenuItem>
                          {props.userData.userType !== "organization_admin" ? (
                            <MenuItem
                              value={3}
                              onClick={() => {
                                accountType.current = "organization";
                                setSecondStep(true);
                              }}
                            >
                              Admin of the organization
                            </MenuItem>
                          ) : null}
                          {props.userData.userType === "global_admin" ? (
                            <MenuItem
                              value={2}
                              onClick={() => {
                                accountType.current = "dc";
                                setSecondStep(true);
                              }}
                            >
                              Admin of the data center
                            </MenuItem>
                          ) : null}
                        </Select>
                      )}
                    />
                  ) : accountType.current === "user" &&
                    userData.userType === "organization_admin" ? null : (
                    <Controller
                      name="organization_id"
                      control={props.control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          label={step.label}
                          variant="standard"
                          fullWidth
                          size="small"
                        >
                          {accountType.current === "organization" ||
                          accountType.current === "user" ? (
                            organization.map((org) => {
                              return (
                                <MenuItem value={org.id}>{org.name}</MenuItem>
                              );
                            })
                          ) : accountType.current === "dc" ? (
                            dataCenter.map((dc) => {
                              return (
                                <MenuItem value={dc.id}>{dc.name}</MenuItem>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </Select>
                      )}
                    />
                  )}
                </FormControl>
                <Button
                  variant="outlined"
                  sx={{
                    mt: "10px",
                    ml: "10px",
                    borderRadius: "50px",
                    borderWidth: "2px",
                    ":hover": {
                      borderWidth: "2px",
                      bgcolor: theme.palette.background.main,
                    },
                    bgcolor: theme.palette.background.main,
                  }}
                  onClick={handleNext}
                >
                  Next
                </Button>
              </>
            ) : null}
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
};

export default StepperOrgDC;
