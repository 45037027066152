import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useState } from "react";
import CachedIcon from "@mui/icons-material/Cached";
import "./styles.css";

const DownloadFile = (props) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleReload = () => {
    props.tftpWebSocket.send(
      JSON.stringify({ type: "getFiles", userId: props.userData.id })
    );
  };

  const handleChange = (event) => {
    setSelectedFile(event.target.value);
  };

  const handleDownload = () => {
    props.tftpWebSocket.send(
      JSON.stringify({
        type: "download",
        userId: props.userData.id,
        fileName: selectedFile,
      })
    );
  };

  return (
    <Box>
      <Box className={["row"]}>
        <Typography sx={{ fontSize: "20px" }}>Download file</Typography>
        <IconButton
          disabled={!props.enabled}
          onClick={() => {
            handleReload();
          }}
        >
          <CachedIcon />
        </IconButton>
      </Box>
      <Box className={["row"]}>
        <FormControl>
          <InputLabel size="small" id="select-label-download-file">
            Select file
          </InputLabel>
          <Select
            labelId="select-label-download-file"
            size="small"
            disabled={!props.files}
            id="select-input-download-file"
            value={selectedFile ? selectedFile : ""}
            label="Select file"
            onChange={handleChange}
          >
            {props.files.map((file, index) => {
              return (
                <MenuItem key={index + 1} value={file}>
                  {file}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Button
          disabled={!selectedFile || !props.enabled}
          variant="outlined"
          sx={{ borderWidth: "2px", borderRadius: "50px" }}
          onClick={handleDownload}
        >
          Download
        </Button>
      </Box>
    </Box>
  );
};

export default DownloadFile;
