export const getOrganizationAndDcName = (userData) => {
  try {
    switch (userData.account_type_id) {
      case 1:
        return "Global admin";
      case 2:
        if (userData.data_center) {
          return "Admin DC: " + userData.data_center.name;
        }
        break;
      case 3:
        if (userData.organization) {
          return "Admin Org: " + userData.organization.name;
        }
        break;
      case 4:
        if (userData.organization) {
          return userData.organization.name;
        }
        break;
      case 5:
        if (userData.organization) {
          return userData.organization.name;
        }
      default:
        return "";
    }
  } catch (err) {
    console.log("Error getting organization/dc name: " + err);
    return "";
  }
};
