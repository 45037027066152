import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  useTheme,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { getAvailableToShareTerminals } from "../functions/terminals";

const steps = [
  {
    id: 1,
    label: "Select a Mini-PC",
  },
  {
    id: 2,
    label: "Select a teminal",
  },
  {
    id: 3,
    label: "Add",
  },
];

const StepperTerminal = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [miniPC, setMiniPC] = useState([]);
  const [selectedMiniPCIds, setSelectedMiniPCIds] = useState([]);
  const selectedTerminals = useRef([]);
  const [terminal, setTerminal] = useState([]);
  const [selectedTerminalIds, setSelectedTerminalIds] = useState([]);
  const finalInput = useRef([]);
  const theme = useTheme();

  useEffect(() => {
    if (isOpen) {
      setMiniPC(getAvailableToShareTerminals(props.userPorts, props.userData));
    }
  }, [isOpen]);

  const handleSelectMiniPC = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedMiniPCIds(value);
  };

  const renderMiniPCs = (selectedMiniPCIds) => {
    return selectedMiniPCIds
      .map((id) => miniPC.find((item) => item.id === id).name)
      .join(", ");
  };

  const handleSelectTerminal = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedTerminalIds(value);
  };

  const renderTerminals = (selectedTerminalIds) => {
    console.log(selectedTerminals.current);
    let temp = [];
    selectedTerminalIds.map((id) =>
      selectedTerminals.current.forEach((item) => {
        let temp2 = item.serial_ports.find((item2) => item2.id === id);
        if (temp2) {
          temp.push(temp2.name);
        }
      })
    );
    return temp.join(", ");
  };

  const handleNext = () => {
    if (activeStep === 0) {
    }
    if (activeStep !== 2) setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleDone = () => {
    props.setUserListData(
      props.userListData.map((user) => {
        if (user.id === props.userData.id) {
          finalInput.current.forEach((miniPc, index) => {
            miniPc.serial_ports.forEach((port) => {
              let temp = true;
              user.serial_ports.forEach((port2) => {
                if (port.id === port2.id) temp = false;
              });
              if (temp)
                user.serial_ports.push({
                  id: port.id,
                  name: port.name,
                  pnpId: port.pnpId,
                  serial_number: port.serial_number,
                  added: true,
                  mini_pc_id: miniPc.id,
                  mini_pc: { id: miniPc.id, name: miniPc.name },
                });
            });
          });
          return user;
        } else return user;
      })
    );
    setActiveStep(0);
    setMiniPC([]);
    setTerminal([]);
    setSelectedMiniPCIds([]);
    setSelectedTerminalIds([]);
    selectedTerminals.current = [];
    finalInput.current = [];
    setIsOpen(false);
  };

  return (
    <Accordion
      sx={{
        boxShadow: "none",
        width: "100%",
        m: "0px",
        margin: "auto",
        bgcolor: "transparent",
      }}
      disableGutters={true}
      expanded={isOpen}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <AccordionSummary
          sx={{
            m: 0,
            p: 0,
            my: "-12px",
          }}
        >
          <Button
            variant="outlined"
            sx={{
              borderRadius: "50px",
              borderWidth: "2px",
              ":hover": {
                borderWidth: "2px",
                bgcolor: theme.palette.background.main,
              },
              m: "0px",
              bgcolor: theme.palette.background.main,
            }}
            onClick={() => {
              if (!isOpen) setIsOpen(true);
            }}
          >
            Add new terminal
          </Button>
        </AccordionSummary>
      </Box>
      <AccordionDetails>
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          sx={{ width: "100%" }}
        >
          {steps.map((step, index) => (
            <Step key={step.label} sx={{ width: "100%" }}>
              <StepLabel>{step.label}</StepLabel>
              <StepContent sx={{ width: "100%" }}>
                {step.id !== 3 ? (
                  <>
                    <FormControl sx={{ width: "70%", alignItems: "center" }}>
                      <InputLabel id={`select-terminal-${step.id}`}>
                        {step.id === 1 ? "Mini-PC" : "Terminal"}
                      </InputLabel>
                      <Select
                        labelId={`select-terminal-${step.id}`}
                        value={
                          step.id === 1
                            ? selectedMiniPCIds
                            : selectedTerminalIds
                        }
                        label={`Label ${step.id}`}
                        multiple
                        onChange={
                          step.id === 1
                            ? handleSelectMiniPC
                            : handleSelectTerminal
                        }
                        fullWidth
                        size="small"
                        sx={{
                          bgcolor: theme.palette.background.main,
                          width: "100%",
                        }}
                        renderValue={
                          step.id === 1 ? renderMiniPCs : renderTerminals
                        }
                      >
                        {step.id === 1
                          ? miniPC.map((item) => (
                              <MenuItem
                                key={item.id}
                                value={item.id}
                                onClick={() => {
                                  if (
                                    selectedTerminals.current.includes(item)
                                  ) {
                                    selectedTerminals.current =
                                      selectedTerminals.current.filter(
                                        (item2) => item2.id !== item.id
                                      );
                                  } else {
                                    selectedTerminals.current.push(item);
                                  }
                                }}
                              >
                                {item.name}
                              </MenuItem>
                            ))
                          : selectedTerminals.current.map((item, index) => {
                              return item.serial_ports.map((item2) => (
                                <MenuItem
                                  key={item2.id}
                                  value={item2.id}
                                  onClick={() => {
                                    if (finalInput.current[index]) {
                                      let temp = true;
                                      finalInput.current[index].serial_ports =
                                        finalInput.current[
                                          index
                                        ].serial_ports.filter((item3) => {
                                          if (item3.id === item2.id) {
                                            temp = false;
                                            return false;
                                          }
                                          return true;
                                        });
                                      if (temp)
                                        finalInput.current[
                                          index
                                        ].serial_ports.push(item2);
                                    } else {
                                      finalInput.current[index] = {
                                        id: item.id,
                                        name: item.name,
                                        serial_ports: [item2],
                                      };
                                    }
                                  }}
                                >
                                  {item2.name ? item2.name : item2.pnpId}
                                </MenuItem>
                              ));
                            })}
                      </Select>
                    </FormControl>
                    <Button
                      variant="outlined"
                      sx={{
                        mt: "10px",
                        ml: "10px",
                        borderRadius: "50px",
                        borderWidth: "2px",
                        ":hover": {
                          borderWidth: "2px",
                          bgcolor: theme.palette.background.main,
                        },
                        bgcolor: theme.palette.background.main,
                      }}
                      onClick={handleNext}
                    >
                      Next
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="outlined"
                    sx={{
                      borderRadius: "50px",
                      borderWidth: "2px",
                      ":hover": {
                        borderWidth: "2px",
                        bgcolor: theme.palette.background.main,
                      },
                      bgcolor: theme.palette.background.main,
                    }}
                    onClick={handleDone}
                  >
                    Add
                  </Button>
                )}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </AccordionDetails>
    </Accordion>
  );
};

export default StepperTerminal;
