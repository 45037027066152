import { Box, Button, Input, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { BarLoader } from "react-spinners";
import { useApi } from "../../api/ContextApi";

const UploadFile = (props) => {
  const [file, setFile] = useState(null);
  let theme = useTheme();
  const api = useApi();

  const onFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  useEffect(() => {
    console.log(22222);
  }, [props.uploadSpinner]);

  const onFileUpload = async () => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file.name);
    props.setUploadSpinner(true);
    api.uploadFile(formData).then((response) => {
      console.info(response);
      if (response?.data?.fileId) {
        setFile(null);
        props.setFiles((prev) => prev.concat(file.name));
        props.tftpWebSocket.send(
          JSON.stringify({
            type: "upload",
            fileId: response.data.fileId,
            userId: props.userData.id,
            fileName: file.name,
          })
        );
      } else {
        props.setUploadSpinner(false);
      }
    });
  };

  return (
    <Box alignItems="center">
      <Typography sx={{ marginTop: "26px", fontSize: "20px" }}>
        Upload file
      </Typography>
      <Input onChange={onFileChange} type="file" disabled={!props.enabled} />
      <Button
        variant="outlined"
        disabled={!file || !props.enabled}
        sx={{ borderWidth: "2px", borderRadius: "50px" }}
        onClick={onFileUpload}
      >
        Upload
      </Button>
      {props.uploadSpinner ? (
        <BarLoader color={theme.palette.primary.main} />
      ) : null}
    </Box>
  );
};

export default UploadFile;
