import {
  Box,
  Button,
  Container,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  APIKEYLISTMANAGEMENT,
  DATACENTERLISTMANAGEMENT,
  ORGANIZATIONLISTMANAGEMENT,
  ROOT,
  USERLISTMANAGEMENT,
} from "../../navigation/CONSTANTS";
import { useState } from "react";
import OrganizationList from "./organizations";
import DataCenterList from "./dataCenter";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/userSlice";
import UserList from "./users";
import { getUserManagementTitle } from "./functions/userManagement";
import ApiKeyList from "./apiKeys";

const UserManagement = (props) => {
  const userData = useSelector(selectUser);
  const navigate = useNavigate();
  const theme = useTheme();
  const [addItem, setAddItem] = useState(false);
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const handleAddItem = () => {
    setAddItem((prevValue) => {
      return !prevValue;
    });
  };

  return (
    <>
      <Container maxWidth="xl" sx={{ mt: "10px", mb: "25px" }}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h4">User management</Typography>
          <Button
            variant="contained"
            sx={{ borderWidth: "2px", borderRadius: "50px" }}
            onClick={() => {
              navigate(ROOT);
            }}
          >
            Home page
          </Button>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          my="10px"
        >
          <Typography variant="h6" color={theme.palette.primary.main}>
            {getUserManagementTitle(
              props.variant,
              params.get("organizationName")
            )}
          </Typography>
          <Box
            component="div"
            id="user-management-users"
            display="flex"
            justifyContent="right"
            alignItems="center"
          >
            <Tooltip
              title={
                props.variant === "api-key"
                  ? "Add API key"
                  : props.variant === "users"
                  ? "Add user"
                  : props.variant === "org"
                  ? "Add organization"
                  : "Add data center"
              }
            >
              <IconButton
                onClick={handleAddItem}
                disabled={
                  props.variant && props.variant === "api-key" && addItem
                }
                sx={{
                  color: theme.palette.primary.main,
                  borderColor:
                    props.variant && props.variant === "api-key" && addItem
                      ? "grey"
                      : theme.palette.primary.main,
                  borderWidth: "2px",
                  borderStyle: "solid",
                  mx: "16px",
                }}
              >
                <AddIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            {(userData.userType === "dc_admin" ||
              userData.userType === "global_admin") &&
            props.variant !== "api-key" ? (
              <Button
                variant="outlined"
                sx={{
                  ml: "10px",
                  borderWidth: "2px",
                  borderRadius: "50px",
                  ":hover": { borderWidth: "2px" },
                }}
                onClick={() => {
                  navigate(APIKEYLISTMANAGEMENT);
                }}
              >
                API keys
              </Button>
            ) : null}
            {userData.userType === "global_admin" && props.variant !== "dc" ? (
              <Button
                variant="outlined"
                sx={{
                  ml: "10px",
                  borderWidth: "2px",
                  borderRadius: "50px",
                  ":hover": { borderWidth: "2px" },
                }}
                onClick={() => {
                  navigate(DATACENTERLISTMANAGEMENT);
                }}
              >
                Data centers
              </Button>
            ) : null}
            {(userData.userType === "dc_admin" ||
              userData.userType === "global_admin") &&
            props.variant !== "org" ? (
              <Button
                variant="outlined"
                sx={{
                  ml: "10px",
                  borderWidth: "2px",
                  borderRadius: "50px",
                  ":hover": { borderWidth: "2px" },
                }}
                onClick={() => {
                  navigate(ORGANIZATIONLISTMANAGEMENT);
                }}
              >
                Organizations
              </Button>
            ) : null}
            {props.variant !== "users" || params.get("organizationId") ? (
              <Button
                variant="outlined"
                sx={{
                  ml: "10px",
                  borderWidth: "2px",
                  borderRadius: "50px",
                  ":hover": { borderWidth: "2px" },
                }}
                onClick={() => {
                  navigate(USERLISTMANAGEMENT);
                }}
              >
                Users
              </Button>
            ) : null}
          </Box>
        </Box>
        {props.variant === "api-key" ? (
          <ApiKeyList addItem={addItem} />
        ) : props.variant === "users" ? (
          <UserList addItem={addItem} />
        ) : props.variant === "org" ? (
          <OrganizationList addItem={addItem} />
        ) : (
          <DataCenterList addItem={addItem} />
        )}
      </Container>
    </>
  );
};

export default UserManagement;
