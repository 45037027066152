import { Box, Grid, List, Typography, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAsyncFeedback from "../../../hooks/useAsyncFeedback";
import { insertToArray } from "../../../utils/functions";
import SingleDataCenter from "./singleDataCenter";
import { useApi } from "../../../api/ContextApi";

const DataCenterList = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [handbrake, setHandBrake] = useState(true);
  const [dataCenterData, setDataCenterData] = useState([]);
  const [latestVersion, setLatestVersion] = useState("");
  const [indexKey, setIndexKey] = useState(0);
  const [handleAsyncDC, { loading }] = useAsyncFeedback();
  const freePricingPlan = useRef(null);
  const api = useApi();

  useEffect(() => {
    handleAsyncDC(
      () => api.getDataCenterFullInfo(),
      null,
      (data) => {
        setDataCenterData(data.dataCenters);
        setLatestVersion(data.latestVersion);
        freePricingPlan.current = data.freePricingPlan;
      },
      "loading"
    );
  }, []);

  useEffect(() => {
    if (handbrake) {
      setHandBrake(false);
    } else {
      setDataCenterData(
        insertToArray(dataCenterData, 0, {
          id: indexKey,
          name: "",
          dc_admin_id: "",
          VAT_number: "",
          phone: "",
          address: "",
          email_tech: "",
          email_pay: "",
          identificator: "",
          accounts: [],
          edit: true,
          draft: true,
        })
      );
      setIndexKey(indexKey + 1);
    }
  }, [props.addItem]);

  return (
    <>
      <Box component="div">
        <Grid container spacing={2} sx={{ px: "16px" }}>
          <Grid item xs={4}>
            <Typography>Data center name</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography>Admin name</Typography>
          </Grid>
        </Grid>
        <List>
          {dataCenterData.map((value, index) => (
            <SingleDataCenter
              key={value.id}
              data={value}
              index={index}
              setDataCenterData={setDataCenterData}
              dataCenterData={dataCenterData}
              latestVersion={latestVersion}
              freePricingPlan={freePricingPlan.current}
            />
          ))}
        </List>
      </Box>
    </>
  );
};

export default DataCenterList;
