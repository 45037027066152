import {
  Box,
  Divider,
  Grid,
  IconButton,
  Input,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import SaveIcon from "@mui/icons-material/Save";
import { useDispatch, useSelector } from "react-redux";
import {
  selectorProfile,
  selectorProfileAdminDC,
  selectorProfileGlobalAdmin,
  updateProfile,
  updateProfileAdminDC,
  userType,
} from "../../../redux/userSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import useAsyncFeedback from "../../../hooks/useAsyncFeedback";
import PricingPlanInfo from "./pricingPlanInfo";
import Usage from "./usage";
import { useApi } from "../../../api/ContextApi";

const PersonalPricingPlan = () => {
  const userData = useSelector(userType);
  const [usage, setUsage] = useState(null);
  const [type, setType] = useState(null);
  const [pricingPlan, setPricingPlan] = useState(null);
  const [handleAsyncPlan, { loading }] = useAsyncFeedback();
  const api = useApi();

  useEffect(() => {
    handleAsyncPlan(
      () => api.getPricingPlan(),
      null,
      (data) => {
        console.log(data);
        if (data.type) {
          setType(data.type);
          if (data.type === "FREE") setPricingPlan(data.pricingPlan);
          else setUsage(data.usage);
        }
      },
      "loading"
    );
  }, []);

  const validationSchema = Yup.object().shape({});

  const defaultValues = {};

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  useEffect(() => {
    if (isSubmitSuccessful) {
    }
  }, [isSubmitSuccessful, reset]);

  const theme = useTheme();
  const [edit, setEdit] = useState();

  const handleSaveButton = (data) => {};

  if (userData.userType !== "dc_admin" || !type) {
    return <></>;
  } else if (type === "FREE") {
    return <></>;
  } else {
    return (
      <>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box
            sx={{
              mb: "10px",
              mx: "10px",
              p: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              height: "fit-content",
              borderStyle: "solid",
              borderWidth: "2px",
              borderColor: theme.palette.primary.main,
              borderRadius: "15px",
              width: "25%",
            }}
          >
            <PricingPlanInfo plan={usage.pricingPlan} />
          </Box>
          <Box
            sx={{
              mb: "10px",
              mx: "10px",
              p: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              height: "fit-content",
              borderStyle: "solid",
              borderWidth: "2px",
              borderColor: theme.palette.primary.main,
              borderRadius: "15px",
              width: "70%",
            }}
          >
            <Usage usage={usage} />
          </Box>
        </Box>
      </>
    );
  }
};

export default PersonalPricingPlan;
