export const insertToArray = (arr, index, ...newItems) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted items
  ...newItems,
  // part of the array after the specified index
  ...arr.slice(index),
];

export const filterByVersion = (version, filterFrom, filterTo) => {
  let versionArray = version.split(".");
  let filterFromArray = filterFrom.split(".");
  let filterToArray = filterTo.split(".");
  let done1 = false;
  let done2 = false;
  let filtered = true;
  versionArray.forEach((item, index) => {
    if (!done1) {
      if (!isNaN(parseInt(filterToArray[index]))) {
        if (parseInt(item) > parseInt(filterToArray[index])) {
          done1 = true;
          filtered = false;
        } else if (parseInt(item) < parseInt(filterToArray[index])) {
          done1 = true;
        }
      }
    }
    if (!done2) {
      if (!isNaN(parseInt(filterFromArray[index]))) {
        if (parseInt(item) < parseInt(filterFromArray[index])) {
          done2 = true;
          filtered = false;
        } else if (parseInt(item) > parseInt(filterFromArray[index])) {
          done2 = true;
        }
      }
    }
  });
  return filtered;
};

export const currencyToText = (price, currency) => {
  switch (currency) {
    case "EUR":
      return `\u20AC${price}`;
    case "PLN":
      return `${price}zł`;
    case "USD":
      return `$${price}`;
    default:
      return "";
  }
};

export const tftpOnMessage = async (
  data,
  setIsTFTPEnabled,
  setContainer,
  wsTFTP,
  userId,
  setFiles,
  uploadSpinner,
  setUploadSpinner,
  api
) => {
  try {
    switch (data.type) {
      case "open":
        setIsTFTPEnabled(true);
        wsTFTP.send(JSON.stringify({ type: "getContainer", userId: userId }));
        wsTFTP.send(JSON.stringify({ type: "getFiles", userId: userId }));
        break;
      case "getContainer":
        if (data.container) {
          setContainer(data.container);
        } else {
          setContainer({});
        }
        break;
      case "createContainer":
        if (data.result) {
          setContainer(data.result);
        } else {
          setContainer({});
        }
        break;
      case "getFiles":
        setFiles(data.data);
        break;
      case "download":
        data.satus === "error" ?? console.log(data.message);
        api.downloadFile(data.fileId, data.fileName).then(async (response) => {
          if (response.status !== 200) return null;
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });

          const fileURL = URL.createObjectURL(blob);

          const link = document.createElement("a");
          link.href = fileURL;
          link.setAttribute("download", data.fileName);
          document.body.appendChild(link);

          link.click();
        });
        break;
      case "uploadResult":
        setUploadSpinner(false);
        if (!data.data.done) {
          alert("Failed to save file.");
        }
        break;
      default:
        console.warn(
          "The type of message received from the connection via TFTP is not supported:" +
            data.type
        );
        break;
    }
  } catch (err) {
    console.log("Error in reading message from TFTP connection ");
  }
};
