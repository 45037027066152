import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useRef, useState } from "react";
import useAsyncFeedback from "../../hooks/useAsyncFeedback";
import { BeatLoader } from "react-spinners";
import { useApi } from "../../api/ContextApi";

const SingleConsoleHistory = (props) => {
  const theme = useTheme();
  const [handleAsyncHistory, { loading }] = useAsyncFeedback();
  const [data, setData] = useState(null);
  const [history, setHistory] = useState("");
  const [load, setLoading] = useState(false);
  const [downloadRequest, setDownloadRequest] = useState(false);
  const disableDownloadButton = useRef(false);
  const api = useApi();

  useEffect(() => {
    if (data === null && (load || downloadRequest)) {
      handleAsyncHistory(
        () =>
          api.getSingleConsoleHistory(
            props.miniPcId,
            props.serialPortId,
            props.data.Key
          ),
        null,
        (response) => {
          try {
            let content = "";
            if (response && response.Body && response.Body.type === "Buffer") {
              const buf = new Uint8Array(response.Body.data);
              const decoder = new TextDecoder("utf-8");
              content = decoder.decode(buf);
            }
            setHistory(JSON.parse(content).message);
            setData(content);
            if (downloadRequest) {
              handleDownload(content);
            }
          } catch (err) {
            console.log("Error:\n" + err);
          }
        },
        "loading"
      );
    }
  }, [load, downloadRequest]);

  const handleDownload = (file) => {
    try {
      disableDownloadButton.current = true;
      if (file) {
        const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
          file
        )}`;
        const link = document.createElement("a");
        link.href = jsonString;
        link.download = `${props.serialPortName}-${props.pnpId}.json`;

        link.click();
        disableDownloadButton.current = false;
      } else {
        setDownloadRequest(true);
      }
    } catch (err) {
      console.log("Error:\n" + err);
    }
  };

  const getEmail = (str) => {
    const parts = str.split(" - ");

    if (parts.length > 1) {
      const email = parts[parts.length - 1].replace(".json", "");
      return ` - ${email}`;
    } else {
      return "";
    }
  };

  return (
    <Box
      width="100%"
      sx={{
        backgroundColor: "transparent",
      }}
    >
      <Accordion
        disableGutters
        sx={{
          width: "100%",
          boxShadow: "none",
          backgroundColor: "transparent",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={() => setLoading(!load)}
          sx={{ width: "100%", flexDirection: "row-reverse" }}
        >
          <Box
            display="flex"
            flexDirection="row"
            width={"100%"}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>
              {props.data.LastModified + getEmail(props.data.Key)}
            </Typography>
            <div
              onClick={(event) => event.stopPropagation()}
              style={{
                display: "flex",
                justifyContent: "right",
                alignItems: "center",
              }}
            >
              <Button
                disabled={disableDownloadButton.current}
                variant="contained"
                onClick={() => {
                  handleDownload(data);
                }}
                sx={{ borderRadius: "50px" }}
              >
                Download file
              </Button>
            </div>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {loading ? (
            <BeatLoader color={theme.palette.primary.main} />
          ) : (
            <Box
              style={{
                backgroundColor: "#00393D",
                color: theme.palette.background.main,
                width: "100%",
                maxHeight: "600px",
                overflow: "scroll",
                padding: "10px",
              }}
            >
              {
                <pre
                  dangerouslySetInnerHTML={{
                    __html: history,
                  }}
                />
              }
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default SingleConsoleHistory;
