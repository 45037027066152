import { Box, Grid, List, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { insertToArray } from "../../../utils/functions";
import useAsyncFeedback from "../../../hooks/useAsyncFeedback";
import SingleOrganization from "./singleOrganization";
import { useApi } from "../../../api/ContextApi";

const OrganizationList = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [handbrake, setHandBrake] = useState(true);
  const [organizationData, setOrganizationData] = useState([]);
  const [indexKey, setIndexKey] = useState(0);
  const [handleAsyncOrg, { loading }] = useAsyncFeedback();
  const api = useApi();

  useEffect(() => {
    handleAsyncOrg(
      () => api.getOrganizations(),
      null,
      (data) => {
        setOrganizationData(data.organizations);
      },
      "loading"
    );
  }, []);

  useEffect(() => {
    if (handbrake) {
      setHandBrake(false);
    } else {
      setOrganizationData(
        insertToArray(organizationData, 0, {
          id: indexKey,
          name: "",
          account: "",
          description: "",
          edit: true,
          draft: true,
        })
      );
      setIndexKey(indexKey + 1);
    }
  }, [props.addItem]);

  return (
    <>
      <Box component="div">
        <Grid container spacing={2} sx={{ px: "16px" }}>
          <Grid item xs={3}>
            <Typography>Organization name</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>Admin</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>Description</Typography>
          </Grid>
        </Grid>
        <List>
          {organizationData.map((value, index) => (
            <SingleOrganization
              key={value.id}
              data={value}
              index={index}
              setOrganizationData={setOrganizationData}
              organizationData={organizationData}
            />
          ))}
        </List>
      </Box>
    </>
  );
};

export default OrganizationList;
