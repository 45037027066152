const config = {
  WEBSOCKET_SERVER_URL:
    process.env.NODE_ENV === "production"
      ? window.ENV.REACT_APP_WEBSOCKET_API_URL
        ? window.ENV.REACT_APP_WEBSOCKET_API_URL
        : "wss://beta-backend.portharbor.io/xtermjs"
      : process.env.REACT_APP_WEBSOCKET_API_URL
      ? process.env.REACT_APP_WEBSOCKET_API_URL
      : "ws://192.168.1.112:8080/xtermjs",
  NOTIFICATION_WEBSOCKET_URL:
    process.env.NODE_ENV === "production"
      ? window.ENV.REACT_APP_WEBSOCKET_NOTIFICATION_API_URL
        ? window.ENV.REACT_APP_WEBSOCKET_NOTIFICATION_API_URL
        : "wss://beta-backend.portharbor.io/notifications"
      : process.env.REACT_APP_WEBSOCKET_NOTIFICATION_API_URL
      ? process.env.REACT_APP_WEBSOCKET_NOTIFICATION_API_URL
      : "ws://192.168.1.112:8080/notifications",
  API_URL:
    process.env.NODE_ENV === "production"
      ? window.ENV.REACT_APP_API_URL
        ? window.ENV.REACT_APP_API_URL
        : "https://beta-backend.portharbor.io/api/v1"
      : process.env.REACT_APP_API_URL
      ? process.env.REACT_APP_API_URL
      : "http://192.168.1.112:8080/api/v1",
  SERVER_SECRET_KEY:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjY1OTYyMzkwMjJ9.o0qSS_hu4RvYoIsvNsaS4-OfZtmeAqwGOn0tMi3Jc38",
  TEST_API_URL:
    process.env.NODE_ENV === "production"
      ? window.ENV.REACT_TEST_API_URL
        ? window.ENV.REACT_TEST_API_URL
        : "https://beta-backend.portharbor.io/api/v1"
      : process.env.REACT_TEST_API_URL
      ? process.env.REACT_TEST_API_URL
      : "http://192.168.1.112:8080/api/v1",
};

export default config;
