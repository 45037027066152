import {
  Box,
  Modal,
  Button,
  Container,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROOT } from "../../navigation/CONSTANTS";
import GeneralInformations from "./generalInformations";
import useAsyncFeedback from "../../hooks/useAsyncFeedback";
import { useDispatch, useSelector } from "react-redux";
import {
  selectorProfile,
  selectorProfileAdminDC,
  selectorProfileGlobalAdmin,
  setProfileData,
  setProfileDataAdminDC,
  setProfileDataGlobalAdmin,
  setProfileDataFreeUser,
  userType,
} from "../../redux/userSlice";
import ChangePasswordComponent from "./changePassword";
import { useAuth } from "../../navigation/authorization/ProvideAuth";
import TwoFactorAuthentication from "./twoFactorAuthentication";
import { useApi } from "../../api/ContextApi";

const Profile = () => {
  const navigate = useNavigate();
  const [handleAsyncProfile, { loading }] = useAsyncFeedback();
  const dispatch = useDispatch();
  const userTypeData = useSelector(userType);
  const userData = useSelector(
    userTypeData.userType === "global_admin"
      ? selectorProfileGlobalAdmin
      : userTypeData.userType === "dc_admin"
      ? selectorProfileAdminDC
      : selectorProfile
  );
  const auth = useAuth();
  const [user2FASecret, setUser2FASecret] = useState({
    url: null,
    secret: null,
    algorithm: null,
  });
  const [twoFA, setTwoFA] = useState(false);
  const theme = useTheme();
  const [modal, setModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const api = useApi();

  useEffect(() => {
    handleAsyncProfile(
      () =>
        api.getProfileData().then((res) => {
          console.log(res);
          return res;
        }),
      null,
      (data) => {
        console.log(data);
        setTwoFA(data.twoFA);
        dispatch(
          userTypeData.userType === "global_admin"
            ? setProfileDataGlobalAdmin({
                name: data.name,
                email: data.email,
                organization: data.organization,
              })
            : userTypeData.userType === "dc_admin"
            ? setProfileDataAdminDC({
                name: data.name,
                email: data.email,
                data_center: data.data_center,
                adminName: data.adminName,
                adminEmail: data.adminEmail,
                VAT_number: data.VAT_number,
                phone: data.phone,
                address: data.address,
                email_tech: data.email_tech,
                email_pay: data.email_pay,
                identificator: data.identificator,
              })
            : userTypeData.userType === "free_user"
            ? setProfileDataFreeUser({
                name: data.name,
                email: data.email,
                organization: data.organization,
                adminName: data.adminName,
                adminEmail: data.adminEmail,
                apiKey: data.apiKey,
                dataCenterIdentificator: data.dataCenterIdentificator,
              })
            : setProfileData({
                name: data.name,
                email: data.email,
                organization: data.organization,
                adminName: data.adminName,
                adminEmail: data.adminEmail,
              })
        );
        setUser2FASecret({
          url: data.url,
          secret: data.secret,
          algorithm: data.algorithm,
        });
      },
      "loading"
    );
  }, []);

  const handleBackToHomePage = () => {
    navigate(ROOT);
  };

  const handleDeleteButton = () => {
    setIsDeleting(true);
    api.deleteUser(userData.id).then((res) => {
      if (res.status === 200) {
        auth.signout();
        window.location.reload(false);
      }
    });
  };

  if (loading) return <></>;

  return (
    <>
      <Container
        maxWidth="xl"
        sx={{
          mx: "auto",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography m="10px" variant="h4">
          User profile:
        </Typography>
        <Button
          variant="contained"
          onClick={handleBackToHomePage}
          sx={{ borderRadius: "50px" }}
        >
          Home page
        </Button>
      </Container>
      <Container
        maxWidth="xl"
        sx={{ mx: "auto", display: "flex", flexDirection: "column" }}
      >
        <Box display={"flex"}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mx: "10px",
              width: "50%",
            }}
          >
            <GeneralInformations />
          </Box>
          <Box sx={{ width: "50%" }}>
            <Box sx={{ height: "32px" }} />
            <Box>
              <ChangePasswordComponent />
              {twoFA ? (
                <TwoFactorAuthentication
                  secret={user2FASecret}
                  setSecret={setUser2FASecret}
                />
              ) : null}
              {userTypeData.userType === "global_admin" ? null : (
                <>
                  <Box
                    height={"52px"}
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                  >
                    <Button
                      variant="outlined"
                      sx={{
                        borderRadius: "50px",
                        borderWidth: "2px",
                        ":hover": { borderWidth: "2px" },
                        mx: "16px",
                        width: "250px",
                        mb: "2px",
                      }}
                      onClick={() => setModal(true)}
                    >
                      Delete account
                    </Button>
                  </Box>
                  <Modal
                    open={modal}
                    onClose={() => setModal(false)}
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "white",
                        padding: "20px",
                        borderRadius: "20px",
                        marginBottom: "15px",
                        maxWidth: "600px",
                      }}
                    >
                      <Typography
                        id="modal-modal-title"
                        sx={{ textAlign: "center" }}
                      >
                        Delete account
                      </Typography>
                      <Typography
                        className="modal-modal-description"
                        sx={{ marginBottom: "15px" }}
                      >
                        Are you sure you want to delete your{"("}
                        <span style={{ color: theme.palette.primary.main }}>
                          {userData.name}
                        </span>
                        {") "}
                        account?
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          disabled={isDeleting}
                          variant="outlined"
                          onClick={() => handleDeleteButton()}
                          sx={{ borderRadius: "10px", borderWidth: "2px" }}
                        >
                          Delete
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={() => setModal(false)}
                          sx={{ borderRadius: "10px", borderWidth: "2px" }}
                        >
                          Cancel
                        </Button>
                      </Box>
                    </Box>
                  </Modal>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default Profile;
