import { Box, Container, useTheme } from "@mui/material";
import images from "../assets";
import { useNavigate } from "react-router-dom";
import { ROOT } from "../navigation/CONSTANTS";
import Copyright from "./Copyright";

const Footer = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleHomePageNavigation = () => {
    navigate(ROOT);
  };

  return (
    <>
      <Box
        component="footer"
        sx={{
          py: "5px",
          px: 2,
          mt: "auto",
          backgroundColor: theme.palette.primary.main,
          width: "100%",
        }}
      >
        <Container
          maxWidth="md"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box
              component="img"
              src={images.logoHorizontalWhite}
              sx={{
                height: "50px",
                width: "200px",
                objectFit: "cover",
                cursor: "pointer",
              }}
              onClick={handleHomePageNavigation}
            />
          </Box>
          <Copyright color="text.opposite" />
        </Container>
      </Box>
    </>
  );
};

export default Footer;
