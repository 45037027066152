import { useEffect, useState } from "react";
import useAsyncFeedback from "../../hooks/useAsyncFeedback";
import { useApi } from "../../api/ContextApi";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROOT } from "../../navigation/CONSTANTS";

const Status = () => {
  const [handleAsyncServerStatus, { loading }] = useAsyncFeedback();
  const api = useApi();
  const [status, setStatus] = useState({ server: false, database: false });
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    handleAsyncServerStatus(
      () => api.serverStatus(),
      null,
      (data) => {
        setStatus({ server: true, database: data.databaseStatus });
      },
      "loading"
    );
  }, []);

  const handleButton = () => {
    navigate(ROOT);
  };

  if (loading) {
    return <Box></Box>;
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: "40%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "10px",
            border: `solid 2px ${theme.palette.primary.main}`,
            borderRadius: "15px",
            alignItems: "center",
            minWidth: "300px",
            marginBottom: "40px",
          }}
        >
          <Typography sx={{ justifyContent: "center", alignItems: "center" }}>
            Server status: {status.server ? "online" : "offline"}
          </Typography>
          <Typography sx={{ justifyContent: "center", alignItems: "center" }}>
            Database status: {status.database ? "connected" : "disconnected"}
          </Typography>
        </Box>
        <Button
          variant="outlined"
          onClick={handleButton}
          sx={{ borderWidth: "2px", borderRadius: "15px" }}
        >
          Home page
        </Button>
      </Box>
    </Container>
  );
};

export default Status;
