import {
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SingleSerialPort from "./singleSerialPort";

const SingleMiniPC = (props) => {
  let theme = useTheme();
  const [visible, setVisible] = useState(false);
  const [serialPorts, setSerialPorts] = useState(props.serialPorts);

  useEffect(() => {
    setSerialPorts(props.serialPorts);
  }, [props.serialPorts]);

  return (
    <>
      {props.index === 0 ? null : (
        <Divider
          sx={{
            bgcolor: theme.palette.primary.main,
            borderBottomWidth: "2px",
          }}
        />
      )}
      <ListItem sx={{ px: "0px" }}>
        <Box width={"100%"}>
          <Box width={"100%"} mb={visible ? "8px" : "0px"}>
            <Grid container>
              <Grid
                item
                xs={10}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography>{props.miniPC.name}</Typography>
              </Grid>
              <Grid
                item
                xs={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <IconButton
                  id={"expandButton" + props.miniPC.id}
                  onClick={() => {
                    setVisible(!visible);
                  }}
                >
                  {visible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Grid>
            </Grid>
          </Box>
          {visible ? (
            <List sx={{ pt: "0px", pl: "20px" }}>
              {serialPorts.map((value2, index) => {
                return (
                  <SingleSerialPort
                    key={value2.id}
                    data={value2}
                    miniPCId={props.miniPC.id}
                    index={index}
                    filterBy={props.filterBy}
                  />
                );
              })}
            </List>
          ) : null}
        </Box>
      </ListItem>
    </>
  );
};

export default SingleMiniPC;
