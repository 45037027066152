import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Controller, useForm } from "react-hook-form";
import { filterByVersion } from "../../utils/functions";

const SearchComponent = (props) => {
  const { control, handleSubmit, reset, getValues } = useForm({
    defaultValues: {
      name: "",
      status: "all",
      from: "",
      to: "",
    },
  });
  let theme = useTheme();

  const handleChange = (data) => {
    let miniPCList = props.list;
    miniPCList = miniPCList.filter(
      (item) =>
        (data.name === ""
          ? true
          : item.name.toLowerCase().includes(data.name.toLowerCase())) &&
        (data.status === "all" ? true : item.status === data.status) &&
        filterByVersion(item.software_version, data.from, data.to)
    );
    props.onSubmit(miniPCList);
  };

  const handleReset = () => {
    reset();
    props.onSubmit(props.list);
  };

  return (
    <Box
      id="terminal-list-search-container"
      sx={{
        m: "10px",
        p: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        height: "auto",
        borderStyle: "solid",
        borderWidth: "2px",
        borderColor: theme.palette.primary.main,
        borderRadius: "15px",
      }}
    >
      <Accordion
        sx={{ width: "100%", boxShadow: "none" }}
        disableGutters={true}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <AccordionSummary
            expandIcon={<FilterListIcon />}
            aria-controls={"filter"}
            id={"filter"}
            sx={{
              flexDirection: "row-reverse",
              p: "0px",
              alignItems: "center",
              mr: "15px",
            }}
          ></AccordionSummary>
          <Typography mr="20px">Name:</Typography>
          <Box sx={{ mr: "20%", width: "100%" }}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="text"
                  disableUnderline={false}
                  sx={{
                    p: "0px !important",
                    "& .MuiInputBase-input.MuiInput-input": {
                      WebkitTextFillColor: "black",
                      p: "0px",
                    },
                  }}
                  fullWidth={true}
                  onChange={(e) => {
                    field.onChange(e);
                    handleChange(getValues());
                  }}
                />
              )}
            />
          </Box>
          <Button
            variant="outlined"
            sx={{
              borderRadius: "50px",
              borderWidth: "2px",
              width: "200px",
              ml: "auto",
              alignItems: "center",
            }}
            onClick={handleSubmit(handleReset)}
          >
            Reset
          </Button>
        </Box>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={4}>
              <InputLabel id="filter-status-select">Status</InputLabel>
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="filter-status-select-label"
                    id="filter-status-select"
                    size="small"
                    sx={{ mb: "15px", width: "60%", minWidth: "100px" }}
                    onChange={(e) => {
                      field.onChange(e);
                      handleChange(getValues());
                    }}
                  >
                    <MenuItem value={"all"}>all</MenuItem>
                    <MenuItem value={"online"}>online</MenuItem>
                    <MenuItem value={"offline"}>offline</MenuItem>
                  </Select>
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel id="filter-version">Version</InputLabel>
              <Box display="flex">
                <Controller
                  name="from"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="filter-version-from"
                      label="from"
                      variant="standard"
                      sx={{ mr: "10px" }}
                      onChange={(e) => {
                        field.onChange(e);
                        handleChange(getValues());
                      }}
                    />
                  )}
                />
                <Controller
                  name="to"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="filter-version-to"
                      label="to"
                      variant="standard"
                      onChange={(e) => {
                        field.onChange(e);
                        handleChange(getValues());
                      }}
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default SearchComponent;
