import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useApi } from "../../../api/ContextApi";

const Usage = (props) => {
  const theme = useTheme();
  const [isEdited, setIsEdited] = useState(false);
  const api = useApi();

  const validationSchema = Yup.object().shape({
    podsLimit: Yup.number("Value must be a number")
      .transform((value) => (isNaN(value) || value === "" ? 0 : value))
      .integer("Number must be an integer")
      .min(
        props.usage.pods,
        "Number must be at least equal to the current number of PODs"
      )
      .max(2147483647, "Number is too big")
      .required("POD limit is required"),
    connectorsLimit: Yup.number("Value must be a number")
      .transform((value) => (isNaN(value) || value === "" ? 0 : value))
      .integer("Number must be an integer")
      .min(
        props.usage.connectors,
        "Number must be at least equal to the current number of connectors"
      )
      .max(
        props.usage.pricingPlan.maxConnectors,
        "Number must be at most equal to the pricing plan limit"
      )
      .required("Connectors limit is required"),
    limitUsers: Yup.number("Value must be a number")
      .transform((value) => (isNaN(value) || value === "" ? 0 : value))
      .integer("Number must be an integer")
      .min(
        props.usage.users,
        "Number must be at least equal to the current number of users"
      )
      .max(2147483647, "Number is too big")
      .required("Users limit is required"),
  });

  const defaultValues = {
    podsLimit: props.usage.podsLimit ? props.usage.podsLimit : 0,
    connectorsLimit: props.usage.connectorsLimit
      ? props.usage.connectorsLimit
      : 0,
    limitUsers: props.usage.limitUsers ? props.usage.limitUsers : 0,
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    reset,
    setValue,
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const handleButton = (data) => {
    console.log(data);
    api
      .updateDcPricingPlan(
        props.usage.id,
        data.podsLimit,
        data.connectorsLimit,
        data.limitUsers
      )
      .then((response) => {
        if (response.status === 200) {
          setIsEdited(false);
          window.location.reload(false);
        } else if (response.status === 404) {
          reset();
          alert("Pricing plan for your DC not found!");
        } else if (response.status === 403) {
          reset();
          alert("Not allowed");
        } else {
          reset();
          alert("Something went wrong. Try later!");
        }
      });
  };

  return (
    <Box>
      <Box>
        <Typography
          variant="h5"
          height="auto"
          textAlign="center"
          lineHeight="40px"
        >{`Plan - ${props.usage.pricingPlan.name}`}</Typography>
      </Box>
      <Box>
        <Box sx={{ minHeight: "200px" }}>
          <Grid container columnSpacing={2} paddingTop={2}>
            <Grid item xs={9}>
              <Grid
                container
                columnSpacing={2}
                minHeight="50px"
                alignContent="center"
              >
                <Grid item xs={4}>
                  Features
                </Grid>
                <Grid item xs={4}>
                  Usage
                </Grid>
                <Grid item xs={4}>
                  Limit
                </Grid>
              </Grid>
              <Divider
                sx={{
                  borderBottomWidth: "1px",
                  width: "100%",
                  bgcolor: theme.palette.primary.main,
                  width: "100%",
                }}
              />
              <Grid
                container
                columnSpacing={2}
                minHeight="50px"
                alignContent="center"
              >
                <Grid item xs={4}>
                  PODs
                </Grid>
                <Grid item xs={4}>
                  {props.usage.pods}
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name="podsLimit"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="standard"
                        type="number"
                        fullWidth={true}
                        title="PODs limit"
                        size="small"
                        error={errors.podsLimit ? true : false}
                        helperText={errors.podsLimit?.message}
                        disabled={props.usage.pricingPlan.name === "Free"}
                        onChangeCapture={(event) => {
                          const value = event.target.value;
                          if (value === "") {
                            setValue("podsLimit", 0, {
                              shouldValidate: true,
                            });
                          } else {
                            field.onChange(value);
                          }
                          if (!isEdited) {
                            setIsEdited(true);
                          }
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Divider
                sx={{
                  borderBottomWidth: "1px",
                  width: "100%",
                  bgcolor: theme.palette.primary.main,
                  width: "100%",
                }}
              />
              <Grid
                container
                columnSpacing={2}
                minHeight="50px"
                alignContent="center"
              >
                <Grid item xs={4}>
                  Connectors
                </Grid>
                <Grid item xs={4}>
                  {props.usage.connectors}
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name="connectorsLimit"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="standard"
                        type="number"
                        fullWidth={true}
                        title="Connectors limit"
                        // title="Description"
                        size="small"
                        error={errors.connectorsLimit ? true : false}
                        helperText={errors.connectorsLimit?.message}
                        onChangeCapture={(event) => {
                          const value = event.target.value;
                          if (value === "") {
                            setValue("connectorsLimit", 0, {
                              shouldValidate: true,
                            });
                          } else {
                            field.onChange(value);
                          }
                          if (!isEdited) {
                            setIsEdited(true);
                          }
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Divider
                sx={{
                  borderBottomWidth: "1px",
                  width: "100%",
                  bgcolor: theme.palette.primary.main,
                  width: "100%",
                }}
              />
              <Grid
                container
                columnSpacing={2}
                minHeight="50px"
                alignContent="center"
              >
                <Grid item xs={4} justifyContent="center">
                  Users
                </Grid>
                <Grid item xs={4}>
                  {props.usage.users}
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name="limitUsers"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="standard"
                        type="number"
                        fullWidth={true}
                        title="Users limit"
                        // title="Number of free PODs"
                        size="small"
                        error={errors.limitUsers ? true : false}
                        helperText={errors.limitUsers?.message}
                        onChangeCapture={(event) => {
                          const value = event.target.value;
                          if (value === "") {
                            setValue("limitUsers", 0, {
                              shouldValidate: true,
                            });
                          } else {
                            field.onChange(value);
                          }
                          if (!isEdited) {
                            setIsEdited(true);
                          }
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={3}
              display="flex"
              alignItems="end"
              justifyContent="end"
            >
              <Box
                width="100%"
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
              >
                <Button
                  variant="outlined"
                  disabled={!isEdited}
                  sx={{
                    borderRadius: "50px",
                    borderWidth: "3px",
                    maxHeight: "40px",
                    lineHeight: "22px",
                    fontSize: "22px",
                    marginBottom: "20px",
                  }}
                  onClick={() => {
                    setIsEdited(false);
                    reset();
                  }}
                >
                  RESET
                </Button>
                <Button
                  variant="outlined"
                  disabled={!isEdited}
                  sx={{
                    borderRadius: "50px",
                    borderWidth: "3px",
                    maxHeight: "40px",
                    lineHeight: "22px",
                    fontSize: "22px",
                  }}
                  onClick={handleSubmit(handleButton)}
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Usage;
