import { Box, Grid, Typography, useTheme } from "@mui/material";
import { currencyToText } from "../../../utils/functions";

const PricingPlanInfo = (props) => {
  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography fontWeight="bold">Plan - {props.plan.name}</Typography>
      <Typography marginBottom="20px">{props.plan.description}</Typography>
      <Grid container justifyContent="center">
        <Grid item xs={12} display="flex" justifyContent="center">
          <Typography marginRight="5%">Free PODs:</Typography>
          <Typography>{props.plan.freePodNumber}</Typography>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <Typography marginRight="5%">Limit of connectors</Typography>
          <Typography>{props.plan.maxConnectors}</Typography>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <Typography marginRight="5%">Free users</Typography>
          <Typography>{props.plan.freeUsers}</Typography>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <Typography marginRight="5%">Price for POD</Typography>
          <Typography>
            {currencyToText(props.plan.priceForPod, props.plan.currency)}
          </Typography>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <Typography marginRight="5%">Price per connector</Typography>
          <Typography>
            {currencyToText(props.plan.priceForConnector, props.plan.currency)}
          </Typography>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <Typography marginRight="5%">Price for additional user</Typography>
          <Typography>
            {currencyToText(
              props.plan.priceForAdditionalUser,
              props.plan.currency
            )}
          </Typography>
        </Grid>
      </Grid>
      {props.plan.userManagement ? (
        <Typography>User management</Typography>
      ) : null}
      {props.plan.ownNames ? <Typography>Own names</Typography> : null}
      {props.plan.tftp ? <Typography>TFTP</Typography> : null}
      {props.plan.twoFactorAuthentication ? <Typography>2FA</Typography> : null}
      {props.plan.consoleHistory ? (
        <Typography>Console history</Typography>
      ) : null}
      {props.plan.shareConsole ? (
        <Typography>Sharing console</Typography>
      ) : null}
      {props.plan.accessToApi ? <Typography>Access to API</Typography> : null}
    </Box>
  );
};

export default PricingPlanInfo;
