import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ListIcon from "@mui/icons-material/List";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import HistoryToggleOffOutlinedIcon from "@mui/icons-material/HistoryToggleOffOutlined";
import { useNavigate } from "react-router-dom";
import {
  TERMINALLIST,
  STATISTICS,
  USERLISTMANAGEMENT,
  PRICINGPLANS,
  CONSOLEHISTORY,
} from "../../navigation/CONSTANTS";
import { useSelector } from "react-redux";
import { userType } from "../../redux/userSlice";
import useAsyncFeedback from "../../hooks/useAsyncFeedback";
import { SyncLoader } from "react-spinners";
import { useApi } from "../../api/ContextApi";

const Cards8 = () => {
  const theme = useTheme();
  let navigate = useNavigate();
  const userData = useSelector(userType);
  const [handleAsyncConsoleHistoryPermission, { loading }] = useAsyncFeedback();
  const [consoleHistoryPermission, setConsoleHistoryPermission] =
    useState(false);
  const api = useApi();

  useEffect(() => {
    handleAsyncConsoleHistoryPermission(
      () => api.getConsolehistoryPermission(),
      null,
      (data) => {
        if (data) setConsoleHistoryPermission(data.allow ? data.allow : false);
      },
      "loading"
    );
  }, []);

  if (loading) {
    return (
      <SyncLoader
        color={theme.palette.primary.main}
        style={{ marginTop: "10vh" }}
      />
    );
  }

  return (
    <React.Fragment>
      <Grid item>
        <Card
          sx={{
            width: "200px",
            m: 4,
            background: `linear-gradient(to bottom, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: "15px",
            p: "4px",
          }}
        >
          {" "}
          <Box
            sx={{
              width: "100%",
              minHeight: "300px",
              background: theme.palette.background.main,
              borderRadius: "11px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <ListIcon
                sx={{
                  height: "90px",
                  width: "90px",
                  m: "auto",
                  color: theme.palette.primary.main,
                }}
              />
              <Typography
                fontSize="20px"
                maxWidth={150}
                textAlign="center"
                color={theme.palette.secondary.main}
                sx={{ height: "3em" }}
              >
                Terminals
              </Typography>
              <Typography
                fontSize="12px"
                maxWidth={150}
                textAlign="center"
                my="5px"
              >
                Go to the terminal list view
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                variant="outlined"
                id={"dashboard-card-terminals-button"}
                sx={{
                  borderRadius: "50px",
                  borderWidth: "2px",
                  ":hover": {
                    borderWidth: "2px",
                    background: theme.palette.fadedColors.primary,
                  },
                  width: "150px",
                }}
                onClick={() => {
                  navigate(TERMINALLIST);
                }}
              >
                Go there
              </Button>
            </CardActions>
          </Box>
        </Card>
      </Grid>
      {userData.userType === "global_admin" ? (
        <Grid item>
          <Card
            sx={{
              width: "200px",
              m: 4,
              background: `linear-gradient(to bottom, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: "15px",
              p: "4px",
            }}
          >
            {" "}
            <Box
              sx={{
                width: "100%",
                minHeight: "300px",
                background: theme.palette.background.main,
                borderRadius: "11px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <QueryStatsIcon
                  sx={{
                    height: "90px",
                    width: "90px",
                    m: "auto",
                    color: theme.palette.primary.main,
                  }}
                />
                <Typography
                  fontSize="20px"
                  maxWidth={150}
                  textAlign="center"
                  color={theme.palette.secondary.main}
                  sx={{ height: "3em" }}
                >
                  Statistics
                </Typography>
                <Typography
                  fontSize="12px"
                  maxWidth={150}
                  textAlign="center"
                  my="5px"
                >
                  Go to view statistics
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  variant="outlined"
                  id={"dashboard-card-statistics-button"}
                  sx={{
                    borderRadius: "50px",
                    borderWidth: "2px",
                    ":hover": {
                      borderWidth: "2px",
                      background: theme.palette.fadedColors.primary,
                    },
                    width: "150px",
                  }}
                  onClick={() => {
                    navigate(STATISTICS);
                  }}
                >
                  Go there
                </Button>
              </CardActions>
            </Box>
          </Card>
        </Grid>
      ) : null}
      <Grid item>
        <Card
          sx={{
            width: "200px",
            m: 4,
            background: `linear-gradient(to bottom, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: "15px",
            p: "4px",
          }}
        >
          {" "}
          <Box
            sx={{
              width: "100%",
              minHeight: "300px",
              background: theme.palette.background.main,
              borderRadius: "11px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <ManageAccountsIcon
                sx={{
                  height: "90px",
                  width: "90px",
                  m: "auto",
                  color: theme.palette.primary.main,
                }}
              />
              <Typography
                fontSize="20px"
                maxWidth={150}
                textAlign="center"
                color={theme.palette.secondary.main}
                sx={{ height: "3em" }}
              >
                User management
              </Typography>
              <Typography
                fontSize="12px"
                maxWidth={150}
                textAlign="center"
                my="5px"
              >
                Go to manage organizations and users
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                variant="outlined"
                sx={{
                  borderRadius: "50px",
                  borderWidth: "2px",
                  ":hover": {
                    borderWidth: "2px",
                    background: theme.palette.fadedColors.primary,
                  },
                  width: "150px",
                }}
                onClick={() => {
                  navigate(USERLISTMANAGEMENT);
                }}
              >
                Go there
              </Button>
            </CardActions>
          </Box>
        </Card>
      </Grid>
      {consoleHistoryPermission ? (
        <Grid item>
          <Card
            sx={{
              width: "200px",
              m: 4,
              background: `linear-gradient(to bottom, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: "15px",
              p: "4px",
            }}
          >
            {" "}
            <Box
              sx={{
                width: "100%",
                minHeight: "300px",
                background: theme.palette.background.main,
                borderRadius: "11px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <HistoryToggleOffOutlinedIcon
                  sx={{
                    height: "90px",
                    width: "90px",
                    m: "auto",
                    color: theme.palette.primary.main,
                  }}
                />
                <Typography
                  fontSize="20px"
                  maxWidth={150}
                  textAlign="center"
                  color={theme.palette.secondary.main}
                  sx={{ height: "3em" }}
                >
                  Console history
                </Typography>
                <Typography
                  fontSize="12px"
                  maxWidth={150}
                  textAlign="center"
                  my="5px"
                >
                  Go to the console history view
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  variant="outlined"
                  sx={{
                    borderRadius: "50px",
                    borderWidth: "2px",
                    ":hover": {
                      borderWidth: "2px",
                      background: theme.palette.fadedColors.primary,
                    },
                    width: "150px",
                  }}
                  onClick={() => {
                    navigate(CONSOLEHISTORY);
                  }}
                >
                  Go there
                </Button>
              </CardActions>
            </Box>
          </Card>
        </Grid>
      ) : null}
      {userData.userType !== "organization_admin" ? (
        <Grid item>
          <Card
            sx={{
              width: "200px",
              m: 4,
              background: `linear-gradient(to bottom, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: "15px",
              p: "4px",
            }}
          >
            {" "}
            <Box
              sx={{
                width: "100%",
                minHeight: "300px",
                background: theme.palette.background.main,
                borderRadius: "11px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <AccountBalanceIcon
                  sx={{
                    height: "90px",
                    width: "90px",
                    m: "auto",
                    color: theme.palette.primary.main,
                  }}
                />
                <Typography
                  fontSize="20px"
                  maxWidth={150}
                  textAlign="center"
                  color={theme.palette.secondary.main}
                  sx={{ height: "3em" }}
                >
                  Pricing plans
                </Typography>
                <Typography
                  fontSize="12px"
                  maxWidth={150}
                  textAlign="center"
                  my="5px"
                >
                  Check your pricing plans
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  variant="outlined"
                  sx={{
                    borderRadius: "50px",
                    borderWidth: "2px",
                    ":hover": {
                      borderWidth: "2px",
                      background: theme.palette.fadedColors.primary,
                    },
                    width: "150px",
                  }}
                  onClick={() => navigate(PRICINGPLANS)}
                >
                  Go there
                </Button>
              </CardActions>
            </Box>
          </Card>
        </Grid>
      ) : null}
    </React.Fragment>
  );
};

export default Cards8;
