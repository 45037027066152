import apisauce from "apisauce";
import config from "../config/index.js";

const createApi = (signout) => {
  const api = apisauce.create({
    baseURL: config.API_URL,
    headers: {
      Accept: "application/vnd.api+json",
    },
    timeout: 60000,
  });

  const apiFile = apisauce.create({
    baseURL: config.API_URL,
    headers: {
      Accept: "application/vnd.api+json",
    },
    timeout: 60000,
    responseType: "arraybuffer",
  });

  const naviMonitor = (response) => {
    try {
      if (response.status === 401) {
        console.warn("Unhandled session expiration");
        signout();
      }
    } catch (e) {
      console.log(e); // the error you throwed at transformation
    }
  };
  api.addMonitor(naviMonitor);
  apiFile.addMonitor(naviMonitor);

  const setAuthToken = (userAuth) => {
    api.setHeader("Authorization", "Bearer " + userAuth);
    apiFile.setHeader("Authorization", "Bearer " + userAuth);
  };
  const removeAuthToken = () => {
    api.deleteHeader("Authorization");
    apiFile.deleteHeader("Authorization");
  };
  const getHeaders = () => {
    // console.log("Headers:", api.headers);
    return api.headers.Authorization;
  };

  const login = (email, password) => {
    return api.post("/login", {
      email: email,
      password: password,
    });
  };

  const forgotPasswordRequest = (email) => {
    return api.post("/forgot-password-request", { email: email });
  };

  const forgotPasswordReset = (code, password, hashedAccountId) => {
    return api.post("/forgot-password-reset", {
      code: code,
      password: password,
      hashedAccountId: hashedAccountId,
    });
  };

  const getPortsByAccountType = () => {
    return api.get("/serial-ports");
  };

  const updateMiniPCName = (id, name) => {
    return api.put("/minipc-name", { id: id, name: name });
  };

  const updateSerialPort = (
    id,
    name,
    baudRate,
    dataBits,
    parity,
    stopBits,
    rtscts,
    xon,
    xoff,
    xany,
    verified
  ) => {
    return api.put("/serial-port", {
      id: id,
      name: name,
      baudRate: baudRate,
      dataBits: dataBits,
      parity: parity,
      stopBits: stopBits,
      rtscts: rtscts,
      xon: xon,
      xoff: xoff,
      xany: xany,
      verified: verified,
    });
  };

  // notifications

  const getNotifications = () => {
    return api.get("/notifications");
  };

  const viewNotifications = () => {
    return api.put("/notifications");
  };

  // serial port

  const deleteSerialPort = (serialPortId) => {
    return api.delete("/serial-port", { serialPortId: serialPortId });
  };

  const forgetSerialPort = (serialPortId) => {
    return api.put("/forget-serial-port", { serialPortId: serialPortId });
  };

  //user

  const getProfileData = () => {
    return api.get("/profile");
  };

  const updateProfileData = (data) => {
    return api.put("/profile", data);
  };

  const updatePassword = (oldPassword, newPassword) => {
    return api.post("/change-password", {
      oldPassword: oldPassword,
      newPassword: newPassword,
    });
  };

  const update2FASecret = (goal, userId) => {
    return api.put("/secret-2fa", {
      goal: goal,
      userId: userId ? userId : null,
    });
  };

  const verify2FASecret = (userId, code) => {
    return api.get("/login-2fa", {
      id: userId,
      code: code,
    });
  };

  const getUsers = () => {
    return api.get("/users");
  };

  const updateUser = (
    user_id,
    name,
    email,
    serial_ports,
    vlan_network_intefaces
  ) => {
    return api.post("/update-user", {
      user_id: user_id,
      name: name,
      email: email,
      serial_ports: serial_ports,
      vlan_network_intefaces: vlan_network_intefaces,
    });
  };

  const createUser = (
    name,
    email,
    password,
    account_type_id,
    organization_id
  ) => {
    return api.post("/create-user", {
      name: name,
      email: email,
      password: password,
      account_type_id: account_type_id,
      organization_id: organization_id,
    });
  };

  const getAdminsByOrganization = (organization_id) => {
    return api.get("/organization-admins", {
      organization_id: organization_id,
    });
  };

  const deleteUser = (account_id) => {
    return api.delete("/user", {
      account_id: account_id,
    });
  };

  //organizations

  const getOrganizations = () => {
    return api.get("/organizations");
  };

  const createOrganization = (name, description) => {
    return api.post("/create-organization", {
      name: name,
      description: description,
    });
  };

  const updateOrganization = (id, name, admin_id, description) => {
    return api.post("/update-organization", {
      id: id,
      name: name,
      admin_id: admin_id,
      description: description,
    });
  };

  const deleteOrganization = (organization_id) => {
    return api.delete("/organization", {
      organization_id: organization_id,
    });
  };

  //dataCenter

  const getDataCenter = () => {
    return api.get("/data-center");
  };

  const getDataCenterFullInfo = () => {
    return api.get("/data-center-full-info");
  };

  const createDataCenter = (data) => {
    return api.post("/create-data-center", {
      data: data,
    });
  };

  const updateDataCenter = (data) => {
    return api.post("/update-data-center", {
      data: data,
    });
  };

  const deleteDataCenter = (data_center_id) => {
    return api.delete("/data-center", {
      data_center_id: data_center_id,
    });
  };

  const addOrganizations = (data) => {
    return api.post("/data-center-add-organizations", {
      data: data,
    });
  };

  //statistics
  const getUserCreationStats = () => {
    return api.get("/user-creation-stats");
  };

  const getGeneralInformations = () => {
    return api.get("/general-informations");
  };

  const getDeviceStats = () => {
    return api.get("/device-statistics");
  };

  const getOrganizationStats = () => {
    return api.get("/organization-statistics");
  };

  //api key
  const getApiKeys = () => {
    return api.get("/api-key");
  };

  const createApiKey = (apiKey) => {
    return api.post("/api-key", { apiKey: apiKey });
  };

  const forgetApiKey = (apiKeyId) => {
    return api.put("/forget-api-key", { apiKeyId: apiKeyId });
  };

  const deleteApiKey = (apiKeyId) => {
    return api.delete("/api-key", { apiKeyId: apiKeyId });
  };

  // payment
  const getAllPricingPlans = () => {
    return api.get("/pricing-plans");
  };

  const updatePricingPlan = (
    id,
    name,
    description,
    freePodNumber,
    priceForPod,
    maxConnectors,
    priceForConnector,
    freeUsers,
    priceForAdditionalUser,
    support,
    currency,
    ownNames,
    userManagement,
    twoFactorAuthentication,
    tftp,
    accessToApi,
    consoleHistory,
    shareConsole
  ) => {
    return api.put("/pricing-plan", {
      id: id,
      name: name,
      description: description,
      freePodNumber: freePodNumber,
      priceForPod: priceForPod,
      maxConnectors: maxConnectors,
      priceForConnector: priceForConnector,
      freeUsers: freeUsers,
      priceForAdditionalUser: priceForAdditionalUser,
      support: support,
      currency: currency,
      ownNames: ownNames,
      userManagement: userManagement,
      twoFactorAuthentication: twoFactorAuthentication,
      tftp: tftp,
      accessToApi: accessToApi,
      consoleHistory: consoleHistory,
      shareConsole: shareConsole,
    });
  };

  const getPricingPlan = () => {
    return api.get("/pricing-plan");
  };

  const updateDcPricingPlan = (id, podsLimit, connectorsLimit, limitUsers) => {
    return api.put("/pricing-plan-dc-limits", {
      id: id,
      podsLimit: podsLimit,
      connectorsLimit: connectorsLimit,
      limitUsers: limitUsers,
    });
  };

  //tftp

  const uploadFile = (formData) => {
    return api.post("/tftp-upload-file", formData);
  };

  const downloadFile = (fileId, fileName) => {
    return apiFile.get("/tftp-download-file/" + fileId, {
      responseType: "arraybuffer",
    });
  };

  const getNetworkInterfaces = () => {
    return api.get("/network-interfaces");
  };

  const getNetworkINterface = (mini_pc_id) => {
    return api.get("/network-interface", { mini_pc_id: mini_pc_id });
  };

  //console history

  const getConsoleHistory = () => {
    return api.get("/console-history");
  };

  const enableHistoryByMiniPC = (id, changeTo) => {
    return api.put("/minipc-console-history", { id: id, changeTo: changeTo });
  };

  const enableHistory = (id, changeTo) => {
    return api.put("/console-history", { id: id, changeTo: changeTo });
  };

  const getSerialPortConsoleHistory = (
    miniPCId,
    serialPortId,
    continuationToken
  ) => {
    return api.get("/serial-port-console-history", {
      miniPCId: miniPCId,
      serialPortId: serialPortId,
      continuationToken: continuationToken,
    });
  };

  const getSingleConsoleHistory = (miniPCId, serialPortId, fileName) => {
    return api.get("/single-console-history", {
      miniPCId: miniPCId,
      serialPortId: serialPortId,
      fileName: fileName,
    });
  };

  const getConsolehistoryPermission = () => {
    return api.get("/consoleHistoryPermission");
  };

  const checkPortUserStatus = (serialPortId) => {
    return api.get("/check-port-user-status", { serialPortId: serialPortId });
  };

  const serverStatus = () => {
    return api.get(
      "/server-status",
      {},
      { headers: { Authorization: `Bearer ${config.SERVER_SECRET_KEY}` } }
    );
  };

  return {
    setAuthToken,
    removeAuthToken,
    getHeaders,
    login,
    forgotPasswordRequest,
    forgotPasswordReset,
    getPortsByAccountType,
    updateMiniPCName,
    updateSerialPort,
    getNotifications,
    viewNotifications,
    deleteSerialPort,
    forgetSerialPort,
    getProfileData,
    updateProfileData,
    updatePassword,
    update2FASecret,
    verify2FASecret,
    getUsers,
    updateUser,
    createUser,
    getAdminsByOrganization,
    deleteUser,
    getOrganizations,
    createOrganization,
    updateOrganization,
    deleteOrganization,
    getDataCenter,
    getDataCenterFullInfo,
    createDataCenter,
    updateDataCenter,
    deleteDataCenter,
    addOrganizations,
    getUserCreationStats,
    getGeneralInformations,
    getDeviceStats,
    getOrganizationStats,
    getApiKeys,
    createApiKey,
    forgetApiKey,
    deleteApiKey,
    getAllPricingPlans,
    updatePricingPlan,
    getPricingPlan,
    updateDcPricingPlan,
    uploadFile,
    downloadFile,
    getNetworkInterfaces,
    getNetworkINterface,
    getConsoleHistory,
    enableHistoryByMiniPC,
    enableHistory,
    getSerialPortConsoleHistory,
    getSingleConsoleHistory,
    getConsolehistoryPermission,
    checkPortUserStatus,
    serverStatus,
  };
};

export default createApi;
