import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Controller, useForm } from "react-hook-form";
import { filterByVersion } from "../../utils/functions";

const ConsoleHistorySearch = (props) => {
  const { control, handleSubmit, reset, getValues } = useForm({
    defaultValues: {
      miniPcName: "",
      pnpId: "",
      email: "",
      from: "",
      to: "",
    },
  });
  let theme = useTheme();

  const handleReset = () => {
    reset();
    props.setFilterBy({
      miniPcName: "",
      pnpId: "",
      email: "",
      from: "",
      to: "",
    });
  };

  const handleSave = () => {
    props.setFilterBy(getValues());
  };

  return (
    <Box
      sx={{
        m: "10px",
        p: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        height: "auto",
        borderStyle: "solid",
        borderWidth: "2px",
        borderColor: theme.palette.primary.main,
        borderRadius: "15px",
      }}
    >
      <Accordion
        sx={{ width: "100%", boxShadow: "none" }}
        disableGutters={true}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <AccordionSummary
            expandIcon={<FilterListIcon />}
            aria-controls={"filter"}
            id={"filter"}
            sx={{
              flexDirection: "row-reverse",
              p: "0px",
              alignItems: "center",
              mr: "15px",
            }}
          ></AccordionSummary>
          <Typography style={{ marginRight: "10px" }}>MiniPC name:</Typography>
          <Box sx={{ width: "75%", mr: "20px" }}>
            <Controller
              name="miniPcName"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="text"
                  disableUnderline={false}
                  sx={{
                    p: "0px !important",
                    "& .MuiInputBase-input.MuiInput-input": {
                      WebkitTextFillColor: "black",
                      p: "0px",
                    },
                  }}
                  fullWidth={true}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                  }}
                />
              )}
            />
          </Box>
          <Button
            variant="outlined"
            sx={{
              borderRadius: "50px",
              borderWidth: "2px",
              ml: "auto",
              alignItems: "center",
            }}
            onClick={handleReset}
          >
            Reset
          </Button>
          <Button
            variant="outlined"
            sx={{
              borderRadius: "50px",
              borderWidth: "2px",
              ml: "20px",
              alignItems: "center",
            }}
            onClick={handleSave}
          >
            Save
          </Button>
        </Box>
        <AccordionDetails>
          <Grid container sx={{ ml: "23px" }}>
            <Grid item xs={3}>
              <Box display="flex" flexDirection="row">
                <Box sx={{ width: "90%" }}>
                  <Controller
                    name="pnpId"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="standard"
                        label="Pnp Id"
                        sx={{}}
                        fullWidth
                        onChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                      />
                    )}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display="flex" flexDirection="row">
                <Box sx={{ width: "90%" }}>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="standard"
                        label="Email Address"
                        sx={{}}
                        fullWidth
                        onChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                      />
                    )}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} display="flex" alignItems="baseline">
              <InputLabel id="filter-version" sx={{ mr: "20px" }}>
                Date:
              </InputLabel>
              <Box display="flex">
                <Controller
                  name="from"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="filter-version-from"
                      label="from"
                      InputLabelProps={{ shrink: true }}
                      type="date"
                      variant="standard"
                      sx={{ mr: "10px" }}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                    />
                  )}
                />
                <Controller
                  name="to"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="filter-version-to"
                      label="to"
                      InputLabelProps={{ shrink: true }}
                      type="date"
                      variant="standard"
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default ConsoleHistorySearch;
