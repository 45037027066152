import { useState } from "react";

const useAsyncFeedback = () => {
  const [loadingMap, setLoadingMap] = useState({});

  const updateField = (field, value) => {
    if (field) {
      setLoadingMap((prev) => ({ ...prev, [field]: value }));
    }
  };

  const handleSuccessResponse = (
    response,
    onSuccess,
    successFeedbackMessage,
    loadingField
  ) => {
    if (successFeedbackMessage) {
      console.log("Accept-asyncFeedback");
    }

    updateField(loadingField, false);

    if (onSuccess) {
      try {
        onSuccess(response.data);
      } catch (err) {
        // skip
      }
    }
  };

  const handleFailureResponse = (response, loadingField, onError) => {
    console.log("Error-asyncFeedback-outside");

    updateField(loadingField, false);

    if (onError) {
      onError();
    }
  };

  const handleCall = async (
    remoteCall,
    successFeedbackMessage,
    onSuccess,
    loadingField = null,
    onError = null
  ) => {
    updateField(loadingField, true);

    try {
      const response = await remoteCall();

      if (response.ok) {
        handleSuccessResponse(
          response,
          onSuccess,
          successFeedbackMessage,
          loadingField
        );
      } else {
        handleFailureResponse(response, loadingField, onError);
      }
    } catch (err) {
      console.log("Error-asyncFeedback");

      updateField(loadingField, false);
    }
  };

  return [handleCall, loadingMap];
};

export default useAsyncFeedback;
