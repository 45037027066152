export const getUserManagementTitle = (variant, organizationName) => {
  try {
    if (variant === "users" && organizationName)
      return "Users -> " + organizationName;
    else if (variant === "users") return "Users";
    else if (variant === "org") return "Organizations";
    else if (variant === "api-key") return "API keys";
    else return "Data centers";
  } catch (err) {
    console.log("Error getting userManagement view title: " + err);
    return {};
  }
};
