import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import useAsyncFeedback from "../../hooks/useAsyncFeedback";
import { useApi } from "../../api/ContextApi";

const UserCreationChart = () => {
  const theme = useTheme();
  const [newUsers, setNewUsers] = useState([]);
  const [handleAsyncUsers, { loading }] = useAsyncFeedback();
  const [rawData, setRawData] = useState(null);
  const [userCreationData, setUserCreationData] = useState([]);
  const [chartType, setChartType] = useState("year");
  const api = useApi();

  useEffect(() => {
    handleAsyncUsers(
      () => api.getUserCreationStats(),
      null,
      (data) => {
        let data2 = {};
        data2.year = data.year.reverse();
        data2.month = data.month.reverse();
        data2.week = data.week.reverse();
        setRawData(data2);
        setUserCreationData(data2.year);
      },
      "loading"
    );
  }, []);

  const handleSelect = (event) => {
    setChartType(event.target.value);
    setUserCreationData(rawData[`${event.target.value}`]);
  };

  return (
    <>
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ height: "2.5rem" }}
      >
        <Typography sx={{ ml: "10px", color: theme.palette.secondary.main }}>
          Chart of newly created accounts
        </Typography>
        <FormControl sx={{ minWidth: "30%" }}>
          <InputLabel id="select-label">Type</InputLabel>
          <Select
            variant="outlined"
            labelId="select-label"
            id="select"
            size="small"
            disabled={loading}
            label="Type"
            name="type"
            value={chartType}
            onChange={handleSelect}
          >
            <MenuItem id="menu-item-year" value={"year"}>
              year
            </MenuItem>
            <MenuItem id="menu-item-month" value={"month"}>
              month
            </MenuItem>
            <MenuItem id="menu-item-week" value={"week"}>
              week
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          width={100}
          height={100}
          data={userCreationData}
          margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
        >
          <Line
            type="monotone"
            dataKey="quantity"
            stroke={theme.palette.primary.main}
          />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <XAxis dataKey="title" stroke={theme.palette.secondary.main} />
          <YAxis stroke={theme.palette.secondary.main} />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

export default UserCreationChart;
