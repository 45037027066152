import { Box, Button, Container, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROOT } from "../../navigation/CONSTANTS";
import { useSelector } from "react-redux";
import { userType } from "../../redux/userSlice";
import ConfigurationPricingPlans from "./configurationPricingPlans/configurationPricingPlans";
import PersonalPricingPlan from "./personalPricingPlan/personalPricingPlan";

const Payments = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const userData = useSelector(userType);

  const handleBackToHomePage = () => {
    navigate(ROOT);
  };

  return (
    <>
      <Container
        maxWidth="xl"
        sx={{
          mx: "auto",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography m="10px" variant="h4">
          {userData.userType === "global_admin"
            ? "Pricing plans:"
            : "Pricing plan:"}
        </Typography>
        <Button
          variant="contained"
          onClick={handleBackToHomePage}
          sx={{ borderRadius: "50px" }}
        >
          Home page
        </Button>
      </Container>
      <Container
        maxWidth="xl"
        sx={{ mx: "auto", display: "flex", flexDirection: "column" }}
      >
        {userData.userType === "global_admin" ? (
          <ConfigurationPricingPlans />
        ) : (
          <PersonalPricingPlan />
        )}
      </Container>
    </>
  );
};

export default Payments;
