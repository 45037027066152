import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { ROOT } from "./CONSTANTS";
import { useNavigate } from "react-router-dom";

export const NotFound = () => {
  let navigate = useNavigate();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      my="auto"
    >
      <Typography variant="h4" textAlign="center" mb={2}>
        Error 404: Page not found!
      </Typography>
      <Button
        variant="contained"
        sx={{ borderRadius: "15px" }}
        onClick={() => navigate(ROOT)}
      >
        Home page
      </Button>
    </Box>
  );
};
