import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  useTheme,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import YupPassword from "yup-password";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useApi } from "../../api/ContextApi";
YupPassword(Yup);

const ChangePasswordComponent = () => {
  const theme = useTheme();
  const [changePassword, setChangePassword] = useState(false);
  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    password: false,
    confirmPassword: false,
  });
  const api = useApi();

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .required("Password is required")
      .min(6, "Password is too short")
      .max(50, "Password is too long"),
    password: Yup.string()
      .required("Password is required")
      .minLowercase(1, "Password must contain at least 1 lower case letter")
      .minUppercase(1, "Password must contain at least 1 upper case letter")
      .minNumbers(1, "Password must contain at least 1 number")
      .minSymbols(1, "Password must contain at least 1 special character")
      .min(6, "Password is too short")
      .max(50, "Password is too long"),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm({
    defaultValues: {
      oldPassword: "",
      password: "",
      confirmPassword: "",
    },
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  const handleButton = (data) => {
    api.updatePassword(data.oldPassword, data.password).then((result) => {
      if (result.data.done) {
        setChangePassword(!changePassword);
        window.location.reload(false);
      }
    });
  };

  const handleClickShowPassword = (element) => {
    if (element === "oldPassword") {
      setShowPassword({
        ...showPassword,
        oldPassword: !showPassword.oldPassword,
      });
    } else if (element === "password")
      setShowPassword({ ...showPassword, password: !showPassword.password });
    else if (element === "confirmPassword")
      setShowPassword({
        ...showPassword,
        confirmPassword: !showPassword.confirmPassword,
      });
  };

  return (
    <Accordion
      disableGutters
      sx={{ width: "100%", boxShadow: "none" }}
      expanded={changePassword}
    >
      <Box sx={{ display: "flex" }}>
        <AccordionSummary
          disabled={changePassword}
          sx={{ height: "50px" }}
          aria-expanded={changePassword}
          onClick={() => {
            setChangePassword(!changePassword);
          }}
        >
          <Button
            variant="outlined"
            sx={{
              borderRadius: "50px",
              mb: "2px",
              borderWidth: "2px",
              ":hover": { borderWidth: "2px" },
              width: "250px",
            }}
          >
            Change password
          </Button>
        </AccordionSummary>
      </Box>
      <AccordionDetails sx={{ py: 0 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            minHeight: "54px",
            alignItems: "flex-end",
          }}
        >
          <Box sx={{ width: "70%" }}>
            <Controller
              name="oldPassword"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="standard"
                  type={showPassword.oldPassword ? "text" : "password"}
                  fullWidth={true}
                  label="Old password"
                  size="small"
                  error={errors.oldPassword ? true : false}
                  helperText={errors.oldPassword?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={() => handleClickShowPassword("oldPassword")}
                          edge="end"
                        >
                          {showPassword.oldPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            minHeight: "52px",
            alignItems: "flex-end",
          }}
        >
          <Box sx={{ width: "70%" }}>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="standard"
                  type={showPassword.password ? "text" : "password"}
                  fullWidth={true}
                  label="Password"
                  size="small"
                  error={errors.password ? true : false}
                  helperText={errors.password?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={() => handleClickShowPassword("password")}
                          edge="end"
                        >
                          {showPassword.password ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            minHeight: "52px",
            alignItems: "flex-end",
          }}
        >
          <Box sx={{ width: "70%" }}>
            <Controller
              name="confirmPassword"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="standard"
                  type={showPassword.confirmPassword ? "text" : "password"}
                  fullWidth={true}
                  label="Confirm password"
                  size="small"
                  error={errors.confirmPassword ? true : false}
                  helperText={errors.confirmPassword?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={() =>
                            handleClickShowPassword("confirmPassword")
                          }
                          edge="end"
                        >
                          {showPassword.confirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Box>
          <Box sx={{ width: "30%" }}>
            <Tooltip title="Change password">
              <IconButton
                sx={{
                  bgcolor: theme.palette.primary.main,
                  color: theme.palette.background.main,
                  ":hover": {
                    bgcolor: theme.palette.secondary.main,
                  },
                  height: "2.5rem",
                  width: "2.5rem",
                  borderColor: theme.palette.primary.main,
                  borderWidth: "1px",
                  borderStyle: "solid",
                  ml: "10px",
                  my: "auto",
                }}
                onClick={handleSubmit(handleButton)}
              >
                <SaveIcon sx={{ height: "1.8rem", width: "1.8rem" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel">
              <IconButton
                sx={{
                  bgcolor: theme.palette.primary.main,
                  color: theme.palette.background.main,
                  ":hover": {
                    bgcolor: theme.palette.secondary.main,
                  },
                  height: "2.5rem",
                  width: "2.5rem",
                  borderColor: theme.palette.primary.main,
                  borderWidth: "1px",
                  borderStyle: "solid",
                  ml: "10px",
                  mr: "-16px",
                  my: "auto",
                }}
                onClick={() => setChangePassword(!changePassword)}
              >
                <ClearIcon sx={{ height: "1.8rem", width: "1.8rem" }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Box sx={{ height: "52px" }} />
      </AccordionDetails>
    </Accordion>
  );
};

export default ChangePasswordComponent;
